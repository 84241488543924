import { atom } from "recoil";
import { POLLING_INTERVAL } from "src/constants";
import { getChange } from "src/utils/common";
import { METAL_PAIRS, DEFAULT_METALS_DATA, INTERVAL_RESOLUTION } from "../socket/constants";
import { getPythCandles } from "./OptionBlitzProvider";

export const metalsState = atom({
  key: "metalsState", // unique ID (with respect to other atoms/selectors)
  default: {
    data: { ...DEFAULT_METALS_DATA },
    isDataFetched: false,
    isSubscribed: false,
  },
  effects: [
    ({ setSelf, getLoadable, node, onSet, resetSelf }) => {
      // fetching current prices from finage REST API.
      const fetchPriceData = async () => {
        const prices = await Promise.all(
          METAL_PAIRS.map((pair) => getPythCandles(pair.split("/").join("_"), INTERVAL_RESOLUTION))
        );

        // old state.
        let { data, isSubscribed } = getLoadable(node).contents;

        // creating updated data.
        let updatedData = {};
        for (let i = 0; i < METAL_PAIRS.length; i++) {
          let newPairData = {
            ...data[METAL_PAIRS[i]],
            /** @type {string} */
            s: METAL_PAIRS[i],

            /** @type {number} */
            p: prices[i].newPrice.rate,

            /** @type {string} */
            dc: getChange(prices[i].newPrice.rate, prices[i].oldPrice.rate).toFixed(2),

            /** @type {{C:number}[]} */
            graphData: prices[i].obCandles.map((x) => ({ C: x.rate })),
          };
          updatedData[METAL_PAIRS[i]] = newPairData;
        }
        setSelf({ isSubscribed, isDataFetched: true, data: { ...updatedData } });
      };

      async function fetchData() {
        await fetchPriceData();
      }

      // fetching data first time.
      fetchData();

      setInterval(() => {
        // fetching data after interval only if subscribed.
        let { isSubscribed } = getLoadable(node).contents;
        if (isSubscribed) {
          fetchPriceData();
        }
      }, POLLING_INTERVAL);

      // cleanup
      return () => {
        resetSelf();
      };
    },
  ],
});
