import React from "react";
import s from "./SuccessPopup.module.scss";
import Close from "./../../assets/images/popup__close.svg";
import Success from "./../../assets/images/popup__success.svg";
import Error from "./../../assets/images/popup__error.svg";
import Button from "../UI/Button/Button";
import { ReactPortal } from "../Popup/Popup";
const MessagePopup = ({ closePopup, message }) => {
  return (
    <ReactPortal>
      <div className={s.popup_container}>
        <div className={s.popup_container__bg}></div>
        <div className={s.popup}>
          <div className={s.popup__header}>
            <strong>{message.status}</strong>
            <button className={s.popup__button} onClick={closePopup}>
              <img src={Close} alt="" />
            </button>
          </div>
          <div className={s.popup__body}>
            {message.status === "success" ? (
              <img src={Success} alt="" />
            ) : (
              <img src={Error} alt="" height={"100px"} width={"100px"} />
            )}
            <strong><div dangerouslySetInnerHTML={{__html: message.message}}></div></strong>
            <p>
              <div></div>
              Download your investors pack and visit <a href="https://discord.gg/bQAQZfTrxM" target='_blank'>https://discord.gg/bQAQZfTrxM</a> <br /> to learn more about how
              to use your BLX tokens and engage with governance of the DAO.<br/><br/>Attention:<br/><br/>Please return to this presale app on 28th March 2023 to claim your BLX tokens. If the soft cap is not reached you will be able to claim a refund of your USDC. If the hardcap is reached earlier than the 28th of March then the presale will end early and you will be able to claim your BLX then. The BUY BLX button will change to CLAIM BLX.
            </p>
            <Button onClick={closePopup}>OK</Button>
          </div>
        </div>
      </div>
    </ReactPortal>
  );
};

export default MessagePopup;
