import React, { useEffect, useState } from "react";
import s from "./Markets.module.scss";
import TableBlock from "./TableBlock/TableBlock";
import Arrow from "./../../assets/images/arrow_green.svg";
import AltButton from "../UI/AltButton/AltButton";
import { useTranslation } from "react-i18next";
import Fade from "react-reveal/Fade";
import useObserver from "src/hooks/useObserver";
import { useRecoilState } from "recoil";
import { cryptoState } from "src/state/cryptoAtom";
import { forexState } from "src/state/forexAtom";
import { metalsState } from "src/state/metalsAtom";
import { equitiesState } from "src/state/equitiesAtom";
import { featuredSignalsState } from "src/state/featuredSignalsAtom";

// Tabs Enum
export const MARKET_TABLE_TABS = {
  FOREX: 0,
  CRYPTO: 1,
  METALS: 2,
  EQUITIES: 3,
};

const Markets = () => {
  const { t } = useTranslation();
  const { inView, ref } = useObserver();

  const [activeTab, setActiveTab] = useState(MARKET_TABLE_TABS.FOREX);

  const [cryptoData, setCryptoData] = useRecoilState(cryptoState);
  const [forexData, setForexData] = useRecoilState(forexState);
  const [metalsData, setMetalsData] = useRecoilState(metalsState);
  const [equitiesData, setEquitiesData] = useRecoilState(equitiesState);
  const [featuredSignalsData, setFeaturedSignalsData] = useRecoilState(featuredSignalsState);

  function getDataSource(activeTab) {
    switch (activeTab) {
      case MARKET_TABLE_TABS.FOREX:
        return forexData.data;
      case MARKET_TABLE_TABS.CRYPTO:
        return cryptoData.data;
      case MARKET_TABLE_TABS.METALS:
        return metalsData.data;
      case MARKET_TABLE_TABS.EQUITIES:
        return equitiesData.data;
      default:
        return forexData.data;
    }
  }

  function setSubscriptionStatus(tab, subStatus = false) {
    switch (tab) {
      case MARKET_TABLE_TABS.CRYPTO:
        setCryptoData({ ...cryptoData, isSubscribed: subStatus });
        break;
      case MARKET_TABLE_TABS.FOREX:
        setForexData({ ...forexData, isSubscribed: subStatus });
        break;
      case MARKET_TABLE_TABS.METALS:
        setMetalsData({ ...metalsData, isSubscribed: subStatus });
        break;
      case MARKET_TABLE_TABS.EQUITIES:
        setEquitiesData({ ...equitiesData, isSubscribed: subStatus });
        break;

      default:
        setCryptoData({ ...cryptoData, isSubscribed: subStatus });
        setForexData({ ...forexData, isSubscribed: subStatus });
        setMetalsData({ ...metalsData, isSubscribed: subStatus });
        setEquitiesData({ ...equitiesData, isSubscribed: subStatus });
        return;
    }
  }

  useEffect(() => {
    if (inView) {
      // unsubbing from previous sub tab.
      setSubscriptionStatus();
      //subbing to featuredSignalsTable
      setFeaturedSignalsData({ ...featuredSignalsData, isSubscribed: true });
      // subbing to new tab.
      setSubscriptionStatus(activeTab, true);
    } else {
      // unsubscribing the active tab.
      setSubscriptionStatus(activeTab, false);
    }
  }, [inView, activeTab]);

  return (
    <section className={s.markets} id="markets">
      <div ref={ref} className="container">
        <h2>{t("Markets.heading")}</h2>

        <Fade bottom>
          <TableBlock
            markets={true}
            data={getDataSource(activeTab)}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
        </Fade>

        <Fade bottom>
          <div className={s.markets__signals}>
            <div className={s.markets__signals_row}>
              <h3>{t("Markets.heading1")}</h3>
              <p>{t("Markets.description1")}</p>
            </div>
            <TableBlock featureTable={true} data={featuredSignalsData.data ?? []} />

            <div className={s.markets__signals_row}>
              <p>{t("Markets.text")}</p>
              <a href="https://docs.optionblitz.co/optionblitz-trading-academy/">
                <AltButton>
                  <span>{t("Markets.Trading Academy")}</span>
                  <img src={Arrow} alt="" height="8" width="5" />
                </AltButton>
              </a>
            </div>
          </div>
        </Fade>
      </div>
    </section>
  );
};

export default Markets;
