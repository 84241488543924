import React, { useEffect, useState } from "react";
import s from "./TableBlock.module.scss";
import { AreaChart, Area, YAxis, ResponsiveContainer } from "recharts";
import useContract from "src/hooks/useContract";
import Sorting from "./Sorting/Sorting";
import Tabs from "./Tabs/Tabs";
import Button from "../../UI/Button/Button";
import tradeIcon from "../../../assets/images/chevron_right__icon.svg";
import viewIcon from "../../../assets/images/view__icon.svg";
import chevronRight from "src/assets/images/chevron_right__icon__green.svg";
import { useTranslation } from "react-i18next";

const marketsTableColumns = ["Name", "Last price", "1D Change", ""];
const featureTableColumns = ["Assets", "Chart", "Type", "5 min", "15 min", "Hourly", "Daily", "Trade Now"];
const activityTableColumns = ["Date", "Address", "Amount", "View On-Chain"];

const padLeft = (n, base, chr) => {
  const len = String(base || 10).length - String(n).length + 1;
  return len > 0 ? new Array(len).join(chr || "0") + n : n;
};

const formatDate = (d) => {
  const date =
    [d.getDate(), d.getMonth() + 1, d.getFullYear()].map((x) => padLeft(x)).join("/") +
    " " +
    [d.getHours(), d.getMinutes(), d.getSeconds()].map((x) => padLeft(x)).join(":");
  return date;
};
// Table rows to render if table is for Markets.
const TableRow = ({ icon, icon1, title, price, change, graphData }) => {
  return (
    <tr>
      <td data-title="Name">
        <div className={s.table__name}>
          {icon1 ? (
            <div style={{ marginLeft: "10px" }} className={s.table__assets__pair_icons}>
              <div>
                <img src={icon} alt="currency-logo 0" height="25" width="25" />
              </div>
              <div>
                <img src={icon1} alt="currency-logo 1" height="25" width="25" />
              </div>
            </div>
          ) : (
            <div className={s.table__icon}>
              <img src={icon} alt="" />
            </div>
          )}
          <strong>{title}</strong>
        </div>
      </td>
      <td data-title="Last price">
        <div className={s.table__price}>
          <strong>{price}</strong>
        </div>
      </td>
      <td>
        <div className={s.table__change}>
          <strong>{change}</strong>
        </div>
      </td>
      <td data-title="1D change">
        <div className={s.table__graph}>
          <ResponsiveContainer width="100%" height="100%">
            <AreaChart
              width={200}
              height={60}
              data={graphData}
              margin={{
                top: 0,
                right: 0,
                left: 0,
                bottom: 0,
              }}
            >
              <YAxis width={0} domain="auto" tick={false} axisLine={false} />
              <Area type="monotone" dataKey="C" stroke="#00CD86" strokeWidth={2} fill="url(#colorUv)" />
              <defs>
                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="67%" stopColor="#00CD86" stopOpacity={0.1} />
                  <stop offset="100%" stopColor="rgba(0, 205, 134, 0)" stopOpacity={0.1} />
                </linearGradient>
              </defs>
            </AreaChart>
          </ResponsiveContainer>
        </div>
      </td>
    </tr>
  );
};

// Table rows to render if table is for Featured Signals.
const FeatureTableRow = ({ icon, icon1, title, price, graphData, fiveMin, fifteenMin, daily, hourly, type }) => {
  let [isAdaptive, setIsAdaptive] = useState(window.screen.width <= 1025);
  useEffect(() => {
    window.addEventListener(
      "resize",
      function (event) {
        setIsAdaptive(window.screen.width <= 1025);
      },
      true
    );
  }, []);

  function getColor(s) {
    if (!s) return "#00CD86";

    const sell = s.toLowerCase().includes("sell");
    if (s.toLowerCase().includes("sell")) {
      return "#BF3D3D";
    }
    if (s.toLowerCase().includes("neutral")) {
      return "#505972";
    }
    if (s.toLowerCase().includes("buy")) {
      return "#00CD86";
    } else {
      return "#59648a";
    }
  }

  return (
    <>
      {isAdaptive ? (
        <>
          <tr>
            <td data-title="Assets">
              <div className={s.table__assets}>
                <div className={s.table__assets__pair_icons}>
                  {icon1 ? (
                    <>
                      <div>
                        <img src={icon} alt="currency-logo" height="25" width="25" />
                      </div>
                      <div>
                        <img src={icon1} alt="currency-logo" height="25" width="25" />
                      </div>
                    </>
                  ) : (
                    <div style={{ marginLeft: "-10px" }}>
                      <img src={icon} alt="currency-logo" height="25" width="25" />
                    </div>
                  )}
                </div>
                <div className={s.table__assets__pair_info}>
                  <h3>{title}</h3>
                  <p>{price}</p>
                </div>
              </div>
            </td>
            <td data-title="Charts" colSpan={2}>
              <div className={s.table__graph + " " + s.table__graph__feature}>
                <ResponsiveContainer width="100%" height="100%">
                  <AreaChart
                    width={200}
                    height={60}
                    data={graphData}
                    margin={{
                      top: 0,
                      right: 0,
                      left: 0,
                      bottom: 0,
                    }}
                  >
                    <YAxis width={0} domain="auto" tick={false} axisLine={false} />
                    <Area type="monotone" dataKey="C" stroke="#00CD86" strokeWidth={2} fill="url(#colorUv)" />
                    <defs>
                      <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="67%" stopColor="#00CD86" stopOpacity={0.1} />
                        <stop offset="100%" stopColor="rgba(0, 205, 134, 0)" stopOpacity={0.1} />
                      </linearGradient>
                    </defs>
                  </AreaChart>
                </ResponsiveContainer>
              </div>
            </td>
            {/* <td data-title="Sentiments" style={{ display: "table-cell" }}>
              <div className={s.table__sentiments}>
                <div className={s.table__sentiments__info}>
                  <span></span>
                  <p>10 Online</p>
                </div>
                <div className={s.table__sentiments__bar}>
                  <span style={{ width: "10%" }}></span>
                </div>
              </div>
            </td> */}
            <td data-title="Trade" className={s.featureTable_TradeNow}>
              <div className={s.table__trade + " " + s.table__trade__feature}>
                <a href="#">
                  <img src={tradeIcon} alt="trade now icon" height="17" width="16" />
                </a>
              </div>
            </td>
          </tr>
          <tr className={s.featureTable_Row_Mobile}>
            <td data-title="Type">
              <div className={s.table__type}>
                {type?.map((t, index) => (
                  <strong key={index} style={{ color: index === 2 ? getColor(t) : "#cdd6f5" }}>
                    {t}:
                  </strong>
                ))}
              </div>
            </td>
            <td data-title="5min">
              <div className={s.table__five}>
                {fiveMin?.map((f, index) => (
                  <strong key={index} style={{ color: index === 2 ? getColor(f) : "#cdd6f5" }}>
                    {f}
                  </strong>
                ))}
              </div>
            </td>
            <td data-title="15min">
              <div className={s.table__fifteen}>
                {fifteenMin?.map((f, index) => (
                  <strong key={index} style={{ color: index === 2 ? getColor(f) : "#cdd6f5" }}>
                    {f}
                  </strong>
                ))}
              </div>
            </td>
            <td data-title="Hourly">
              <div className={s.table__hourly}>
                {hourly?.map((f, index) => (
                  <strong key={index} style={{ color: index === 2 ? getColor(f) : "#cdd6f5" }}>
                    {f}
                  </strong>
                ))}
              </div>
            </td>
            <td data-title="Daily">
              <div className={s.table__daily}>
                {daily?.map((f, index) => (
                  <strong key={index} style={{ color: index === 2 ? getColor(f) : "#cdd6f5" }}>
                    {f}
                  </strong>
                ))}
              </div>
            </td>
          </tr>
        </>
      ) : (
        <tr>
          <td data-title="Assets" className={s.featureTable__sm_row}>
            <div className={s.table__assets}>
              <div className={s.table__assets__pair_icons} style={{ width: 12 }}>
                {icon1 ? (
                  <>
                    <div>
                      <img src={icon} alt="currency-logo" height="25" width="25" />
                    </div>
                    <div>
                      <img src={icon1} alt="currency-logo" height="25" width="25" />
                    </div>
                  </>
                ) : (
                  <div>
                    <img src={icon} alt="currency-logo" height="28" width="28" />
                  </div>
                )}
              </div>
              <div className={s.table__assets__pair_info}>
                <h3>{title}</h3>
                <p>{price}</p>
              </div>
            </div>
          </td>
          <td data-title="Charts">
            <div className={s.table__graph}>
              <ResponsiveContainer width="100%" height="100%">
                <AreaChart
                  width={200}
                  height={60}
                  data={graphData}
                  margin={{
                    top: 0,
                    right: 0,
                    left: 0,
                    bottom: 0,
                  }}
                >
                  <YAxis width={0} domain="auto" tick={false} axisLine={false} />
                  <Area type="monotone" dataKey="C" stroke="#00CD86" strokeWidth={2} fill="url(#colorUv)" />
                  <defs>
                    <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="67%" stopColor="#00CD86" stopOpacity={0.1} />
                      <stop offset="100%" stopColor="rgba(0, 205, 134, 0)" stopOpacity={0.1} />
                    </linearGradient>
                  </defs>
                </AreaChart>
              </ResponsiveContainer>
            </div>
          </td>
          {/* <td data-title="Charts" className={s.featureTable__sm_row}>
            <div className={s.table__sentiments}>
              <div className={s.table__sentiments__info}>
                <span></span>
                <p>10 Online</p>
              </div>
              <div className={s.table__sentiments__bar}>
                <span style={{ width: "20%" }}></span>
              </div>
            </div>
          </td> */}
          <td data-title="Type">
            <div className={s.table__type}>
              {type?.map((t, index) => (
                <strong key={index}>{t}:</strong>
              ))}
            </div>
          </td>
          <td data-title="5min" className={s.featureTable__md_row}>
            <div className={s.table__five}>
              {fiveMin?.map((f, index) => (
                <strong key={index} style={{ color: index === 2 ? getColor(f) : "#cdd6f5" }}>
                  {f}
                </strong>
              ))}
            </div>
          </td>
          <td data-title="15min" className={s.featureTable__md_row}>
            <div className={s.table__fifteen}>
              {fifteenMin?.map((f, index) => (
                <strong key={index} style={{ color: index === 2 ? getColor(f) : "#cdd6f5" }}>
                  {f}
                </strong>
              ))}
            </div>
          </td>
          <td data-title="Hourly" className={s.featureTable__md_row}>
            <div className={s.table__hourly}>
              {hourly?.map((h, index) => (
                <strong key={index} style={{ color: index === 2 ? getColor(h) : "#cdd6f5" }}>
                  {h}
                </strong>
              ))}
            </div>
          </td>
          <td data-title="Daily" className={s.featureTable__md_row}>
            <div className={s.table__daily}>
              {daily?.map((d, index) => (
                <strong key={index} style={{ color: index === 2 ? getColor(d) : "#cdd6f5" }}>
                  {d}
                </strong>
              ))}
            </div>
          </td>
          <td data-title="Trade Now">
            <div className={s.table__trade}>
              <a href="https://app.optionblitz.co/trading" target="_blank">
                <img src={tradeIcon} alt="trade now icon" />
              </a>
            </div>
          </td>
        </tr>
      )}
    </>
  );
};

// Table rows to render if table is for Activity Section in the presale page.
const ActivityRow = ({ date, amount, address, view }) => {
  return (
    <>
      <tr>
        <td data-title="Date">
          <div className={s.table__date}>
            <strong>{date}</strong>
          </div>
        </td>
        <td data-title="Address">
          <div className={s.table__address}>
            <strong>{address}</strong>
          </div>
        </td>
        <td data-title="Amount">
          <div className={s.table__price}>
            <strong>{amount} USDC</strong>
          </div>
        </td>
        <td data-title="View On-Chain">
          <div className={s.table__view}>
            <a href={view} target="_blank">
              <img src={viewIcon} alt="view-icon" height="16" width="16" />
            </a>
          </div>
        </td>
      </tr>
    </>
  );
};
const Table = ({ data, columns, featureTable = false, activity = false, markets = false, showMore }) => {
  const { contracts: contractState } = useContract();
  const { contracts, latestPurchases } = contractState;
  const [activityTableData, setActivityTableData] = useState([]);
  const maxActivityRows = 50;
  useEffect(() => {
    console.log(contractState);
    const network = contractState.deployment?.network;
    const purchases = (latestPurchases || [])
      .filter((v, i) => i < maxActivityRows)
      .map((p) => {
        return {
          date: formatDate(new Date(p.timestamp)),
          amount: p.usdc,
          address: p.buyer,
          view: `https://${network === "mainnet" ? "" : network + "."}etherscan.io/tx/${p.transactionHash}`,
        };
      });
    setActivityTableData(purchases);
  }, [contractState, latestPurchases]);

  return (
    <table
      className={`${s.table} ${featureTable ? s.featureTable : activity ? s.activityTable : ""}`}
      cellPadding={0}
      cellSpacing={0}
    >
      <tbody>
        <tr>
          {columns.map((i, index) => {
            return (
              <th
                key={index}
                className={
                  featureTable && (index === 0 || index === 2)
                    ? s.featureTable__sm_col
                    : index > 3
                    ? s.featureTable__md_col
                    : ""
                }
              >
                <div>{i}</div>
              </th>
            );
          })}
        </tr>

        {/* Featured Signals */}
        {featureTable &&
          Object.keys(data).map((symbol, index) => (
            <FeatureTableRow
              key={index}
              icon={data[symbol].icon}
              icon1={data[symbol].icon1}
              title={data[symbol].s}
              price={data[symbol].p}
              graphData={data[symbol].graphData}
              fiveMin={data[symbol].fiveMin}
              fifteenMin={data[symbol].fifteenMin}
              hourly={data[symbol].hourly}
              type={data[symbol].type}
              daily={data[symbol].daily}
            />
          ))}

        {/* Markets Table */}
        {markets &&
          Object.keys(data)
            .slice(0, showMore ? Object.keys(data).length : 5)
            .map((symbol, index) => (
              <TableRow
                icon={data[symbol].icon}
                icon1={data[symbol].icon1}
                title={data[symbol].s}
                price={data[symbol].p}
                change={data[symbol].dc}
                graphData={data[symbol].graphData}
                key={index}
              />
            ))}

        {/* Activity Table in presale page */}
        {activity &&
          activityTableData.map((t, index) => (
            <ActivityRow key={index} date={t.date} amount={t.amount} address={t.address} view={t.view} />
          ))}
      </tbody>
    </table>
  );
};

const TableBlock = ({ featureTable = false, activity = false, markets = false, data, activeTab, setActiveTab }) => {
  const [showMore, setShowMore] = useState(false);

  const [id, setId] = useState(1);
  const { t } = useTranslation();

  function sortData(id, data) {
    let obj = {};
    let sorted_arr = [];
    const unsorted_data = Object.keys(data).map((key) => {
      return { [key]: data[key].dc };
    });
    sorted_arr = unsorted_data.sort((a, b) => a[Object.keys(a)[0]] - b[Object.keys(b)[0]]);
    if (id === 1) {
      sorted_arr = sorted_arr.reverse();
    }
    for (const symbol of sorted_arr) {
      obj[Object.keys(symbol)[0]] = data[Object.keys(symbol)[0]];
    }

    return obj;
  }

  return (
    <>
      {/* For the Featured signals section in landing page. */}
      {featureTable && <Table data={data} columns={featureTableColumns} featureTable={true} />}

      {/* For the markets section in landing page. */}
      {markets && (
        <div className={s.table_block}>
          <div className={s.table_block__row}>
            <Tabs activeTab={activeTab} setActiveTab={setActiveTab} />
            <Sorting setId={setId} />
          </div>

          <Table data={sortData(id, data)} columns={marketsTableColumns} showMore={showMore} markets={true} />
          {Object.keys(data).length > 5 && (
            <div className="show-more" onClick={() => setShowMore(!showMore)}>
              <p>{showMore ? "Show less" : "Show more"}</p>
              <img src={chevronRight} alt="icon" />
            </div>
          )}
          <div className={s.table_block__row}>
            <p>{t("Markets.text")}</p>
            <Button link="https://app.optionblitz.co/trading">Start trading</Button>
          </div>
        </div>
      )}

      {/* For the activity section in the presale page. */}
      {activity && (
        <>
          <Table data={"data"} columns={activityTableColumns} activity={true} />
          {activityTableColumns.length > 5 && (
            <div className="show-more" onClick={() => setShowMore(!showMore)}>
              <p>{showMore ? t("Markets.show less") : t("Markets.show more")}</p>
              <img src={chevronRight} alt="icon" height="8" />
            </div>
          )}
        </>
      )}
    </>
  );
};

export default TableBlock;
