import React, { useEffect, useState } from "react";
import s from "./BuyInput.module.scss";

const BuyInput = ({ title, isHaveMaxButton, currency, amount, changeHandler }) => {
  let [isAdaptive, setIsAdaptive] = useState(window.screen.width <= 768);

  useEffect(() => {
    window.addEventListener(
      "resize",
      function (event) {
        setIsAdaptive(window.screen.width <= 768);
      },
      true
    );
  }, []);

  return (
    <div className={s.input}>
      <span>{title}</span>
      <div className={s.input__block}>
        <div className={s.input__field}>
          <input
            type="text"
            value={amount}
            onChange={(e) => changeHandler(e, currency)}
            onKeyDown={(e) => {
              if ((e.code === "ArrowUp") | (e.code === "ArrowDown")) {
                e.preventDefault();
              }
            }}
          />
          <div className={s.input__field_info}>
            {isHaveMaxButton && <button>MAX</button>}
            <span>{currency}</span>
          </div>
        </div>
        <div className={s.input__controls}>
          <button
            name="minus"
            className={s.input__controls_minus}
            onClick={(e) => {
              changeHandler(e, currency);
            }}
          ></button>
          <button
            name="plus"
            className={s.input__controls_plus}
            onClick={(e) => {
              changeHandler(e, currency);
            }}
          ></button>
        </div>
      </div>
    </div>
  );
};
export default BuyInput;
