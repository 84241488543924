import { Provider } from "@ethersproject/abstract-provider";
import { BigNumber, Signer, utils, constants } from "ethers";
import { _OptionBlitzContracts } from "./contracts";
import {
  BlxPresale__factory,
  FiatTokenV2_1__factory,
  Multicall2,
  BlxPresale,
  FiatTokenV2_1,
  BiconomyForwarder,
} from "./typechain-types";
import {
  getSignedPermit,
  getBiconomySignedRequest,
  getBiconomyDomainSeparator,
  biconomyForwarderAddresses,
} from "./metaTx";
import { getForwarder } from "./forwarder";

const USDC_DECIMALS = 6;
const PRECISION = 18446744073709551616; // 2**64

const isMetaMask = (provider: Provider) => {
  return (((provider as any) || {}).connection || {}).url === "metamask";
};

const isEIP1193 = (provider: Provider) => {
  return (((provider as any) || {}).connection || {}).url === "eip-1193:";
};

const getPresalePurchaseInfo = async (
  multicall: Multicall2,
  blxPresale: BlxPresale,
  usdc: FiatTokenV2_1,
  forwarder: BiconomyForwarder,
  provider: Provider,
  from: string
) => {
  const multiCallStructs: Multicall2.CallStruct[] = [
    [blxPresale.address, blxPresale.interface.encodeFunctionData("blxRate")],
    [blxPresale.address, blxPresale.interface.encodeFunctionData("minAmount")],
    [blxPresale.address, blxPresale.interface.encodeFunctionData("maxPurchase")],
    [multicall.address, multicall.interface.encodeFunctionData("getEthBalance", [from])],
    [usdc.address, usdc.interface.encodeFunctionData("allowance", [from, blxPresale.address])],
    [usdc.address, usdc.interface.encodeFunctionData("balanceOf", [from])],
    [blxPresale.address, blxPresale.interface.encodeFunctionData("txCost")],
    [blxPresale.address, blxPresale.interface.encodeFunctionData("presaleClosed")],
    [blxPresale.address, blxPresale.interface.encodeFunctionData("presaleActive")],
    [blxPresale.address, blxPresale.interface.encodeFunctionData("presaleStart")],
    [usdc.address, usdc.interface.encodeFunctionData("nonces", [from])],
    [usdc.address, usdc.interface.encodeFunctionData("name")],
    [usdc.address, usdc.interface.encodeFunctionData("version")],
    [forwarder.address, forwarder.interface.encodeFunctionData("getNonce", [from, 0])],
  ].map(([target, callData]) => ({
    target,
    callData,
  }));

  const tx = await multicall.populateTransaction.aggregate(multiCallStructs);
  const results = await provider.call(tx);
  const x = multicall.interface.decodeFunctionResult("aggregate", results);
  const { blockNumber, returnData }: { blockNumber: BigNumber; returnData: string[] } = x as any;
  return {
    blxRate: blxPresale.interface.decodeFunctionResult("blxRate", returnData[0] as string)[0],
    minAmount: blxPresale.interface.decodeFunctionResult("minAmount", returnData[1] as string)[0],
    maxPurchase: blxPresale.interface.decodeFunctionResult("maxPurchase", returnData[2] as string)[0],
    ethBalance: multicall.interface.decodeFunctionResult("getEthBalance", returnData[3] as string)[0],
    usdcAllowance: usdc.interface.decodeFunctionResult("allowance", returnData[4] as string)[0],
    usdcBalance: usdc.interface.decodeFunctionResult("balanceOf", returnData[5] as string)[0],
    txCost: blxPresale.interface.decodeFunctionResult("txCost", returnData[6] as string)[0],
    presaleClosed: blxPresale.interface.decodeFunctionResult("presaleClosed", returnData[7] as string)[0],
    presaleActive: blxPresale.interface.decodeFunctionResult("presaleActive", returnData[8] as string)[0],
    presaleStart: blxPresale.interface.decodeFunctionResult("presaleStart", returnData[9] as string)[0],
    permitNonce: usdc.interface.decodeFunctionResult("nonces", returnData[10] as string)[0],
    permitName: usdc.interface.decodeFunctionResult("name", returnData[11] as string)[0],
    permitVersion: usdc.interface.decodeFunctionResult("version", returnData[12] as string)[0],
    forwardNonce: forwarder.interface.decodeFunctionResult("getNonce", returnData[13] as string)[0],
  };
};

export function parseUsdc(value: string): BigNumber {
  return utils.parseUnits(value, USDC_DECIMALS);
}

export function toUsdc(value: BigNumber): string {
  return utils.formatUnits(value, USDC_DECIMALS);
}

export function toAbdk({ val }: { val: number }): BigNumber {
  return utils.parseUnits(`${val * PRECISION}`, 0);
}

export function fromAbdk({ val, precision }: { val: BigNumber; precision: number }): string {
  return utils.formatUnits(
    val.mul(precision >= 0 ? 10 ** precision : 1).div(BigNumber.from(`${PRECISION}`)),
    precision >= 0 ? precision : -precision
  );
}

export const calcPrice = (blxSold: number, blxToBuy: number) => {
  //const k1 = 1 + 33/100;
  const k1 = 1 + 1 / 3;
  const k2 = 0.001 / k1;
  const usdcNeeded = k2 * Math.pow(blxSold + blxToBuy, k1) - k2 * Math.pow(blxSold, k1) + 0.1 * blxToBuy;
  const price = usdcNeeded / blxToBuy;
  return { usdcNeeded, price };
};

export const calcBlxAmount = (blxSold: number, currentPrice: number, usdc: number) => {
  let startPrice = currentPrice;
  let blxToBuy = Math.round(usdc / startPrice);
  let { usdcNeeded, price } = calcPrice(blxSold, blxToBuy);
  // bsearch for the 'target price' thus the amount of blx
  while (Math.abs(usdcNeeded - usdc) >= 1) {
    console.log(`${usdc} ${usdcNeeded} ${price} ${blxToBuy}`);
    blxToBuy = Math.round(usdc / ((startPrice + price) / 2));
    ({ usdcNeeded, price } = calcPrice(blxSold, blxToBuy));
    startPrice = price;
  }
  console.log(`${usdc} ${usdcNeeded} ${price} ${blxToBuy}`);
  return { usdcNeeded, blxToBuy, price };
};

export const startPresale = async (provider: Provider, signer: Signer, optionBlitz: _OptionBlitzContracts) => {
  const BlxPresale = optionBlitz.BlxPresale.connect(signer);
  const metamask = isMetaMask(signer.provider);
  if (!metamask) {
    const unsignedTx = await BlxPresale.populateTransaction.start();
    const signedTx = await signer.signTransaction(unsignedTx);
    return provider.sendTransaction(signedTx);
  } else {
    // metamask(may be walletconnect too) don't support tx signing, must send via it
    return await BlxPresale.start();
  }
};

export const startIBCO = async (provider: Provider, signer: Signer, optionBlitz: _OptionBlitzContracts) => {
  const ibco = optionBlitz.IBCO.connect(signer);
  const metamask = isMetaMask(signer.provider);
  if (!metamask) {
    const unsignedTx = await ibco.populateTransaction.start();
    const signedTx = await signer.signTransaction(unsignedTx);
    return provider.sendTransaction(signedTx);
  } else {
    // metamask(may be walletconnect too) don't support tx signing, must send via it
    return await ibco.start();
  }
};

export const setPresaleMinAmount = async (
  amount: number,
  provider: Provider,
  signer: Signer,
  optionBlitz: _OptionBlitzContracts
) => {
  const BlxPresale = optionBlitz.BlxPresale.connect(signer);
  const metamask = isMetaMask(signer.provider);
  if (!metamask) {
    const unsignedTx = await BlxPresale.populateTransaction.setMinAmount(BigNumber.from(Math.round(amount * 1e6)));
    const signedTx = await signer.signTransaction(unsignedTx);
    return provider.sendTransaction(signedTx);
  } else {
    // metamask(may be walletconnect too) don't support tx signing, must send via it
    return await BlxPresale.setMinAmount(BigNumber.from(Math.round(amount * 1e6)));
  }
};

export const setIBCOMinAmount = async (
  amount: number,
  provider: Provider,
  signer: Signer,
  optionBlitz: _OptionBlitzContracts
) => {
  const ibco = optionBlitz.IBCO.connect(signer);
  const metamask = isMetaMask(signer.provider);
  if (!metamask) {
    const unsignedTx = await ibco.populateTransaction.setMinAmount(BigNumber.from(Math.round(amount * 1e6)));
    const signedTx = await signer.signTransaction(unsignedTx);
    return provider.sendTransaction(signedTx);
  } else {
    // metamask(may be walletconnect too) don't support tx signing, must send via it
    return await ibco.setMinAmount(BigNumber.from(Math.round(amount * 1e6)));
  }
};

export const transferPresaleTxFee = async (provider: Provider, signer: Signer, optionBlitz: _OptionBlitzContracts) => {
  const BlxPresale = optionBlitz.BlxPresale.connect(signer);
  const metamask = isMetaMask(signer.provider);
  if (!metamask) {
    const unsignedTx = await BlxPresale.populateTransaction.transferTxFee();
    const signedTx = await signer.signTransaction(unsignedTx);
    return provider.sendTransaction(signedTx);
  } else {
    // metamask(may be walletconnect too) don't support tx signing, must send via it
    return await BlxPresale.transferTxFee();
  }
};

export const transferIBCOTxFee = async (provider: Provider, signer: Signer, optionBlitz: _OptionBlitzContracts) => {
  const ibco = optionBlitz.IBCO.connect(signer);
  const metamask = isMetaMask(signer.provider);
  if (!metamask) {
    const unsignedTx = await ibco.populateTransaction.transferTxFee();
    const signedTx = await signer.signTransaction(unsignedTx);
    return provider.sendTransaction(signedTx);
  } else {
    // metamask(may be walletconnect too) don't support tx signing, must send via it
    return await ibco.transferTxFee();
  }
};

export const vestToken = async (
  vestingParam: {
    beneficiary: string;
    releaseStartTime: number; // time vesting release start(may still be locked for 'lockUnti' but release portion count starts)
    lockUntil: number; // seconds add to start time before token can be released
    releaseDuration: number; // duration from start time before fully releasable
    secondsPerSlice: number; // portion releaseable with reference to duration
    amount: number; // token amount
  },
  provider: Provider,
  signer: Signer,
  optionBlitz: _OptionBlitzContracts
) => {
  const { beneficiary, releaseDuration, releaseStartTime, lockUntil, secondsPerSlice, amount } = vestingParam;
  const tokenVesting = optionBlitz.TokenVesting.connect(signer);
  const blxToken = optionBlitz.BlxToken.connect(signer);
  const amountInWei = parseUsdc(`${amount}`);
  const metamask = isMetaMask(signer.provider);
  if (!metamask) {
    const unsignedTx = await tokenVesting.populateTransaction.createVestingSchedule(
      beneficiary,
      releaseStartTime,
      lockUntil || releaseStartTime,
      releaseDuration || 1,
      secondsPerSlice || 1,
      false,
      amountInWei
    );
    const signedTx = await signer.signTransaction(unsignedTx);
    return provider.sendTransaction(signedTx);
  } else {
    // metamask(may be walletconnect too) don't support tx signing, must send via it
    return await tokenVesting.createVestingSchedule(
      beneficiary,
      releaseStartTime,
      lockUntil || releaseStartTime,
      releaseDuration || 1,
      secondsPerSlice || 1,
      false,
      amountInWei
    );
  }
};

export const releaseToken = async (
  vestingScheduleId: string,
  amount: number,
  provider: Provider,
  signer: Signer,
  optionBlitz: _OptionBlitzContracts
) => {
  const tokenVesting = optionBlitz.TokenVesting.connect(signer);
  const metamask = isMetaMask(signer.provider);
  const amountInWei = parseUsdc(`${amount}`);
  if (!metamask) {
    const unsignedTx = await tokenVesting.populateTransaction.release(vestingScheduleId, amountInWei);
    const signedTx = await signer.signTransaction(unsignedTx);
    return provider.sendTransaction(signedTx);
  } else {
    // metamask(may be walletconnect too) don't support tx signing, must send via it
    return await tokenVesting.release(vestingScheduleId, amountInWei);
  }
};

export const enterPresale = async (
  tx: { amount: number; referrer?: string },
  provider: Provider,
  signer: Signer,
  optionBlitz: _OptionBlitzContracts
) => {
  if (!signer) {
    throw new Error("no wallet available for signing purchase");
  }
  const gasMultiplier = 2;
  const inUSDC = true;
  const payDirect = true;
  const signerProvider = signer.provider;
  console.log("signer provider", signerProvider);
  const MultiCall = optionBlitz.Multicall2.connect(signerProvider || provider);
  const BlxPresale = optionBlitz.BlxPresale.connect(signerProvider || provider);
  let TokenSale = optionBlitz.TokenSale.connect(signerProvider || provider);
  let USDC = optionBlitz.USDC.connect(signerProvider || provider);
  let Forwarder = optionBlitz.Forwarder.connect(signerProvider || provider);
  let { chainId } = await USDC.provider.getNetwork();
  let forwarder = getForwarder(chainId, optionBlitz);
  const { amount, referrer } = tx;
  const from = await signer.getAddress();
  const purchaseInfo = await getPresalePurchaseInfo(MultiCall, BlxPresale, USDC, Forwarder, signerProvider, from);
  console.log(purchaseInfo);
  const {
    blxRate,
    minAmount,
    maxPurchase,
    usdcAllowance,
    usdcBalance,
    txCost,
    presaleActive,
    presaleClosed,
    presaleStart,
    ethBalance,
  } = purchaseInfo;
  const allowance = +toUsdc(usdcAllowance);
  const balance = +toUsdc(usdcBalance);
  const usdcAmount = BigNumber.from(parseUsdc(`${amount.toFixed(USDC_DECIMALS)}`)).div(inUSDC ? 1 : blxRate);
  const blxAmount = BigNumber.from(parseUsdc(`${amount.toFixed(USDC_DECIMALS)}`)).mul(inUSDC ? blxRate : 1);
  const permitDeadline = BigNumber.from(Math.round(Date.now() / 1000) + 60 * 5); // 5 min deadline
  //const walletNetProvider = await getWalletNetProvider();
  //const fallbackNetProvider = getFallbackNetProvider();
  const minEth = BigNumber.from("100000000000000000"); // 0.1 eth
  const skipPreCheck = false;
  //console.log(minAmount);
  // if (true) {
  //     return {
  //         txHash: '0x87fe38718c89e1bdf295f8fa1d261ffd09e1e101f3889ec7eab6a439601ec613',
  //     }
  // }
  if (presaleClosed && !skipPreCheck) {
    return Promise.reject({
      message: "presale closed",
    });
  }

  if ((!presaleActive || Date.now() < presaleStart.toNumber() * 1000) && !skipPreCheck) {
    return Promise.reject({
      message: "presale not started",
    });
  }

  if (amount < +toUsdc(minAmount) && !skipPreCheck) {
    return Promise.reject({
      message: "not meeting min purchase",
      required: +toUsdc(minAmount),
      purchase: amount,
    });
  }

  if (amount > +toUsdc(maxPurchase) && !skipPreCheck) {
    return Promise.reject({
      message: `purchase exceed available ${toUsdc(maxPurchase)}  USDC`,
      required: +toUsdc(maxPurchase),
      purchase: amount,
    });
  }
  //console.log(`${from} ${chainId} usdc(${USDC.address}) allowance`, allowance, balance);
  const multiCallStructs: Multicall2.CallStruct[] = [];
  const txFee = ethBalance.gte(minEth) ? 0 : +toUsdc(txCost);
  if (balance < amount + txFee && !skipPreCheck) {
    return Promise.reject({
      message: `No ETH balance detected, so transaction fees will be taken in USDC. Purchase rejected because you do not have at least ${amount} + ${txFee} = ${
        amount + txFee
      } USDC to complete the purchase.`,
      required: amount + txFee,
      available: balance,
    });
  }
  if (allowance < amount + txFee || skipPreCheck) {
    let blxPresaleAddress = BlxPresale.address;
    const { permitNonce, permitName, permitVersion } = purchaseInfo;
    const signedPermit = await getSignedPermit(
      signer,
      USDC,
      blxPresaleAddress,
      usdcAmount.add(ethBalance.gte(minEth) ? BigNumber.from(0) : txCost).toNumber(),
      permitDeadline.toNumber(),
      { nonce: permitNonce.toNumber(), name: permitName, chainId: chainId, version: permitVersion }
    );
    //const { permitTx, permitResult } = await forwarder.sendEIP2612PermitRequest(USDC.address, signedPermit);
    //console.log(permitTx, permitResult);
    const { multicallStruct } = await forwarder.sendEIP2612PermitRequest(USDC.address, signedPermit, true);
    multiCallStructs.push(multicallStruct);
  }
  const permitCall = multiCallStructs.length > 0 ? multiCallStructs[0].callData : "0x";
  const permitForwarderCall = multiCallStructs.length > 1 ? multiCallStructs[1].callData : "0x";
  const gasLimit = skipPreCheck
    ? BigNumber.from(300000)
    : await TokenSale.connect(provider).estimateGas.enterPresale(
        usdcAmount,
        referrer || constants.AddressZero,
        permitCall,
        permitForwarderCall,
        { from }
      );
  const gasPrice = (await (signer.provider || provider).getGasPrice()).add(5 * 1e9);
  const ethNeeded = gasPrice.mul(gasLimit).mul(gasMultiplier);
  //const gasLimit = BigNumber.from(204320); //measured from test
  console.log(`${from} eth balance ${ethBalance} ${gasPrice} ${gasLimit} ${ethNeeded.toBigInt()}`);
  if (payDirect && ethBalance.gt(ethNeeded)) {
    const submittedTx = await TokenSale.connect(signer).enterPresale(
      usdcAmount,
      referrer || constants.AddressZero,
      permitCall,
      permitForwarderCall
    );
    const { hash: txHash } = submittedTx;
    console.log(txHash, submittedTx);
    return { txHash };
  } else {
    const presaleCallData = TokenSale.interface.encodeFunctionData("enterPresale", [
      usdcAmount,
      referrer || constants.AddressZero,
      permitCall,
      permitForwarderCall,
    ]);
    const batchId = 0;
    const { forwardNonce } = purchaseInfo;
    const tokenGasPrice = 0;
    const requestDeadline = BigNumber.from(Math.round(Date.now() / 1000) + 60 * 60); // 60 min deadline
    const signedForwardRequest = await getBiconomySignedRequest(
      signer,
      Forwarder,
      TokenSale.address,
      gasLimit.mul(gasMultiplier).toNumber(),
      USDC.address,
      tokenGasPrice,
      requestDeadline.toNumber(),
      presaleCallData,
      { chainId, batchNonce: forwardNonce.toNumber() }
    );
    const domainSeparator = await getBiconomyDomainSeparator(Forwarder.address, chainId);
    console.log(signedForwardRequest, `${signedForwardRequest.sig}`, utils.joinSignature(signedForwardRequest.sig));
    return forwarder.sendBiconomyERC20ForwardRequest({
      ...signedForwardRequest,
      domainSeparator,
      func: "enterPresale",
      chainId,
    });
  }
};

export const enterIBCO = async (
  tx: { blxAmount: number; amount: number; referrer?: string },
  provider: Provider,
  signer: Signer,
  optionBlitz: _OptionBlitzContracts
) => {
  const gasMultiplier = 2;
  const inUSDC = true;
  const payDirect = true;
  const TokenSale = optionBlitz.TokenSale.connect(provider);
  const IBCO = optionBlitz.IBCO.connect(provider);
  const USDC = optionBlitz.USDC.connect(provider);
  const Forwarder = optionBlitz.Forwarder.connect(provider);
  const { amount, referrer } = tx;
  const from = await signer.getAddress();
  const blxSold = +toUsdc(await IBCO.distributedBlx());
  const currentPrice = +toUsdc(await IBCO.currentPrice());
  const txCost = await IBCO.txCost();
  const ethBalance = await (signer.provider || provider).getBalance(from);
  const gasPrice = await (signer.provider || provider).getGasPrice();
  const { usdcNeeded, blxToBuy, price } = calcBlxAmount(blxSold, currentPrice, amount);
  const { usdc, price18, blx } = await IBCO.calcPrice(parseUsdc(`${blxSold}`), parseUsdc(`${blxToBuy}`));
  const usdcAmount = usdc;
  const blxAmount = blx;
  console.log(`${amount} ${blxAmount} ${toUsdc(txCost)}`);
  const permitDeadline = BigNumber.from(Math.round(Date.now() / 1000) + 60 * 5); // 5 min deadline
  const permitNonce = await USDC.nonces(from);
  const permitName = await USDC.name();
  const permitVersion = await USDC.version();
  const { chainId } = await USDC.provider.getNetwork();
  const forwarder = getForwarder(chainId, optionBlitz);
  const allowance = +toUsdc(await USDC.allowance(from, IBCO.address));
  const multiCallStructs: Multicall2.CallStruct[] = [];
  console.log("usdc allowance", allowance);
  if (allowance < amount + +toUsdc(txCost)) {
    const signedPermit = await getSignedPermit(
      signer,
      USDC,
      IBCO.address,
      usdcAmount.add(txCost).toNumber(),
      permitDeadline.toNumber(),
      { nonce: permitNonce.toNumber(), name: permitName, chainId, version: permitVersion }
    );
    //const { permitTx, permitResult } = await forwarder.sendEIP2612PermitRequest(USDC.address, signedPermit);
    //console.log(permitTx, permitResult);
    const { multicallStruct } = await forwarder.sendEIP2612PermitRequest(USDC.address, signedPermit, true);
    multiCallStructs.push(multicallStruct);
  }
  const permitCall = multiCallStructs.length > 0 ? multiCallStructs[0].callData : "0x";
  const permitForwarderCall = multiCallStructs.length > 1 ? multiCallStructs[1].callData : "0x";
  const gasLimit = await TokenSale.connect(provider).estimateGas.enterIbco(
    blxAmount,
    usdcAmount,
    referrer || constants.AddressZero,
    permitCall,
    permitForwarderCall,
    { from }
  );
  //const gasLimit = BigNumber.from(168980/277140); //measured from test increase due to the pricing model change
  if (payDirect && ethBalance.gt(gasPrice.mul(gasLimit).mul(gasMultiplier))) {
    const submittedTx = await TokenSale.connect(signer).enterIbco(
      blxAmount,
      usdcAmount,
      referrer || constants.AddressZero,
      permitCall,
      permitForwarderCall
    );
    const { hash: txHash } = submittedTx;
    console.log(txHash, submittedTx);
    return { txHash };
  } else {
    const ibcoCallData = (
      await TokenSale.populateTransaction.enterIbco(
        blxAmount,
        usdcAmount,
        referrer || constants.AddressZero,
        permitCall,
        permitForwarderCall,
        { from }
      )
    ).data;
    const batchId = 0;
    const forwardNonce = await Forwarder.getNonce(from, batchId);
    const tokenGasPrice = 0;
    const requestDeadline = BigNumber.from(Math.round(Date.now() / 1000) + 60 * 60); // 60 min deadline
    const signedForwardRequest = await getBiconomySignedRequest(
      signer,
      Forwarder,
      TokenSale.address,
      gasLimit.mul(gasMultiplier).toNumber(),
      USDC.address,
      tokenGasPrice,
      requestDeadline.toNumber(),
      ibcoCallData,
      { chainId, batchNonce: forwardNonce.toNumber() }
    );

    console.log(signedForwardRequest, `${signedForwardRequest.sig}`, utils.joinSignature(signedForwardRequest.sig));
    return forwarder.sendBiconomyERC20ForwardRequest(signedForwardRequest);
  }
};

export const claimPresale = async (provider: Provider, signer: Signer, optionBlitz: _OptionBlitzContracts) => {
  const BlxPresale = optionBlitz.BlxPresale.connect(signer);
  return BlxPresale.claim();
};

export const claimPresaleReward = claimPresale;

export const refundPresale = async (provider: Provider, signer: Signer, optionBlitz: _OptionBlitzContracts) => {
  const BlxPresale = optionBlitz.BlxPresale.connect(signer);
  return BlxPresale.refund(constants.AddressZero);
};

export const claimIBCO = async (provider: Provider, signer: Signer, optionBlitz: _OptionBlitzContracts) => {
  const IBCO = optionBlitz.IBCO.connect(signer);
  return IBCO.claim();
};

export const claimIBCOReward = claimIBCO;

export const refundIBCO = async (provider: Provider, signer: Signer, optionBlitz: _OptionBlitzContracts) => {
  const IBCO = optionBlitz.IBCO.connect(signer);
  return IBCO.refund();
};

export const transferToDaoAgent = async (provider: Provider, signer: Signer, optionBlitz: _OptionBlitzContracts) => {
  const BlxPresale = optionBlitz.BlxPresale.connect(signer);
  return BlxPresale.transferToDaoAgent();
};
