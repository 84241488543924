import React, { useState } from "react";
import s from "./TokenSale.module.scss";
import Button from "../UI/Button/Button";
import Fade from "react-reveal/Fade";
import Timer from "../Timer/Timer";
import { TokenSaleStages1 } from "../TokenSaleStages/TokenSaleStages";
import { items } from "src/utils/data";
import file from "../../assets/file/optionblitz_whitepaper.pdf";
import { useTranslation } from "react-i18next";
import { IBCO_START_TIME } from "../TokenSaleTimeline/TokenSaleTimeline";

export const Slider = ({ data, stages, presale = false }) => {
  const [activeStage, setActiveStage] = useState(0);
  return (
    <div className={[s.token_sale__item_two, presale ? s.presale : ""].join(" ")}>
      <TokenSaleStages1 stages={stages} activeStage={activeStage} setActiveStage={setActiveStage} presale={presale} />
      {activeStage === 1 && <h2>PRESALE</h2>}
      {activeStage === 2 && <h2>INITIAL BONDING CURVE OFFERING</h2>}
      <div className={[s.token_sale__item_two__info, presale ? s.presale__info : " "].join(" ")}>
        {data[activeStage].map((i, index) => (
          <div
            key={index}
            className={[s.token_sale__item_two__info__info_block, presale ? s.presale__info__info_block : ""].join(" ")}
          >
            <p className={s.token_sale__item_two__info__info_block__title}>{i.title}</p>
            <p className={s.token_sale__item_two__info__info_block__value}>{i.value}</p>
          </div>
        ))}
      </div>
    </div>
  );
};
const TokenSale = () => {
  const { t } = useTranslation();
  const contractAddress = "0xbef6de56b08fc501025d02c41da887f81e23074b";

  return (
    <>
      <section className={s.sale}>
        <div className={s.token_sale} id="token_sale">
          <div className="container">
            <div className={s.token_sale__wrapper}>
              <Fade left>
                <div className={s.token_sale__item_one}>
                  <h2>OPTIONBLITZ TOKEN SALE</h2>
                  <p>
                    {t("TokenSale.desc")}{" "}
                    <a href={file} download>
                      {t("TokenSale.download")}
                    </a>{" "}
                    {t("TokenSale.desc2")}
                  </p>
                  <a href="/presale">
                    <Button> {t("TokenSale.buy blx")}</Button>
                  </a>

                  <div className={s.token_sale__counters}>
                    <div className={s.token_sale__counters_item}>
                      <strong className={s.token_sale__counters_item__title}>
                        Optionblitz {t("TokenSale.sale starts")}
                      </strong>
                      <Timer presaleTimer={true} saleTime={IBCO_START_TIME} />
                    </div>
                  </div>
                </div>
              </Fade>
              <Fade right>
                <div className={s.token_sale__item_two}>
                  <h2>BASICS</h2>

                  <div className={s.token_sale__item_two__info__header}>
                    <p className={s.token_sale__item_two__info__header__title}>Token contract</p>
                    <a
                      href={`https://etherscan.io/address/${contractAddress}`}
                      target="_blank"
                      className={s.token_sale__item_two__info__header__value}
                    >
                      {contractAddress}
                    </a>
                  </div>

                  <div className={s.token_sale__item_two__info}>
                    {items.map((i, index) => {
                      return (
                        <div key={index} className={s.token_sale__item_two__info__info_block}>
                          <p className={s.token_sale__item_two__info__info_block__title}>{i.title}</p>
                          <p className={s.token_sale__item_two__info__info_block__value}>{i.value}</p>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </Fade>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TokenSale;
