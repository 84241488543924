import React, { useState, useEffect } from "react";
import { chartDataInit } from "src/utils/data";
import { breakTransactions } from "src/utils/mockData";
import { GRAPH_INTERVAL, PRESALE_AMOUNT } from "src/constants";
import useContract from "src/hooks/useContract";

const calcPrice = (blxSold, blxToBuy) => {
  //const k1 = 1 + 33 / 100;
  const k1 = 1 + 1 / 3;
  const k2 = 0.001 / k1;
  const usdcNeeded = k2 * Math.pow(blxSold + blxToBuy, k1) - k2 * Math.pow(blxSold, k1) + 0.1 * blxToBuy;
  const price = usdcNeeded / blxToBuy;
  return { usdcNeeded, price };
}

const calcBlxAmount = (blxSold, currentPrice, usdc) => {
  let startPrice = currentPrice;
  let blxToBuy = Math.round(usdc / startPrice);
  let { usdcNeeded, price } = calcPrice(blxSold, blxToBuy);
  // bsearch for the 'target price' thus the amount of blx
  while (Math.abs(usdcNeeded - usdc) >= 1) {
    blxToBuy = Math.round(usdc / ((startPrice + price) / 2));
    ({ usdcNeeded, price } = calcPrice(blxSold, blxToBuy));
    startPrice = price;
  }
  //console.log(`${usdc} ${usdcNeeded} ${price} ${blxToBuy}`);
  return { usdcNeeded, blxToBuy, price };
}

export const useCharts = () => {
  // const [chartData, setChartData] = useState(breakTransactions(chartDataInit[chartDataInit.length - 1].totalSold));
  const [chartData, setChartData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // to keep track of the reference line -> the last index of the original data array.
  const [dataLength, setDataLength] = useState(0);
  const [tokenSold, setTokenSold] = useState(0);
  const [amountUSDC, setAmountUSDC] = useState(0);
  const [amountBLX, setAmountBLX] = useState(0);
  const { contracts: contractState } = useContract();
  const { ibcoBlxSold, presaleBlxSold, blxPrice, presaleEnd } = contractState;
  const [presalePortion, setPresalePortion] = useState(PRESALE_AMOUNT);
  const totalBlxSold = ibcoBlxSold + presaleBlxSold;
  const [tokenPrice, setTokenPrice] = useState(getTokenPrice());

  useEffect(() => {
    if (presaleEnd > 0 && presaleEnd <= Date.now()) setPresalePortion(presaleBlxSold);
  }, [presaleEnd, presaleBlxSold]);

  function changeHandler(e: React.ChangeEvent<HTMLInputElement>, currency: string) {
    if (e.type === "change") {
      if (currency === "BLX") {
        const amount: number = Number(e.target.value);
        if (isNaN(amount)) return;
        setAmountBLX(amount);
        calculateUSDC(amount);
        // const result = breakTransactions(chartData[dataLength - 1].totalSold + amount);
        // setChartData(result);
        // setTokenSold(result[result.length - 1].totalSold);
        // to be sold, not sold
        // setTokenSold(contractState.presaleBlxSold + contractState.ibcoBlxSold + amount);
      }
      if (currency === "USDC") {
        const amount: number = Number(e.target.value);
        if (isNaN(amount)) return;
        setAmountUSDC(amount);
        const blx = calculateBLX(amount);
        // const result = breakTransactions(chartData[dataLength - 1].totalSold + blx);
        // setChartData(result);
        // setTokenSold(result[result.length - 1].totalSold);
        // to be sold, not sold
        // setTokenSold(contractState.presaleBlxSold + contractState.ibcoBlxSold + blx);
      }
    }
    if (e.type === "click") {
      if (currency === "BLX" && e.target.name === "plus") {
        setAmountBLX((amount) => amount + 1);
        const amount = amountBLX + 1;
        calculateUSDC(amount);
        const result = breakTransactions(chartData[dataLength - 1].totalSold + amount);
        // setChartData(result);
        // setTokenSold(result[result.length - 1].totalSold);
        // to be sold, not sold
        // setTokenSold(contractState.presaleBlxSold + contractState.ibcoBlxSold + amount);
      }
      if (currency === "BLX" && e.target.name === "minus") {
        if (amountBLX - 1 > 0) {
          setAmountBLX((amount) => amount - 1);
          const amount = amountBLX - 1;
          calculateUSDC(amount);
          const result = breakTransactions(chartData[dataLength - 1].totalSold + amount);
          // setChartData(result);
          // setTokenSold(result[result.length - 1].totalSold);
          // setTokenSold(contractState.presaleBlxSold + contractState.ibcoBlxSold + amount);
        }
      }
      if (currency === "USDC" && e.target.name === "plus") {
        setAmountUSDC((amount) => amount + 1);
        const amount = amountUSDC + 1;
        const blx = calculateBLX(amount);
        // const result = breakTransactions(chartData[dataLength - 1].totalSold + blx);
        // setChartData(result);
        // setTokenSold(result[result.length - 1].totalSold);
        // to be sold, not sold
        // setTokenSold(contractState.presaleBlxSold + contractState.ibcoBlxSold + blx);
      }
      if (currency === "USDC" && e.target.name === "minus") {
        if (amountUSDC - 1 > 0) {
          setAmountUSDC((amount) => amount - 1);
          const amount = amountUSDC - 1;
          const blx = calculateBLX(amount);
          // const result = breakTransactions(chartData[dataLength - 1].totalSold + blx);
          // setChartData(result);
          // setTokenSold(result[result.length - 1].totalSold);
          // to be sold, not sold
          // setTokenSold(contractState.presaleBlxSold + contractState.ibcoBlxSold + blx);
        }
      }
    }
  }

  //calculate how many tokens can be purchased for given amount of USDC
  function calculateBLX(amount: number) {
    if (totalBlxSold < presalePortion) {
      const tokens = amount / getTokenPrice();
      setAmountBLX(tokens);
    }
    else {
      const { blxToBuy, price: priceJs } = calcBlxAmount(totalBlxSold - presalePortion, blxPrice, amount);
      setAmountBLX(blxToBuy);
      return blxToBuy;

    }
  }

  //calculate how many USDC required to purchase given amount of BLX
  function calculateUSDC(amount: number) {
    if (totalBlxSold < presalePortion) {
      const tokens = amount * getTokenPrice();
      setAmountUSDC(tokens);
      return tokens;
    }
    else {
      const { usdcNeeded, price } = calcPrice(totalBlxSold - presalePortion, amount);
      setAmountUSDC(usdcNeeded);
      return usdcNeeded;
    }
  }

  function getTokenPrice() {
    // x = sold
    // y = 0.1 + 0.001 * x ^ 1/3;
    //console.log(`calc token price ${tokenSold}`);
    if (tokenSold <= presalePortion) return 0.1;
    const t = Math.pow(tokenSold - presalePortion, 1 / 3);
    const tokenPrice = 0.1 + 0.001 * t;
    return tokenPrice;
  }

  async function purchaseBlx() {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        // resetting input fields.
        setAmountBLX(0);
        setAmountUSDC(0);
        // refetching and setting graph data as total blx sold will have updated.
        setData();
        alert(`You bought ${amountBLX}BLX for ${amountUSDC}USDC.`);
        resolve({ amountUSDC, amountBLX });
      }, 5000);
    });
  }

  // @xingxing you can update this function with the original call to the contract. -> should return the number total blx sold.
  async function getTotalBlxSold(): Promise<number> {
    // return new Promise((resolve, reject) => {
    //   setTimeout(() => resolve(40000000), 5000);
    // });

    return contractState?.presaleBlxSold + contractState?.ibcoBlxSold;
  }

  // fetching total blx sold, creating chart data and setting in state.
  async function setData() {
    const blx = await getTotalBlxSold();
    // const blx = contractState.presaleBlxSold + contractState.ibcoBlxSold;

    const txs = breakTransactions(40000000);
    setTokenSold(blx);
    setChartData(txs);
    setDataLength(txs.length);
    setLoading(false);
  }

  // fetching and setting data on initial load.
  useEffect(() => {
    setData();
  }, [contractState]);

  useEffect(() => {
    //console.log(`token sold ${tokenSold}`);
    setTokenPrice(getTokenPrice());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenSold]);

  return {
    tokenSold,
    chartData,
    amountBLX,
    amountUSDC,
    changeHandler,
    tokenPrice,
    purchaseBlx,
    dataLength,
    isLoading: loading,
  };
};
