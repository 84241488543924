import { useEffect, useRef, useState } from "react";

const useObserver = (threshold = 0.1) => {
  const [inView, setInView] = useState(false);
  const sectionRef = useRef(null);

  // attaching a intersection observer to subscribe to the markets data only if section is in viewport, to save data.
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setInView(true);
        } else {
          setInView(false);
        }
      },
      {
        threshold: threshold,
      }
    );
    observer.observe(sectionRef.current);

    return () => {
      observer.unobserve(sectionRef.current);
    };
  }, []);

  return {
    inView,
    ref: sectionRef,
  };
};

export default useObserver;
