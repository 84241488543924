import axios from "axios";
import { FINAGE_API_KEY } from "src/config/constants";
import { optionBlitzBackendDomain } from "src/config";
export const finageApi = axios.create({
  baseURL: `https://${optionBlitzBackendDomain}/finage`,
  // params: {
  //   apikey: FINAGE_API_KEY,
  // },
});

export const optionblitzApi = axios.create({
  baseURL: `https://${optionBlitzBackendDomain}/api/v1`,
});
