import { EventEmitter } from "events";
import Centrifuge, { Subscription, SubscriptionEvents } from "centrifuge";
import { optionBlitzBackendDomain } from "src/config";
const wsEndpoint: Record<string, string> = {
  default: `wss://${optionBlitzBackendDomain}/crypto/d74687c3e0a1ad96674f7e53b9a81a7dd6e87b35`,
};

type ChannelCategory = string;

interface OptionBlitzCentrifugoSubscription {
  channel: Subscription;
  subCount: number;
}

function getAuthenticatedUrl(category: string): string {
  return wsEndpoint[category];
}

function normalizechannel(symbol: ChannelCategory) {
  const normalized = symbol?.replace(/[/_]/g, "");
  return normalized;
}

export class OptionBlitzCentrifugo {
  private _centrifuge: Centrifuge;

  private _connected: boolean = false;

  private _subscriptions: Record<string, OptionBlitzCentrifugoSubscription> = {};

  constructor(wsUrl: string, jwtToken: string, getJwtToken: (ctx: any) => Promise<string>) {
    // v 2.x of centrifuge-js
    const refreshAccessToken = async (ctx: any, cb: (response: Centrifuge.RefreshResponse) => void) => {
      console.log("refresh centrifugo token", ctx);
      const accessToken = await getJwtToken(ctx);
      cb({
        status: 200,
        data: { token: accessToken },
      });
    };

    this._centrifuge = new Centrifuge(`${wsUrl}/connection/websocket`, {
      //      token: jwtToken,
      //      getToken: getJwtToken,
      onRefresh: refreshAccessToken,
      debug: true,
    });
    this._centrifuge.setToken(jwtToken);
  }

  public setToken(accessToken: string) {
    this._centrifuge.setToken(accessToken);
  }

  public start(): () => void {
    const doStop = this._doStart();
    return () => {
      doStop();
    };
  }

  public async subscribe(channel: ChannelCategory) {
    return this._subscribe(normalizechannel(channel));
  }

  public async unSubscribe(channel: ChannelCategory) {
    return this._unSubscribe(normalizechannel(channel));
  }

  public async on(channel: ChannelCategory, listener: (...args: any[]) => void, event = "publish") {
    let emitter = this._subscriptions[normalizechannel(channel)]?.channel;
    if (!emitter) {
      await this.subscribe(normalizechannel(channel));
      emitter = this._subscriptions[normalizechannel(channel)].channel;
    }
    if (emitter) {
      return emitter.on(event, listener);
    }
  }

  public async off(channel: ChannelCategory, listener: (...args: any[]) => void, event = "publish") {
    let emitter = this._subscriptions[normalizechannel(channel)].channel;
    if (!emitter) {
      await this.subscribe(normalizechannel(channel));
      emitter = this._subscriptions[normalizechannel(channel)].channel;
    }
    if (emitter) {
      return emitter.off(event, listener);
    }
  }

  public async once(channel: ChannelCategory, listener: (...args: any[]) => void, event = "publish") {
    let emitter = this._subscriptions[normalizechannel(channel)].channel;
    if (!emitter) {
      await this.subscribe(normalizechannel(channel));
      emitter = this._subscriptions[normalizechannel(channel)].channel;
    }

    if (emitter) {
      return emitter.once(event, listener);
    }
  }

  protected async _subscribe(channel: string): Promise<() => void> {
    const centrifuge = this._connect();
    const _this = this;
    let sub = this._subscriptions[channel];

    if (!sub) {
      console.log(`subscribe to ${channel}`);
      this._subscriptions[channel] = {
        channel: centrifuge.subscribe(
          channel,
          // (data: any) => {
          //   console.log(data);
          // },
          {}
        ),
        subCount: 1,
      };
      sub = this._subscriptions[channel];
    } else {
      this._subscriptions[channel].subCount += 1;
    }

    const subscription = sub.channel;

    subscription?.on("join", (ctx) => {
      // console.log("join", ctx);
    });
    subscription?.on("leave", (ctx) => {
      // console.log("leave", ctx);
    });
    subscription?.on("error", (ctx) => {
      // console.log("error", ctx);
    });
    // v3.x below
    subscription?.on("publication", (ctx) => {
      // console.log("new pub", ctx);
    });
    subscription?.on("subscribed", (ctx) => {});
    subscription?.on("subscribing", (ctx) => {});
    subscription?.on("unsubscribed", (ctx) => {});
    subscription?.on("state", (ctx) => {
      // console.log("new state change", ctx);
    });
    // v 2.x below
    subscription?.on("subscribe", (ctx) => {
      // console.log("subscribed to", ctx);
    });
    subscription?.on("unsubscribe", (ctx) => {
      // console.log("unsubscribed from", ctx);
    });
    subscription?.on("publish", (ctx) => {
      // console.log(`new pub ${channel}`, ctx);
    });

    // subscription.publish({ 'data': 'hello' }).then((result) => {
    //   console.log(result);
    // });

    return () => {
      console.log(`unsubscribe ${channel}`);
      _this._unSubscribe(channel);
    };
  }

  protected async _unSubscribe(channel: string): Promise<void> {
    const sub = this._subscriptions[channel];
    if (sub) {
      sub.subCount -= 1;
    }
    if (sub.subCount === 0) {
      sub.channel.unsubscribe();
      sub.channel.removeAllListeners();
      delete this._subscriptions[channel];
    }
  }

  protected _reconnect() {
    const centrifuge = this._centrifuge;
    console.log("reconnect");
    centrifuge.connect();

    for (const key in this._subscriptions) {
      // eslint-disable-line
      const sub = this._subscriptions[key];
      this._unSubscribe(key);
      console.log(`re-sub ${key}`);
      this._subscribe(key);
    }
  }

  protected _connect() {
    const centrifuge = this._centrifuge;
    const _this = this;
    // v3.x
    centrifuge!.on("connected", (ctx) => {
      // console.log("centrifuge connected ", ctx);
    });
    centrifuge!.on("connecting", (ctx) => {
      // console.log("centrifuge connecting ", ctx);
    });
    centrifuge!.on("disconnected", (ctx) => {
      // console.log("centrifuge disconnected ", ctx);
    });
    // v2.x
    centrifuge!.on("connect", (ctx) => {
      // console.log("centrifuge connected ", ctx);
    });
    centrifuge!.on("disconnect", (ctx) => {
      // console.log("centrifuge disconnected ", ctx);
      _this._connected = false;
      if (ctx.reason === "shutdown" || ctx.reason === "stale") {
        //_this._reconnect();
      }
    });
    if (!this._connected) {
      // console.log("connect to centrifuge");
      centrifuge.connect();
      this._connected = true;
    }
    return centrifuge;
  }

  protected _openSocket() {
    this._connect();
  }

  protected _closeSocket() {
    this._centrifuge!.disconnect();
  }

  protected _doStart(): () => void {
    // console.log("start centrifugo connection");
    const _this = this;

    this._openSocket();
    return () => {
      // console.log("closing centrifuge");
      _this._closeSocket();
    };
  }
}
