import { atom } from "recoil";
import { UserState } from "src/types/state/userState";
import { truncateAddress } from "./../utils/common";

const userState = atom<UserState>({
  key: "userState", // unique ID (with respect to other atoms/selectors)
  default: {}, // default value (aka initial value)
  effects: [
    ({ setSelf, onSet }) => {
      onSet((newValue) => {
        //console.log(newValue);
        if (newValue.account) {
          setSelf({ ...newValue, displayAccount: truncateAddress(newValue.account) });
        } else {
          setSelf({ ...newValue, displayAccount: undefined });
        }
      });
    },
  ],
});

export default userState;
