import { ethers } from "ethers";

export const optionBlitzBackendDomain = "optionblitz.us-east-1.elasticbeanstalk.com";
export const baseApiUrl = `https://${optionBlitzBackendDomain}/landing-page/api`;
export const alchemyRpcEndpoint = {
  1: "https://eth.llamarpc.com",
  5: "https://eth-goerli.g.alchemy.com/v2/fIystCmnxn7Rb327epjN_lFSPyAWhTrU",
};
export const defaultChainId: number = 1;

export const provider = new ethers.providers.JsonRpcProvider(alchemyRpcEndpoint[1]);
