import React, { useEffect, useState } from "react";
import s from "./Timer.module.scss";

const Timer = ({ presaleTimer, saleTime }) => {
  const time = presaleTimer
    ? (saleTime - Date.now()) / 1000
    : (new Date("2023-02-28T10:00:00Z") - Date.now()) / 1000;
  let [timer, setTimer] = useState(time);
  useEffect(() => {
    if (timer > 0) {
      setTimeout(() => {
        setTimer(timer - 1);
      }, 1000);
    }
  }, [timer]);

  let days = Math.floor(timer / 86400);
  let hours = Math.floor((timer % 86400) / 3600);
  let minutes = Math.floor(((timer % 86400) % 3600) / 60);
  let seconds = Math.floor(((timer % 86400) % 3600) % 60);

  return (
    <div className={s.timer}>
      <div className={s.timer__block}>
        <strong>{days}</strong>
        <span>Days</span>
      </div>
      {presaleTimer ? <strong>/</strong> : <strong>:</strong>}
      <div className={s.timer__block}>
        <strong>{hours}</strong>
        <span>Hours</span>
      </div>
      <strong>:</strong>
      <div className={s.timer__block}>
        <strong>{minutes}</strong>
        <span>Minutes</span>
      </div>
      <strong>:</strong>
      <div className={s.timer__block}>
        <strong>{seconds}</strong>
        <span>Sec</span>
      </div>
    </div>
  );
};
export default Timer;
