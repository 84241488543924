import React, { useState, useEffect } from "react";
import s from "./Market.module.scss";
import { Fade } from "react-reveal";
import {
  Area,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  AreaChart,
  Label,
  ReferenceDot,
} from "recharts";
import TableBlock from "src/components/Markets/TableBlock/TableBlock";
import chevronRight from "src/assets/images/chevron_right__icon__green.svg";
import Stepper from "../Stepper/Stepper";
import { Tooltip as LoginTooltip } from "src/components/Popup/Popup";
import { useCharts } from "src/hooks/useCharts";
import { GRAPH_INTERVAL } from "src/constants";
import useUser from "src/hooks/useUser";

const MarketStat = ({ title, price, currency }) => {
  return (
    <div className={s.info__stats_item}>
      <strong>{title}</strong>
      <div>
        <strong>{price}</strong>
        <span>{currency}</span>
      </div>
    </div>
  );
};

// const CustomTooltip = ({ active, payload, label }) => {
//   if (active && payload && payload.length) {
//     return <div className={s.tooltip}>current price: {payload[0].value}</div>;
//   }

//   return null;
// };

const Market = () => {
  const { chartData, changeHandler, amountBLX, amountUSDC, tokenPrice, tokenSold, purchaseBlx, dataLength, isLoading } =
    useCharts();
  const { user } = useUser();
  const [showTooltip, setShowTooltip] = useState(false);
  function formatTick(tick) {
    return Math.floor(tick / 1000000).toString() + "M";
  }

  const xPoint = React.useMemo(
    // if tokensold is greater than 0 but less than our interval, showing reference line on first data point.
    () =>
      tokenSold > 0 && tokenSold >= GRAPH_INTERVAL
        ? Math.round(tokenSold / GRAPH_INTERVAL) * GRAPH_INTERVAL
        : GRAPH_INTERVAL,
    [tokenSold]
  );

  return (
    <div className={s.market}>
      <Fade bottom>
        <div className={s.info}>
          <div className={s.info__stats}>
            <MarketStat title={"Buy price"} price={tokenPrice.toFixed(4)} currency={"USDC"} />
            <MarketStat title={"BLX Sold"} price={tokenSold.toLocaleString()} currency={"BLX"} />
            <MarketStat title={"PRESALE Allocated"} price={"10,000,000"} currency={"BLX"} />
            <MarketStat title={"IBCO ALLOCATED"} price={"30,000,000"} currency={"BLX"} />
          </div>
        </div>
      </Fade>

      <div className={s.info__wrapper}>
        <div className={s.info__chart}>
          <ResponsiveContainer width="100%" height="100%" debounce={100}>
            <AreaChart
              data={chartData}
              margin={{
                top: 16,
                right: 16,
                left: 16,
                bottom: 16,
              }}
            >
              <XAxis dataKey="totalSold" stroke={"#59648A"} interval={10000000 / 20000} tickFormatter={formatTick}>
                <Label value={"BLX Tokens"} position={"insideRight"} />
              </XAxis>
              <YAxis stroke={"#59648A"} tickCount={6} ticks={[0, 0.1, 0.2, 0.3, 0.4, 0.5]}>
                <Label value={"USDC Price"} position={"insideTop"} />
              </YAxis>
              {tokenSold && (
                <>
                  <ReferenceLine
                    x={xPoint}
                    label={{ value: `Current price: ${tokenPrice}`, position: "top", style: { fill: "white" } }}
                    stroke="rgba(255, 255, 255, 0.2)"
                    strokeDasharray="4 4"
                    strokeWidth={2}
                    color="#fff"
                  />
                  <ReferenceDot x={xPoint} y={tokenPrice} r={5} fill="#6140EF" />
                </>
              )}
              <Area
                type="monotone"
                dataKey="price"
                stroke="#6140EF"
                fill="url(#colorUv)"
                activeDot={{ fill: "#6140EF", strokeWidth: 0, r: 5 }}
              />
              {/* <CartesianGrid strokeWidth={1} stroke={"rgba(210, 210, 210, 0.06)"} /> */}
              {/* <Tooltip
                active={true}
                content={<CustomTooltip />}
                cursor={{ stroke: "rgba(255, 255, 255, 0.2)", strokeWidth: 1, strokeDasharray: "4 4" }}
              /> */}
              <defs>
                <linearGradient
                  id="colorUv"
                  x1="200.027"
                  y1="297.347"
                  x2="200.027"
                  y2="452.028"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#5865F2" stop-opacity="0.53" />
                  <stop offset="1" stop-color="#5865F2" stop-opacity="0.01" />
                </linearGradient>
              </defs>
            </AreaChart>
          </ResponsiveContainer>
        </div>
        <div className={s.buy}>
          <div className={s.buy__block}>
            <h3>Buy</h3>
            <Stepper
              changeHandler={changeHandler}
              amountUSDC={amountUSDC}
              amountBLX={amountBLX}
              tokenPrice={tokenPrice}
              purchaseBlx={purchaseBlx}
            />
          </div>
          {!user.account && (
            <>
              <p
                className="message"
                onMouseEnter={() => setShowTooltip(true)}
                onMouseLeave={() => setShowTooltip(false)}
              >
                Please login to access features
              </p>
              <LoginTooltip show={showTooltip} className={"tooltip"} />
            </>
          )}
        </div>
      </div>
      <Fade bottom>
        <div className={s.activity}>
          <h3>Activity</h3>
          <TableBlock activity={true} />
        </div>
      </Fade>
    </div>
  );
};

export default Market;
