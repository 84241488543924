import adaIcon from "../assets/images/cardano_logo.svg";
import audIcon from "../assets/images/aud_icon.svg";
import avaxIcon from "../assets/images/avax_icon.svg";
import bnbIcon from "../assets/images/bnb_icon.svg";
import btcIcon from "../assets/images/btc.svg";
import cadIcon from "../assets/images/cad_icon.svg";
import chfIcon from "../assets/images/chf_icon.svg";
import cnhIcon from "../assets/images/cnh_icon.svg";
import dogeIcon from "../assets/images/doge_icon.svg";
import dotIcon from "../assets/images/dot_icon.svg";
import ethIcon from "../assets/images/ethereum.svg";
import euroIcon from "../assets/images/euro_icon.png";
import gbpIcon from "../assets/images/gbp_icon.svg";
import jpyIcon from "../assets/images/jpy_icon.svg";
import linkIcon from "../assets/images/link_icon.svg";
import ltcIcon from "../assets/images/litecoin_logo.svg";
import maticIcon from "../assets/images/matic_icon.svg";
import solIcon from "../assets/images/sol_icon.svg";
import usdIcon from "../assets/images/usd_icon.svg";
import xagIcon from "../assets/images/xag_icon.svg";
import xauIcon from "../assets/images/xau_icon.svg";
import xpdIcon from "../assets/images/xpd_icon.svg";
import xptIcon from "../assets/images/xpt_icon.svg";
import xrpIcon from "../assets/images/xrp_logo.svg";
import brlIcon from "../assets/images/brl_icon.svg";
import hkdIcon from "../assets/images/hkd_icon.svg";
import mxnIcon from "../assets/images/mxn_icon.svg";
import nokIcon from "../assets/images/nok_icon.svg";
import sekIcon from "../assets/images/sek_icon.svg";
import sgdIcon from "../assets/images/sgd_icon.svg";
import zarIcon from "../assets/images/zar_icon.svg";
import ibmIcon from "../assets/images/ibm_icon.svg";
import aaplIcon from "../assets/images/aapl_icon.svg";
import amznIcon from "../assets/images/amzn_icon.svg";
import googIcon from "../assets/images/goog_icon.svg";
import intcIcon from "../assets/images/intc_icon.svg";
import metaIcon from "../assets/images/meta_icon.svg";
import nvdaIcon from "../assets/images/nvda_icon.svg";
import msftIcon from "../assets/images/msft_icon.svg";
import tslaIcon from "../assets/images/tsla_icon.svg";
import shibIcon from "../assets/images/shib_icon.svg";
import pepeIcon from "../assets/images/pepe_icon.svg";
import { optionBlitzBackendDomain } from "src/config";

export const WSS_ENDPOINTS = {
  crypto: `wss://${optionBlitzBackendDomain}/crypto/d74687c3e0a1ad96674f7e53b9a81a7dd6e87b35`,
  // this shows trade(most likely forex equivalent as the volume seems to be very low)
  // forex: "wss://optionblitz-dev.us-east-1.elasticbeanstalk.com/crypto/d74687c3e0a1ad96674f7e53b9a81a7dd6e87b35",
  // this shows bid/ask
  forex: `wss://${optionBlitzBackendDomain}/forex/d74687c3e0a1ad96674f7e53b9a81a7dd6e87b35`,
};

// Default object depicting return of price response from websocket.
const DEFAULT_PRICE_RESPONSE = {
  dc: "0",
  dd: "0",
  p: "0",
  q: "0",
  s: "",
  t: 0,
};

const DEFAULT_FEATURED_SIGNALS = {
  p: 0,
  type: ["Moving Averages", "Indicators", "Summary"],
  fiveMin: ["Neutral", "Neutral", "Neutral"],
  fifteenMin: ["Neutral", "Neutral", "Neutral"],
  hourly: ["Neutral", "Neutral", "Neutral"],
  daily: ["Neutral", "Neutral", "Neutral"],
};

// Mapping of symbols to icons.
export const ICON_MAP = {
  ADA: adaIcon,
  AUD: audIcon,
  AVAX: avaxIcon,
  BNB: bnbIcon,
  BTC: btcIcon,
  CAD: cadIcon,
  CHF: chfIcon,
  CNH: cnhIcon,
  DOGE: dogeIcon,
  DOT: dotIcon,
  ETH: ethIcon,
  EUR: euroIcon,
  GBP: gbpIcon,
  JPY: jpyIcon,
  LINK: linkIcon,
  LTC: ltcIcon,
  MATIC: maticIcon,
  NZD: audIcon,
  SOL: solIcon,
  USD: usdIcon,
  XAG: xagIcon,
  XAU: xauIcon,
  XPD: xpdIcon,
  XPT: xptIcon,
  XRP: xrpIcon,
  BRL: brlIcon,
  HKD: hkdIcon,
  MXN: mxnIcon,
  NOK: nokIcon,
  SEK: sekIcon,
  SGD: sgdIcon,
  ZAR: zarIcon,
  IBM: ibmIcon,
  AAPL: aaplIcon,
  AMZN: amznIcon,
  GOOG: googIcon,
  INTC: intcIcon,
  META: metaIcon,
  NVDA: nvdaIcon,
  MSFT: msftIcon,
  TSLA: tslaIcon,
  PEPE: pepeIcon,
  SHIB: shibIcon,
};

export const FOREX_PAIRS = [
  "AUD/USD",
  "EUR/USD",
  "GBP/USD",
  "NZD/USD",
  "USD/CAD",
  "USD/CHF",
  "USD/CNH",
  "USD/JPY",
  "USD/BRL",
  "USD/HKD",
  "USD/MXN",
  "USD/NOK",
  "USD/SEK",
  "USD/SGD",
  "USD/ZAR",
];

export const CRYPTO_PAIRS = [
  "BTC/USD",
  "ETH/USD",
  "XRP/USD",
  "BNB/USD",
  "DOGE/USD",
  "LTC/USD",
  "SOL/USD",
  "AVAX/USD",
  "LINK/USD",
  "ADA/USD",
  "MATIC/USD",
  "DOT/USD",

  "SHIB/USD",
  "PEPE/USD",
];

export const EQUITY_PAIRS = [
  "AAPL/USD",
  "AMZN/USD",
  "GOOG/USD",
  "IBM/USD",
  "INTC/USD",
  "META/USD",
  "NVDA/USD",
  "MSFT/USD",
  "TSLA/USD",
];

export const METAL_PAIRS = ["XAU/USD", "XAG/USD"];
export const ENERGY_PAIRS = [];
export const FEATURED_SIGNALS_PAIRS = ["BTC/USD", "ETH/USD", "EUR/USD", "GBP/USD", "XAU/USD"];

export const INTERVAL_RESOLUTION = "60";

function getDefaultData(pairs, twoIcons = false) {
  let defaultData = {};

  for (let pair of pairs) {
    defaultData[pair] = {
      ...DEFAULT_PRICE_RESPONSE,
      s: pair,
      // Adding two icons, if dealing with forex.
      icon: twoIcons ? ICON_MAP[pair.split("/")[0]] : ICON_MAP[pair.split("/")[0]],
      ...(twoIcons && { icon1: ICON_MAP[pair.split("/")[1]] }),
      graphData: [],
    };
  }
  return defaultData;
}

function getDefaultFeaturedSignalData(pairs) {
  let defaultData = {};

  for (let pair of pairs) {
    const twoIcons = FOREX_PAIRS.includes(pair);
    defaultData[pair] = {
      ...DEFAULT_FEATURED_SIGNALS,
      s: pair,
      // Adding two icons, if dealing with forex.
      icon: twoIcons ? ICON_MAP[pair.split("/")[0]] : ICON_MAP[pair.split("/")[0]],
      ...(twoIcons && { icon1: ICON_MAP[pair.split("/")[1]] }),
      graphData: [],
    };
  }
  return defaultData;
}

export const DEFAULT_FOREX_DATA = getDefaultData(FOREX_PAIRS, true);
export const DEFAULT_CRYPTO_DATA = getDefaultData(CRYPTO_PAIRS);
export const DEFAULT_METALS_DATA = getDefaultData(METAL_PAIRS);
export const DEFAULT_EQUITY_DATA = getDefaultData(EQUITY_PAIRS);
export const DEFAULT_ENERGIES_DATA = getDefaultData(ENERGY_PAIRS);
export const DEFAULT_FEATURED_DATA = getDefaultFeaturedSignalData(FEATURED_SIGNALS_PAIRS);
