import React from "react";
import Logo from "../../assets/images/logo.svg";
import s from "./PageLoader.module.scss";
const PageLoader = ({ loading }) => {
  return (
    <div className={[s.page_loader_wrapper, !loading && s.animation].join(" ")}>
      <img src={Logo} alt="logo" />

      <div className={s.loader}>
        <span className={s.loader__circle}></span>
        <span className={s.loader__circle}></span>
        <span className={s.loader__circle}></span>
      </div>
    </div>
  );
};
export default PageLoader;
