import { useCallback, useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import contractState from "src/state/contractAtom";
import { _connectToContracts, _OptionBlitzDeploymentJSON, _OptionBlitzContracts } from "../contracts/contracts";
import devOrNull from "../contracts/deployments/default/private.json";

export interface ContractState {
  deployment?: _OptionBlitzDeploymentJSON;
  contracts?: _OptionBlitzContracts;
}

const useContract = () => {
  //const [contracts, setContracts] = useState<ContractState>();
  const [contracts, setContracts] = useRecoilState(contractState);
  // useEffect(() => {
  //   const x = getNetProvider();
  //   const contracts = _connectToContracts(x, devOrNull as _OptionBlitzDeploymentJSON);
  //   console.log('connect contracts');
  //   setContracts({ deployment: devOrNull, contracts});
  //   setTimeout(() => {
  //       contracts.BlxPresale.amountFromWhitelisted()
  //           .then(v => {
  //               console.log(v.toString());
  //           })
  //           .catch(err => {
  //               console.log(err);
  //           })
  //   }, 0);
  // },[]);
  return { contracts };
};

export default useContract;
