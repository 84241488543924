import s from "./Sorting.module.scss";
import Arrow from "../../../../assets/images/arrow_white.svg";
import { useState } from "react";

const Sorting = ({ setId }) => {
  const [sortingItems, setSortingItems] = useState([
    { id: 1, text: "Biggest risers", isActive: true },
    { id: 2, text: "Biggest fallers" },
    { id: 3, text: "Most Traded" },
  ]);
  const [isActive, setIsActive] = useState(false);

  const toggleItems = () => {
    setIsActive(!isActive);
  };

  const setItem = (id) => {
    let newSortingItems = sortingItems.map((s) => {
      if (s.id === id) {
        return { ...s, isActive: true };
      }
      return { ...s, isActive: false };
    });
    setSortingItems(newSortingItems);
  };

  const handleClick = (id) => {
    toggleItems();
    setItem(id);
    setId(id);
  };

  return (
    <div className={s.sorting}>
      <div
        className={[s.sorting__selected, isActive && s.sorting__selected_active].join(" ")}
        onClick={() => toggleItems()}
      >
        <strong>{sortingItems.filter((s) => s.isActive)[0].text}</strong>
        <img src={Arrow} alt="" />
      </div>
      {isActive && (
        <div className={s.sorting__items}>
          {sortingItems.map((s) => (
            <strong onClick={() => handleClick(s.id)} key={s.id}>
              {s.text}
            </strong>
          ))}
        </div>
      )}
    </div>
  );
};

export default Sorting;
