import React from 'react';
import s from "./InfoBlock.module.scss";

const InfoBlock = ({children, className}) => {
    return (
        <div className={[s.info_block, className].join(' ')}>
            {children}
        </div>
    );
};

export default InfoBlock;