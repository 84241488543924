import React from "react";
import s from "./Timeline.module.scss";
import leftArrow from "../../../assets/images/chevron_left__icon.svg";

const timelineData = [
  {
    date: "Jan 2023",
    isActive: true,
    isLastActive: true,
    tasks: ["Launch community networks", "Begin Optionblitz trading protocol testnet v1"],
  },
  {
    date: "Feb 2023",
    isActive: false,
    isLastActive: false,
    tasks: ["Start Presale"],
  },
  {
    date: "Mar 2023",
    isActive: false,
    isLastActive: false,
    tasks: ["End Presale", "Update to testnet v2"],
  },
  {
    date: "May 2023",
    isActive: false,
    isLastActive: false,
    tasks: ["Start Public Sale (IBCO)", "End Public Sale"],
  },
  {
    date: "Jun 2023",
    isActive: false,
    isLastActive: false,
    tasks: ["Launch Optionblitz v2 on Arbitrum mainnet."],
  },
];
const TimelineItem = ({ tasks, date, isActive, isLastActive }) => {
  return (
    <div
      className={[
        s.timeline__item,
        s.timeline1__item,
        isActive && s.timeline__item_active,
        isLastActive && s.timeline__item_last,
      ].join(" ")}
    >
      <span className={s.timeline__item_date}>{date}</span>
      {tasks.map((t, index) => (
        <div key={index} className={s.timeline__item_title}>
          {t}
        </div>
      ))}
    </div>
  );
};

export const Timeline1 = () => {
  return (
    <div className={[s.timeline, s.timeline1].join(" ")}>
      <TimelineItem
        tasks={timelineData[0].tasks}
        date={timelineData[0].date}
        isActive={timelineData[0].isActive}
        isLastActive={timelineData[0].isLastActive}
      />
      <TimelineItem
        tasks={timelineData[1].tasks}
        date={timelineData[1].date}
        isActive={timelineData[1].isActive}
        isLastActive={timelineData[1].isLastActive}
      />
      <TimelineItem
        tasks={timelineData[2].tasks}
        date={timelineData[2].date}
        isActive={timelineData[2].isActive}
        isLastActive={timelineData[2].isLastActive}
      />
      <div className={s.timeline1__button}>
        <img src={leftArrow} alt="prev-icon" />
      </div>
      <div className={`${s.timeline1__button} ${"btn_next"}`}>
        <img src={leftArrow} alt="next-icon" />
      </div>
      <TimelineItem
        tasks={timelineData[3].tasks}
        date={timelineData[3].date}
        isActive={timelineData[3].isActive}
        isLastActive={timelineData[3].isLastActive}
      />
    </div>
  );
};

export const Timeline2 = () => {
  return (
    <div className={s.timeline}>
      <TimelineItem
        tasks={timelineData[0].tasks}
        date={timelineData[0].date}
        isActive={timelineData[0].isActive}
        isLastActive={timelineData[0].isLastActive}
      />
      <TimelineItem
        tasks={timelineData[1].tasks}
        date={timelineData[1].date}
        isActive={timelineData[1].isActive}
        isLastActive={timelineData[1].isLastActive}
      />
      <TimelineItem
        tasks={timelineData[2].tasks}
        date={timelineData[2].date}
        isActive={timelineData[2].isActive}
        isLastActive={timelineData[2].isLastActive}
      />
      <div className={s.timeline1__button}>
        <img src={leftArrow} alt="prev-icon" />
      </div>
      <div className={`${s.timeline1__button} ${"btn_next"}`}>
        <img src={leftArrow} alt="next-icon" />
      </div>
      <TimelineItem
        tasks={timelineData[3].tasks}
        date={timelineData[3].date}
        isActive={timelineData[3].isActive}
        isLastActive={timelineData[3].isLastActive}
      />
    </div>
  );
};

export const Timeline3 = () => {
  return (
    <div className={s.timeline}>
      <TimelineItem
        tasks={timelineData[0].tasks}
        date={timelineData[0].date}
        isActive={timelineData[0].isActive}
        isLastActive={timelineData[0].isLastActive}
      />
      <TimelineItem
        tasks={timelineData[1].tasks}
        date={timelineData[1].date}
        isActive={timelineData[1].isActive}
        isLastActive={timelineData[1].isLastActive}
      />
      <TimelineItem
        tasks={timelineData[2].tasks}
        date={timelineData[2].date}
        isActive={timelineData[2].isActive}
        isLastActive={timelineData[2].isLastActive}
      />
      <div className={s.timeline1__button}>
        <img src={leftArrow} alt="prev-icon" />
      </div>
      <div className={`${s.timeline1__button} ${"btn_next"}`}>
        <img src={leftArrow} alt="next-icon" />
      </div>
      <TimelineItem
        tasks={timelineData[3].tasks}
        date={timelineData[3].date}
        isActive={timelineData[3].isActive}
        isLastActive={timelineData[3].isLastActive}
      />
    </div>
  );
};

export const Timeline4 = () => {
  return (
    <div className={s.timeline}>
      <TimelineItem
        tasks={timelineData[0].tasks}
        date={timelineData[0].date}
        isActive={timelineData[0].isActive}
        isLastActive={timelineData[0].isLastActive}
      />
      <TimelineItem
        tasks={timelineData[1].tasks}
        date={timelineData[1].date}
        isActive={timelineData[1].isActive}
        isLastActive={timelineData[1].isLastActive}
      />
      <TimelineItem
        tasks={timelineData[2].tasks}
        date={timelineData[2].date}
        isActive={timelineData[2].isActive}
        isLastActive={timelineData[2].isLastActive}
      />
      <div className={s.timeline1__button}>
        <img src={leftArrow} alt="prev-icon" />
      </div>
      <div className={`${s.timeline1__button} ${"btn_next"}`}>
        <img src={leftArrow} alt="next-icon" />
      </div>
      <TimelineItem
        tasks={timelineData[3].tasks}
        date={timelineData[3].date}
        isActive={timelineData[3].isActive}
        isLastActive={timelineData[3].isLastActive}
      />
    </div>
  );
};
