import React, { useState } from "react";
import Fade from "react-reveal";
import s from "./TokenSaleStages.module.scss";
import { useTranslation } from "react-i18next";
const TokenSaleStages = ({ stages, data, stagesName }) => {
  const [activeStage, setActiveStage] = useState(0);

  return (
    <TokenSaleInfo>
      <div className={s.stages__stages_info__header}>
        {stages.map((i, index) => (
          <h3 key={index} className={`${activeStage === index ? "active" : ""}`} onClick={() => setActiveStage(index)}>
            {i}
          </h3>
        ))}
      </div>

      <div className={s.stages__stages_info__body}>
        <h2>{stagesName[activeStage]}</h2>
        <div className={s.stages__stages_info__body__info}>
          {data[activeStage].map((i, index) => {
            return (
              <div key={index} className={s.stages__stages_info__body__info__info_block}>
                <p className={s.stages__stages_info__body__info__info_block__title}>{i.title}</p>
                <p className={s.stages__stages_info__body__info__info_block__value}>{i.value}</p>
              </div>
            );
          })}
        </div>
      </div>
    </TokenSaleInfo>
  );
};

export default TokenSaleStages;

export const TokenSaleStages1 = ({ stages, activeStage, setActiveStage, presale = false }) => {
  return (
    <div
      className={[s.stages__stages_info__header, presale ? s.presale : "", activeStage > 0 ? "stage_heading" : ""].join(
        " "
      )}
    >
      {stages.map((i, index) => (
        <h3 key={index} className={`${activeStage === index ? "active" : ""}`} onClick={() => setActiveStage(index)}>
          {i}
        </h3>
      ))}
    </div>
  );
};

export const TokenSaleInfo = ({ children, style, presale }) => {
  const { t } = useTranslation();
  return (
    <Fade bottom>
      <div className={s.stages} style={style}>
        <div className={[s.stages__stages_info, presale ? s.presale__info : ""].join(" ")}>
          <h2>{t("TokenSale.heading")}</h2>
          <p>1. {t("TokenSale.1")}</p>
          <p>2. {t("TokenSale.2")}</p>
          {children}
        </div>
      </div>
    </Fade>
  );
};
