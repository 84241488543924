import { BigNumberish, ethers, BigNumber } from "ethers";
export { BigNumber };

export const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export const getBase64 = (file: File) =>
  new Promise<string>((resolve, reject) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      resolve(reader.result as string);
    };
    reader.onerror = function (error) {
      //console.log("Error: ", error);
      reject(error);
    };
  });

const handleFormInput = (data: any, key: string, formData: FormData) => {
  if (Array.isArray(data)) {
    data.forEach((item, index) => {
      handleFormInput(item, `${key}[${index}]`, formData);
    });
  } else if (data instanceof File || data instanceof Blob) {
    formData.append(key, data);
  } else if (typeof data === "object") {
    for (const [k, v] of Object.entries(data)) {
      handleFormInput(v, `${key}[${k}]`, formData);
    }
  } else {
    formData.append(key, data);
  }
};

export const shapeFormData = (data: any) => {
  let formData = new FormData();
  for (const [key, value] of Object.entries(data)) {
    handleFormInput(value, key, formData);
  }
  return formData;
};

export const truncateAddress = (address?: string) => {
  if (!address) return "No Account";
  const match = address.match(/^(0x[a-zA-Z0-9]{5})[a-zA-Z0-9]+([a-zA-Z0-9]{3})$/);
  if (!match) return address;
  return `${match[1]}…${match[2]}`;
};

export const toEth = (wei: BigNumberish) => {
  return ethers.utils.formatEther(wei);
};

export const toWei = (eth: string | number) => {
  return ethers.utils.parseEther(eth.toString());
};

export const toHex = (data: string | number) => {
  const val = Number(data);

  return "0x" + val.toString(16);
};

export const awaitTransaction = async (transaction: any) => {
  let tx;
  let receipt;
  let error;
  let status;
  try {
    tx = await transaction;
    receipt = await tx.wait();
    status = true;
  } catch (e: any) {
    if (e.reason) error = e.reason.replace("execution reverted:", "");
    else if (e.code === 4001) error = "Transaction Denied!";
    else if (e.code === -32000) error = "Insuficient Funds in your account for transaction";
    else if (e.data?.code === -32000) error = "Insuficient Funds in your account for transaction";
    else if (e.data?.message) error = e.data.message;
    else if (e.message) error = e.message;
    status = false;
  }
  return {
    tx,
    receipt,
    error,
    status,
  };
};

// util function to calculate change percentage.
export const getChange = (currPrice: string | number, pastPrice: string | number) => {
  return ((Number(currPrice) - Number(pastPrice)) / Number(pastPrice)) * 100;
};

export const getReferrer = () => {
  const refId = window.localStorage.getItem("refId");
  if (refId) {
    return refId;
  } else {
    return "";
  }
};


export const getSignalName = (signal: string) => {
  switch (signal) {
    case "Hold":
      return "Neutral";    
    default:
      return signal;
  }
}