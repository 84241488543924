import { EvmPriceServiceConnection, PriceFeed } from "@pythnetwork/pyth-evm-js";
import { EventEmitter } from "events";
import { symbols } from "./OptionBlitzProvider";

const PYTH_PRICE_SERVICE_URL = "https://pyth.rinalds.dev/";
const IS_PYTH_TESTNET = false;

interface OptionBlitzPriceSubscription {
  event: EventEmitter;
  subCount: number;
}

/* this is for internal use */
function normalizeSymbol(symbol: string) {
  const normalized = symbol?.replace(/[/_]/g, "");
  return normalized;
}

export class OptionBlitzPricefeed {
  private _subscriptions: Record<string, OptionBlitzPriceSubscription> = {};

  protected pythEvm!: EvmPriceServiceConnection;

  private lastPrice: Record<string, number> = {};

  private _24hPrice: Record<string, number> = {};

  public start(): () => void {
    const doStop = this._doStart();

    return () => {
      doStop();
    };
  }

  public async subscribe(symbols: string[]) {
    this.start();
    // console.log('subscribe to ', category, symbols);
    return this._subscribe(symbols);
  }

  public async on(symbol: string, listener: (...args: any[]) => void) {
    const emitter = this._subscriptions[normalizeSymbol(symbol)];
    // console.log('emitter', emitter);
    if (emitter) {
      emitter.event.on(normalizeSymbol(symbol), listener);
    }
  }

  public async off(symbol: string, listener: (...args: any[]) => void) {
    const emitter = this._subscriptions[normalizeSymbol(symbol)];
    if (emitter) {
      emitter.event.off(normalizeSymbol(symbol), listener);
    }
  }

  public async once(symbol: string, listener: (...args: any[]) => void) {
    const emitter = this._subscriptions[normalizeSymbol(symbol)];
    if (emitter) {
      emitter.event.once(normalizeSymbol(symbol), listener);
    }
  }

  public getAllPrices() {
    return JSON.parse(JSON.stringify(this.lastPrice));
  }

  public updateBackupPrice(symbol: string, price: number) {
    const sym = normalizeSymbol(symbol);

    if (!this.lastPrice[sym]) {
      this.lastPrice[sym] = price;
    }
  }

  public update24HPrice(symbol: string, price: number) {
    const sym = normalizeSymbol(symbol);

    this._24hPrice[sym] = price;
  }

  public async get24HPrice(symbol: string) {
    const sym = normalizeSymbol(symbol);

    return new Promise((resolve, reject) => {
      const intv = setInterval(() => {
        if (this._24hPrice[sym]) {
          clearInterval(intv);
          resolve(this._24hPrice[sym]);
        }
      }, 50);
    });
  }

  public async get24HPctChange(symbol: string) {
    const curr = Number(await this.getLastPrice(symbol));
    const prev = Number(await this.get24HPrice(symbol));

    const change = Math.round(((curr - prev) / (prev || 1)) * 100 * 100) / 100;

    return change;
  }

  public async getLastPrice(symbol: string): Promise<number> {
    const sym = normalizeSymbol(symbol);

    return new Promise((resolve, reject) => {
      const intv = setInterval(() => {
        if (this.lastPrice[sym]) {
          clearInterval(intv);
          resolve(this.lastPrice[sym]);
        }
      }, 50);
    });
  }

  protected async _subscribe(symbols: string[]): Promise<() => void> {
    this._addSubscriptions(symbols);

    return async () => {
      // this._unSubscribe(symbols);
    };
  }

  // protected async _unSubscribe(
  //   symbols: string[]
  // ): Promise<void> {

  // }

  protected _addSubscriptions(symbols: string[]) {
    symbols.forEach((s) => {
      if (!this._subscriptions[normalizeSymbol(s)]) {
        this._subscriptions[normalizeSymbol(s)] = {
          event: new EventEmitter(),
          subCount: 0,
        };
      }
      this._subscriptions[normalizeSymbol(s)].subCount += 1;
    });
  }

  protected _removeSubscriptions(symbols: string[]) {
    symbols.forEach((s) => {
      const sub = this._subscriptions[normalizeSymbol(s)];
      if (sub && sub.subCount === 1) {
        sub.event.removeAllListeners();
        delete this._subscriptions[normalizeSymbol(s)];
      }
    });
  }

  protected _doStart(): () => void {
    const idField = IS_PYTH_TESTNET ? "pythTestNetId" : "pythId";
    const ids = Object.values(symbols).map((o) => o[idField]);

    if (!this.pythEvm) {
      this.pythEvm = new EvmPriceServiceConnection(PYTH_PRICE_SERVICE_URL);

      const pythMap = Object.entries(symbols).reduce((all, [ticker, conf]) => {
        all[conf[idField]] = ticker;
        return all;
      }, {} as any);

      const updatePrice = (priceFeed: PriceFeed) => {
        // eslint-disable-next-line
        const symbol = pythMap["0x" + priceFeed.id];
        const price = priceFeed.getPriceNoOlderThan(60)?.getPriceAsNumberUnchecked();

        if (!price) {
          return;
        }

        const ns = normalizeSymbol(symbol);
        const eventEmitter = this._subscriptions[ns];
        eventEmitter?.event.emit(ns, {
          symbol: ns,
          price,
          quantity: 0,
          timestamp: Date.now(),
        });

        this.lastPrice[ns] = price as number;

        const eventEmitterAll = this._subscriptions.all;
        eventEmitterAll?.event.emit("all", {
          symbol: ns,
          price,
          quantity: 0,
          timestamp: Date.now(),
        });

        // console.log(
        //   'Received update for', symbol, price
        // );
      };

      this.pythEvm.getLatestPriceFeeds(ids).then((latest) => {
        latest?.forEach((priceFeed) => {
          updatePrice(priceFeed);
        });
      });

      this.pythEvm.subscribePriceFeedUpdates(ids, (priceFeed) => {
        updatePrice(priceFeed);
      });
    }

    return () => {
      this.pythEvm.unsubscribePriceFeedUpdates(ids);
      console.log("unmount pricefeed stream");
    };
  }

  public getPythConnection() {
    return this.pythEvm;
  }
}

export const priceFeed = new OptionBlitzPricefeed();
