import React from "react";
import s from "./Staking.module.scss";
import Usdc from "./../../assets/images/usdc.svg";
import Blx from "./../../assets/images/icon-blx.svg";
import NoLock from "./../../assets/images/no-lock.svg";
import MaxLock from "./../../assets/images/max-lock.svg";
import MaxLockAlt from "./../../assets/images/max-lock_alt.svg";
import Stars from "./../../assets/images/stars.svg";
import Flash from "./../../assets/images/flash_1.svg";
import Rocket from "./../../assets/images/rocket.svg";
import Moon from "./../../assets/images/moon.svg";
import Button from "../UI/Button/Button";
import Fade from "react-reveal/Fade";
import { useTranslation } from "react-i18next";

const Apy = ({ icons, titles, level, icon, percentage }) => {
  const { t } = useTranslation();
  return (
    <div className={`${s.apy} level-${level}`}>
      <p>
        {t("Staking.Level")} {level}
      </p>
      <div className={s.apy__icons}>
        {icons.map((i, index) => {
          if (index + 1 < icons.length) {
            return (
              <React.Fragment key={index}>
                <div className={s.apy__icons_item} key={index}>
                  <img src={i} alt="" />
                </div>
                <span>+</span>
              </React.Fragment>
            );
          } else {
            return (
              <React.Fragment key={index}>
                <div className={s.apy__icons_item} key={index}>
                  <img src={i} alt="" />
                </div>
              </React.Fragment>
            );
          }
        })}
      </div>
      <strong className={s.apy__title}>{titles.join(" + ")}</strong>
      <div className={s.apy__stats}>
        <div>
          <img src={icon} alt="" />
          <strong>{percentage}%</strong>
        </div>
      </div>
    </div>
  );
};

const Staking = () => {
  const { t } = useTranslation();
  return (
    <section className={s.staking} id="staking">
      <div className="container">
        <h2>{t("Staking.heading")}</h2>
        <p>
          {t("Staking.sub heading")}: <span>- USDC</span>
        </p>

        <Fade bottom>
          <div className={s.staking__apy}>
            <div className={s.staking__apy_items}>
              <Apy icons={[Usdc, NoLock]} titles={["USDC", "NO LOCK"]} level={1} percentage={"-"} icon={Stars} />
              <Apy
                icons={[Usdc, Blx, NoLock]}
                titles={["USDC", "BLX", "NO LOCK"]}
                level={2}
                percentage={"-"}
                icon={Flash}
              />
              <Apy
                icons={[Usdc, MaxLock]}
                titles={["USDC", "MAX LOCK DURATION"]}
                level={3}
                percentage={"-"}
                icon={Rocket}
              />
              <Apy
                icons={[Usdc, Blx, MaxLockAlt]}
                titles={["USDC", "BLX", "MAX LOCK DURATION"]}
                level={4}
                percentage={"-"}
                icon={Moon}
              />
            </div>
          </div>
        </Fade>
        <Fade bottom>
          <div>
            <Button disabled={true}>Start staking</Button>
          </div>
        </Fade>
      </div>
    </section>
  );
};

export default Staking;
