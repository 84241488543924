/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState } from "react";
import s from "./ScheduleACall.module.scss";
import InfoBlock from "../InfoBlock/InfoBlock";
import Button from "../UI/Button/Button";
import Calendar from "./../../assets/images/calendar.svg";
import Image from "./../../assets/images/schedule__image_new.png";
import Image_2x from "./../../assets/images/schedule__image_new_2x.png";
import Fade from "react-reveal";
import { TokenSaleInfo } from "../TokenSaleStages/TokenSaleStages";
import { Slider } from "../TokenSale/TokenSale";
import { stage1Items, stage2Items, items } from "src/utils/data";
const ScheduleACall = () => {
  const [isAdaptive, setIsAdaptive] = useState(window.screen.width <= 786);
  return (
    <section className={s.schedule}>
      <div className="container" style={{ display: isAdaptive ? "flex" : "block" }}>
        <TokenSaleInfo style={{ background: "none", height: "fit-content" }} presale={true} />
        <Slider stages={["Basics", "Stage 1", "Stage 2"]} data={[items, stage1Items, stage2Items]} presale={true} />
      </div>
      <div>
        <div className={s.schedule__fade}></div>
        <div className="container">
          <Fade left>
            <InfoBlock className={s.schedule__info}>
              <h2>Schedule a call with the founders</h2>
              <p>
                Book a call with the founders, select a member of the team which manages the area you would like to
                discuss
              </p>
              <a href="https://calendly.com/optionblitz-info">
                <Button>
                  <img src={Calendar} alt="" />
                  <span>Schedule a call</span>
                </Button>
              </a>
            </InfoBlock>
          </Fade>
          <Fade right>
            <picture>
              <source srcSet={`${Image}, ${Image_2x} 2x`} />
              <img srcSet={`${Image}, ${Image_2x} 2x`} alt="My default image" />
            </picture>
          </Fade>
        </div>
      </div>
    </section>
  );
};

export default ScheduleACall;
