import React from "react";
import s from "./Button.module.scss";

const Button = ({ children, className, onClick, disabled, id, link }) => {
  if (link) {
    return (
      <a href={link} target="_blank">
        <button id={id} className={[s.button, className].join(" ")} onClick={onClick} disabled={disabled}>
          {children}
        </button>
      </a>
    );
  } else {
    return (
      <button id={id} className={[s.button, className].join(" ")} onClick={onClick} disabled={disabled}>
        {children}
      </button>
    );
  }
};

export default Button;
