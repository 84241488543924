import { atom, DefaultValue } from "recoil";
import { _connectToContracts, _OptionBlitzDeploymentJSON, _OptionBlitzContracts } from "../contracts/contracts";
import devOrNull from "../contracts/deployments/default/privateL1.json";
import goerli from "../contracts/deployments/default/goerliL1.json";
import mainnet from "../contracts/deployments/default/mainnetL1.json";
import userState from "./userAtom";
import { enterPresale, toUsdc } from "../contracts/transaction";
import { PresaleStartEvent } from "src/contracts/typechain-types/contracts/sale/BlxPresale";
import { BigNumber, Contract } from "ethers";
import { BlxPresale__factory, BlxPresale, IBCO, Multicall2, Multicall2__factory } from "src/contracts/typechain-types";
import { Provider } from "@ethersproject/providers";
import { defaultChainId, provider as defaultProvider } from "src/config";

const deployments = {
  9413: devOrNull,
  5: goerli,
  1: mainnet,
};

export interface Purchase {
  buyer: string;
  usdc: number;
  blx: number;
  timestamp: number;
  blockNumber: number;
  logIndex: number;
  transactionHash: string;
}

export interface ContractState {
  ibcoSold: number;
  ibcoBlxSold: number;
  ibcoMaxPurchase: number;
  ibcoAvailableBlx: number;
  presaleMaxPurchase: number;
  presaleBlxSold: number;
  presaleSold: number;
  ibcoStart?: number;
  ibcoEnd?: number;
  hardCap?: number;
  softCap?: number;
  presaleHardCap?: number;
  presaleSoftCap?: number;
  presaleStart?: number;
  presaleEnd?: number;
  blxPrice?: number;
  latestPurchases?: Purchase[];
  deployment?: _OptionBlitzDeploymentJSON;
  contracts?: _OptionBlitzContracts;
  account?: string;
  myPresaleAmount?: undefined;
  myIBCOBalance?: undefined;
  myBlxBalance?: undefined;
  myReferredBlx?: undefined;
  myReferredAmount?: undefined;
  presaleDuration?: number;
  ibcoDuration?: number;
  myRewardBlx?: number;
  presaleClosed: boolean;
  presaleSoftCapReached: boolean;
  ibcoClosed: boolean;
  ibcoSoftCapReached: boolean;
  myRewardsClaimed?: number;
  myPresaleClaimed?: number;
}

const blockInfo: {
  startBlock:
    | {
        blockNumber: number;
        timestamp: number;
      }
    | undefined;
  blocks: {
    blockNumber: number;
    timestamp: number;
  }[];
} = {
  startBlock: undefined,
  blocks: [],
};

const calcRewardBlx = (referred_amount: number, referred_blx: number) => {
  if (referred_amount > 100000) return referred_blx / 10;
  else if (referred_amount > 20000) return (referred_blx * 7) / 100;
  else if (referred_amount > 10000) return (referred_blx * 5) / 100;
  else if (referred_amount > 5000) return (referred_blx * 3) / 100;
  else return referred_blx / 100;
};
const getTokenSaleStatus = async (
  multicall: Multicall2,
  blxPresale: BlxPresale,
  ibco: IBCO,
  provider: Provider,
  account?: string
) => {
  const multiCallStructs: Multicall2.CallStruct[] = [
    [blxPresale.address, blxPresale.interface.encodeFunctionData("amountFromWhitelisted")],
    [blxPresale.address, blxPresale.interface.encodeFunctionData("hardCapReached")],
    [blxPresale.address, blxPresale.interface.encodeFunctionData("softCapReached")],
    [blxPresale.address, blxPresale.interface.encodeFunctionData("maxPurchase")],
    [blxPresale.address, blxPresale.interface.encodeFunctionData("presaleStart")],
    [blxPresale.address, blxPresale.interface.encodeFunctionData("presaleEnd")],
    [blxPresale.address, blxPresale.interface.encodeFunctionData("hardCap")],
    [blxPresale.address, blxPresale.interface.encodeFunctionData("softCap")],
    [blxPresale.address, blxPresale.interface.encodeFunctionData("presaleDuration")],
    [ibco.address, ibco.interface.encodeFunctionData("amountFromWhitelisted")],
    [ibco.address, ibco.interface.encodeFunctionData("hardCapReached")],
    [ibco.address, ibco.interface.encodeFunctionData("softCapReached")],
    [ibco.address, ibco.interface.encodeFunctionData("ibcoStart")],
    [ibco.address, ibco.interface.encodeFunctionData("ibcoEnd")],
    [ibco.address, ibco.interface.encodeFunctionData("hardCap")],
    [ibco.address, ibco.interface.encodeFunctionData("softCap")],
    [ibco.address, ibco.interface.encodeFunctionData("duration")],
    [ibco.address, ibco.interface.encodeFunctionData("distributedBlx")],
    [ibco.address, ibco.interface.encodeFunctionData("blxAvailable")],
    [ibco.address, ibco.interface.encodeFunctionData("maxPurchase")],
    [ibco.address, ibco.interface.encodeFunctionData("currentPrice")],
    [blxPresale.address, blxPresale.interface.encodeFunctionData("presaleClosed")],
    [ibco.address, ibco.interface.encodeFunctionData("closed")],
    ...(!account
      ? []
      : [
          [blxPresale.address, blxPresale.interface.encodeFunctionData("collaterals", [account])],
          [ibco.address, ibco.interface.encodeFunctionData("collaterals", [account])],
          [blxPresale.address, blxPresale.interface.encodeFunctionData("referral_rewards", [account])],
        ]),
  ].map((v) => ({
    target: v[0],
    callData: v[1],
  }));

  const tx = await multicall.populateTransaction.aggregate(multiCallStructs);
  const results = await provider.call(tx);
  const x = multicall.interface.decodeFunctionResult("aggregate", results);
  const { blockNumber, returnData }: { blockNumber: BigNumber; returnData: string[] } = x as any;
  return {
    presaleSold: blxPresale.interface.decodeFunctionResult("amountFromWhitelisted", returnData[0] as string)[0],
    presaleHardCapReached: blxPresale.interface.decodeFunctionResult("hardCapReached", returnData[1] as string)[0],
    presaleSoftCapReached: blxPresale.interface.decodeFunctionResult("softCapReached", returnData[2] as string)[0],
    presaleMaxPurchase: blxPresale.interface.decodeFunctionResult("maxPurchase", returnData[3] as string)[0],
    presaleStart: blxPresale.interface.decodeFunctionResult("presaleStart", returnData[4] as string)[0],
    presaleEnd: blxPresale.interface.decodeFunctionResult("presaleEnd", returnData[5] as string)[0],
    presaleHardCap: blxPresale.interface.decodeFunctionResult("hardCap", returnData[6] as string)[0],
    presaleSoftCap: blxPresale.interface.decodeFunctionResult("softCap", returnData[7] as string)[0],
    presaleDuration: blxPresale.interface.decodeFunctionResult("presaleDuration", returnData[8] as string)[0],
    ibcoSold: ibco.interface.decodeFunctionResult("amountFromWhitelisted", returnData[9] as string)[0],
    ibcoHardCapReached: ibco.interface.decodeFunctionResult("hardCapReached", returnData[10] as string)[0],
    ibcoSoftCapReached: ibco.interface.decodeFunctionResult("softCapReached", returnData[11] as string)[0],
    ibcoMaxPurchase: blxPresale.interface.decodeFunctionResult("maxPurchase", returnData[12] as string)[0],
    ibcoStart: ibco.interface.decodeFunctionResult("ibcoStart", returnData[13] as string)[0],
    ibcoEnd: ibco.interface.decodeFunctionResult("ibcoEnd", returnData[14] as string)[0],
    hardCap: ibco.interface.decodeFunctionResult("hardCap", returnData[15] as string)[0],
    softCap: ibco.interface.decodeFunctionResult("softCap", returnData[16] as string)[0],
    ibcoDuration: ibco.interface.decodeFunctionResult("duration", returnData[17] as string)[0],
    ibcoBlxSold: ibco.interface.decodeFunctionResult("distributedBlx", returnData[18] as string)[0],
    ibcoAvailableBlx: ibco.interface.decodeFunctionResult("blxAvailable", returnData[19] as string)[0],
    blxPrice: ibco.interface.decodeFunctionResult("currentPrice", returnData[20] as string)[0],
    presaleClosed: blxPresale.interface.decodeFunctionResult("presaleClosed", returnData[21] as string)[0],
    ibcoClosed: ibco.interface.decodeFunctionResult("closed", returnData[22] as string)[0],
    myPresaleAmount: account
      ? blxPresale.interface.decodeFunctionResult("collaterals", returnData[23] as string)
      : undefined,
    myIBCOAmount: account ? ibco.interface.decodeFunctionResult("collaterals", returnData[24] as string) : undefined,
    myRewards: account
      ? blxPresale.interface.decodeFunctionResult("referral_rewards", returnData[25] as string)
      : undefined,
  };
};

const getDeployment = (chainId: number) => {
  return deployments[chainId];
};

const addNewPurchase = (purchases: Purchase[], newPurchase: Purchase) => {
  const existing = purchases.filter(
    (p) => p.blockNumber === newPurchase.blockNumber && p.logIndex === newPurchase.logIndex
  );
  if (existing.length === 0) {
    return [newPurchase, ...purchases];
  } else return purchases;
};

const getBlockTime = async (blockNumber: number, provider: Provider) => {
  const { startBlock } = blockInfo;
  const { blockNumber: startBlockNumber, timestamp: startTimestamp } = startBlock ?? {};
  let _block: { blockNumber: number; timestamp: number };
  if (!startBlockNumber) {
    const block = await provider.getBlock(blockNumber);
    const { timestamp } = block;
    _block = { blockNumber, timestamp };
    //console.log(`start block ${blockNumber} ${timestamp}`);
    blockInfo.startBlock = { blockNumber, timestamp };
    //blockInfo.blocks.push({ blockNumber, timestamp })
  } else {
    const timestamp = startTimestamp! + (blockNumber - startBlockNumber) * 12;
    _block = { blockNumber, timestamp };
    //blockInfo.blocks.push(_block);
  }
  //console.log(blockNumber, timestamp, new Date(timestamp*1000));
  return _block;
};

const processNewPresaleEvent = async (BlxPresale: BlxPresale, newPurchaseEvent, provider, needTime = true) => {
  //console.log(swapEvent);
  const { address, blockNumber, transactionHash } = newPurchaseEvent;
  const block = !needTime ? { blockNumber, timestamp: undefined } : getBlockTime(blockNumber, provider);
  return Promise.all([block])
    .then(([{ blockNumber, timestamp }]) => {
      const log = BlxPresale.interface.parseLog(newPurchaseEvent);
      const { logIndex, transactionHash } = newPurchaseEvent;
      const { buyer, usdc, blx } = log.args;
      return { blockNumber, timestamp, buyer, usdc, blx, logIndex, transactionHash };
    })
    .catch((err) => {
      //console.log(err);
      throw err;
    });
};

const getPresale = async (BlxPresale: BlxPresale, startBlock: number, historySize: number, provider: Provider) => {
  const currentBlock = await provider.getBlockNumber();
  const segment = 5 * 60 * 60 * 3; // blocks for 3 days
  const fromBlock =
    currentBlock - historySize < startBlock || historySize <= 0 ? startBlock : currentBlock - historySize;
  const segmentSize = currentBlock - fromBlock > segment ? segment : currentBlock - fromBlock;
  const blockRange = Array.from(
    new Array(Math.round((currentBlock - fromBlock + segmentSize / 2) / segmentSize)).keys()
  ).map((i) => ({ from: currentBlock - (i + 1) * segmentSize, to: currentBlock - i * segmentSize }));
  const blockOfEvents = blockRange.map(({ from, to }) =>
    BlxPresale.queryFilter(BlxPresale.filters.NewPurchase(), from, to)
  );
  const events = await Promise.all(blockOfEvents).then((arrOfEvents) => arrOfEvents.flat());
  //console.log(startBlock, fromBlock, currentBlock, blockRange, events);
  return events;
};

const processNewIBCOEvent = async (ibco: IBCO, newPurchaseEvent, provider) => {
  //console.log(swapEvent);
  const { address, blockNumber, transactionHash } = newPurchaseEvent;
  const block = getBlockTime(blockNumber, provider);
  return Promise.all([block])
    .then(([{ blockNumber, timestamp }]) => {
      const log = ibco.interface.parseLog(newPurchaseEvent);
      const { logIndex, transactionHash } = newPurchaseEvent;
      const { buyer, usdc, blx } = log.args;
      return { blockNumber, timestamp, buyer, usdc, blx, logIndex, transactionHash };
    })
    .catch((err) => {
      //console.log(err);
      throw err;
    });
};

const contractState = atom<ContractState>({
  key: "contractState", // unique ID (with respect to other atoms/selectors)
  default: {
    ibcoBlxSold: 0,
    presaleBlxSold: 0,
    ibcoSold: 0,
    presaleSold: 0,
    ibcoMaxPurchase: 0,
    ibcoAvailableBlx: 0,
    presaleMaxPurchase: 0,
    presaleClosed: false,
    presaleSoftCapReached: false,
    ibcoClosed: false,
    ibcoSoftCapReached: false,
  }, // default value (aka initial value)
  effects: [
    ({ setSelf, getPromise, getLoadable, node, onSet, resetSelf }) => {
      console.log("load contracts");
      const POLL_INTERVAL = 30 * 60 * 1000;
      const PURCHASE_POLL_INTERVAL = 2 * 60 * 1000;
      // must use 'global' as 'node' can be of differnt 'view'
      // below works based on the assumption that js is single-thread
      let status = {
        ibcoSold: 0,
        ibcoBlxSold: 0,
        presaleSold: 0,
        presaleBlxSold: 0,
        ibcoMaxPurchase: 0,
        presaleMaxPurchase: 0,
        ibcoStart: 0,
        ibcoEnd: 0,
        ibcoAvailableBlx: 0,
        latestPurchases: [],
        hardCap: undefined,
        softCap: undefined,
        presaleHardCap: undefined,
        presaleSoftCap: undefined,
        presaleStart: undefined,
        presaleEnd: undefined,
        blxPrice: 0.1,
        account: undefined,
        myPresaleAmount: undefined,
        myIBCOBalance: undefined,
        myBlxBalance: undefined,
        myReferredBlx: undefined,
        myReferredAmount: undefined,
        myRewardBlx: undefined,
        presaleDuration: 60 * 60 * 24 * 28 * 1000,
        ibcoDuration: 60 * 60 * 24 * 28 * 1000,
        presaleClosed: false,
        presaleSoftCapReached: false,
        ibcoClosed: false,
        ibcoSoftCapReached: false,
        myRewardsClaimed: undefined,
        myPresaleClaimed: undefined,
      };
      const deployment = getDeployment(defaultChainId);
      const contracts = _connectToContracts(null, deployment as _OptionBlitzDeploymentJSON);
      const { startBlock } = deployment as _OptionBlitzDeploymentJSON;
      setSelf({ deployment: deployment, contracts, ...status });

      let presaleStatus, presaleConfig, ibcoConfig, ibcoStatus, presaleNewPurchase;
      let listeningPresale = false;

      const getCurrentTokenSaleStatus = (netProvider?: Provider) => {
        const provider = netProvider || defaultProvider;
        console.log("multi-call provider", provider);
        getTokenSaleStatus(
          contracts.Multicall2.connect(provider),
          contracts.BlxPresale.connect(provider),
          contracts.IBCO.connect(provider),
          provider,
          status.account
        )
          .then((results) => {
            const oldValue = getLoadable(node).contents as ContractState;
            const newAccount = false;
            console.log(results);
            status.presaleStart = results.presaleStart.toNumber() * 1000;
            status.presaleEnd = results.presaleEnd.gt(0) ? results.presaleEnd.toNumber() * 1000 : 0;
            status.presaleHardCap = +toUsdc(results.presaleHardCap);
            status.presaleSoftCap = +toUsdc(results.presaleSoftCap);
            status.presaleDuration = results.presaleDuration.toNumber();
            status.presaleSold = +toUsdc(results.presaleSold);
            status.presaleBlxSold = +toUsdc(results.presaleSold) * 10; // 1:10
            status.presaleMaxPurchase = +toUsdc(results.presaleMaxPurchase);
            status.ibcoStart = results.ibcoStart.toNumber() * 1000;
            status.ibcoEnd = results.ibcoEnd.toNumber() * 1000;
            status.hardCap = +toUsdc(results.hardCap);
            status.softCap = +toUsdc(results.softCap);
            status.ibcoSold = +toUsdc(results.ibcoSold);
            status.blxPrice = +toUsdc(results.blxPrice) || 0.1;
            status.ibcoBlxSold = +toUsdc(results.ibcoBlxSold);
            status.ibcoAvailableBlx = +toUsdc(results.ibcoAvailableBlx);
            status.ibcoMaxPurchase = +toUsdc(results.ibcoMaxPurchase);
            status.presaleClosed = results.presaleClosed;
            status.presaleSoftCapReached = results.presaleSoftCapReached;
            status.ibcoClosed = results.ibcoClosed;
            status.ibcoSoftCapReached = results.ibcoSoftCapReached;
            if (status.account) {
              status.myPresaleAmount = +toUsdc(results.myPresaleAmount.amount);
              status.myIBCOBalance = +toUsdc(results.myIBCOAmount.amount);
              status.myBlxBalance = +toUsdc(
                results.myPresaleAmount.amount.mul(10).add(results.myIBCOAmount.amountToClaim)
              );
              status.myReferredBlx = +toUsdc(results.myRewards.referred_ibco_blx.add(results.myRewards.referred_blx));
              status.myReferredAmount = +toUsdc(
                results.myRewards.referred_amount.add(results.myRewards.referred_ibco_amount)
              );
              status.myRewardBlx = calcRewardBlx(status.myReferredAmount, status.myReferredBlx);
              status.myRewardsClaimed = +toUsdc(results.myRewards.claimed);
              status.myPresaleClaimed = results.myPresaleAmount.redeemed ? status.myPresaleAmount * 10 : 0;
            }
            setSelf({ ...oldValue, ...status });
          })
          .catch((err) => {
            console.log("multicall error", err);
          });
      };
      const getMyBalance = (newAccount = false) => {
        const { account } = status;
        if (account) {
          const blxPresale = contracts.BlxPresale.connect(defaultProvider);
          const ibco = contracts.IBCO.connect(defaultProvider);
          const myPresaleAmount = blxPresale.collaterals(account);
          const myIBCOBalance = ibco.collaterals(account);
          const rewards = blxPresale.referral_rewards(account);
          Promise.all([myPresaleAmount, myIBCOBalance, rewards])
            .then(([p, i, r]) => {
              const oldValue = getLoadable(node).contents as ContractState;
              status.myPresaleAmount = +toUsdc(p.amount);
              status.myIBCOBalance = +toUsdc(i.amount);
              status.myBlxBalance = +toUsdc(p.amount.mul(10).add(i.amountToClaim));
              status.myReferredBlx = +toUsdc(r.referred_ibco_blx.add(r.referred_blx));
              status.myReferredAmount = +toUsdc(r.referred_amount.add(r.referred_ibco_amount));
              setSelf({ ...oldValue, ...status });
            })
            .catch((err) => {});
        } else if (newAccount) {
          const oldValue = getLoadable(node).contents as ContractState;
          status.myPresaleAmount = undefined;
          status.myIBCOBalance = undefined;
          status.myBlxBalance = undefined;
          status.myReferredBlx = undefined;
          status.myReferredAmount = undefined;
          setSelf({ ...oldValue, ...status });
        }
      };
      const getNewPresalePurchase = (blxPresale: BlxPresale, historySize: number, netProvider: Provider) => {
        // getPresale(blxPresale, startBlock, 0, netProvider)
        // .catch(err => {

        // });
        const { blockNumber: priorBlock } = (status.latestPurchases || [])[0] || {};
        console.log("fetch new purchases", netProvider);
        getPresale(blxPresale, priorBlock || startBlock, 0, netProvider)
          //blxPresale.queryFilter(blxPresale.filters.NewPurchase(), 0 - historySize)
          .then((newPurchasesEvents) => {
            const purchases = newPurchasesEvents.map(async (evt) => {
              const { blockNumber, logIndex, transactionHash } = evt;
              const { buyer, blx, usdc } = evt.args;
              const purchase = await processNewPresaleEvent(blxPresale, evt, netProvider);
              return {
                buyer,
                usdc: +toUsdc(usdc),
                blx: +toUsdc(blx),
                timestamp: purchase.timestamp * 1000,
                blockNumber,
                logIndex,
                transactionHash,
              };
            });
            return Promise.all(purchases);
          })
          .then((purchases) => {
            purchases.sort((a, b) => {
              if (a.blockNumber > b.blockNumber) return -1;
              else if (a.blockNumber < b.blockNumber) return 1;
              else return b.logIndex - a.logIndex;
            });
            const oldValue = getLoadable(node).contents as ContractState;
            const currentPurchases = status.latestPurchases;
            const newPurchases = purchases.filter(
              (p) =>
                currentPurchases.filter(
                  (pp) =>
                    pp.transactionHash === p.transactionHash &&
                    pp.blockNumber === p.blockNumber &&
                    pp.logIndex === p.logIndex
                ).length === 0
            );
            status.latestPurchases = [...newPurchases, ...status.latestPurchases];
            setSelf({ ...oldValue, ...status });
          })
          .catch((err) => {});
      };

      const newPresaleListener = (buyer, usdc, blx, evt) => {
        console.log(`presale purchase by ${buyer} ${toUsdc(usdc)} ${toUsdc(blx)}`, evt);
        getBlockTime(evt.blockNumber, defaultProvider)
          .then((blockInfo) => {
            const oldValue = getLoadable(node).contents as ContractState;
            const { blockNumber, logIndex, transactionHash } = evt;
            const existing = status.latestPurchases;
            const revised = addNewPurchase(existing, {
              buyer,
              usdc: +toUsdc(usdc),
              blx: +toUsdc(blx),
              timestamp: blockInfo.timestamp * 1000,
              blockNumber,
              logIndex,
              transactionHash,
            });
            if (existing !== revised) {
              status.latestPurchases = revised;
              setSelf({ ...oldValue, ...status });
            }
          })
          .catch((err) => {});
        getCurrentTokenSaleStatus();
      };
      getCurrentTokenSaleStatus();

      presaleNewPurchase = setInterval(() => {
        console.log("polling new presale purchases");
        // getWalletNetProvider(status.account)
        //     .then(walletNetProvider => {
        //         const netProvider = walletNetProvider || getNetProvider();

        //         if (!netProvider && false) {
        //             console.log('wallet not connected, skip presale purchase update');
        //             return;
        //         }

        //         const blxPresale: BlxPresale = contracts.BlxPresale.connect(netProvider);
        //         const ibco = contracts.IBCO.connect(netProvider);
        //         const historySize = 300 * 24 * 14; // in blocks so about 14 days (10 * 60 * 60 / 12)

        //         getNewPresalePurchase(blxPresale, historySize, netProvider);
        //     })
        //     .catch(e => {

        //     });
        getCurrentTokenSaleStatus();
      }, PURCHASE_POLL_INTERVAL);

      // getWalletNetProvider(status.account).then(walletNetProvider => {
      //     const netProvider = walletNetProvider || getNetProvider();
      //     const { connection } = netProvider;
      //     const { url: connectionUrl } = connection;
      //     const blxPresale: BlxPresale = contracts.BlxPresale.connect(netProvider);
      //     const ibco = contracts.IBCO.connect(netProvider);
      //     const historySize = 3000; // in blocks so about 10 hours(10 * 60 * 60 / 12)

      //     getNewPresalePurchase(blxPresale, historySize, netProvider);

      //     // ibco.queryFilter(ibco.filters.NewPurchase(), 0 - historySize)
      //     //     .then(newPurchasesEvents => {
      //     //         console.log('ibco purchases', newPurchasesEvents);
      //     //         const purchases = newPurchasesEvents.map(async evt => {
      //     //             const { blockNumber, logIndex, transactionHash } = evt;
      //     //             const { buyer, blx, usdc } = evt.args;
      //     //             const purchase = await processNewIBCOEvent(ibco, evt, netProvider);
      //     //             return {
      //     //                 buyer,
      //     //                 usdc: +toUsdc(usdc),
      //     //                 blx: +toUsdc(blx),
      //     //                 timestamp: purchase.timestamp * 1000,
      //     //                 blockNumber,
      //     //                 logIndex,
      //     //                 transactionHash,
      //     //             };
      //     //         });
      //     //         return Promise.all(purchases);
      //     //     })
      //     //     .then(purchases => {
      //     //         purchases.sort((a, b) => {
      //     //             if (a.blockNumber > b.blockNumber) return -1;
      //     //             else if (a.blockNumber < b.blockNumber) return 1;
      //     //             else return b.logIndex - a.logIndex;
      //     //         });
      //     //         const oldValue = getLoadable(node).contents as ContractState;
      //     //         status.latestPurchases = [...purchases, ...status.latestPurchases];
      //     //         setSelf({ ...oldValue, ...status });
      //     //     })
      //     //     .catch(err => {

      //     //     });

      //     // if (connectionUrl === 'metamask'
      //     // // || connectionUrl === 'eip-1193:'
      //     //  ) {
      //     //     console.log('observing new presale purchases', connectionUrl, netProvider);
      //     //     listeningPresale = true;
      //     //     blxPresale.on(blxPresale.filters.NewPurchase(), newPresaleListener);
      //     // }
      //     // else {
      //     //     console.log('wallet not connected, no live watching presale purchases');
      //     // }

      //     // ibco.on(ibco.filters.IBCOStart(), (endTime: BigNumber, duration, softcap, hardCap, evt) => {
      //     //     console.log(`ibco started ${new Date(endTime.toNumber() * 1000)}`, evt);
      //     //     getIBCOConfig();
      //     // });

      //     // ibco.on(ibco.filters.NewPurchase(), (buyer, usdc, blx, evt) => {
      //     //     console.log(`ibco purchase by ${buyer} ${toUsdc(usdc)} ${toUsdc(blx)}`, evt);
      //     //     getBlockTime(evt.blockNumber, netProvider)
      //     //         .then((blockInfo) => {
      //     //             const oldValue = getLoadable(node).contents as ContractState;
      //     //             const { blockNumber, logIndex, transactionHash } = evt;
      //     //             const existing = status.latestPurchases;
      //     //             const revised = addNewPurchase(existing, {
      //     //                 buyer,
      //     //                 usdc: +toUsdc(usdc),
      //     //                 blx: +toUsdc(blx),
      //     //                 timestamp: blockInfo.timestamp * 1000,
      //     //                 blockNumber,
      //     //                 logIndex,
      //     //                 transactionHash,
      //     //             });
      //     //             if (existing !== revised) {
      //     //                 status.latestPurchases = revised;
      //     //                 setSelf({ ...oldValue, ...status });
      //     //             }
      //     //         })
      //     //         .catch(err => {

      //     //         })
      //     //     getIBCOBlx();
      //     //     getMyBalance();
      //     // });
      // })

      onSet((newState, oldState, isReset) => {
        // // user login, wallet(and provider) is now available
        // const { account } = newState;
        // const newAccount = account !== status.account;
        // status.account = account;
        // setSelf({ ...oldState, ...status, account });
        // getCurrentTokenSaleStatus();
        // getWalletNetProvider(status.account).then(walletNetProvider => {
        //     const netProvider = walletNetProvider || getNetProvider();
        //     const { connection } = netProvider;
        //     const { url: connectionUrl } = connection;
        //     const blxPresale: BlxPresale = contracts.BlxPresale.connect(netProvider);
        //     console.log('account from wallet', account, walletNetProvider, connectionUrl, listeningPresale);
        //     if (!listeningPresale && (connectionUrl === 'metamask' || connectionUrl === 'eip-1193:')) {
        //         console.log('observing new presale purchases', netProvider);
        //         listeningPresale = true;
        //         blxPresale.on(blxPresale.filters.NewPurchase(), newPresaleListener);
        //     }
        // }).catch(e => {
        // })
      });
    },
  ],
});

export default contractState;
