import React, { useState, useEffect } from "react";
import s from "./Language.module.scss";
import US from "./../../../assets/images/us.webp";
import Russia from "./../../../assets/images/russia.png";
import Vietnam from "./../../../assets/images/flags/vietnam.png";
import Spain from "./../../../assets/images/flags/spain.png";
import Portugal from "./../../../assets/images/flags/portugal.png";
import Indonesia from "./../../../assets/images/flags/indonesia.png";
import France from "./../../../assets/images/flags/france.png";
import Turkey from "./../../../assets/images/flags/turkey.png";
import China from "./../../../assets/images/flags/china.png";
import { useTranslation } from "react-i18next";

const Language = () => {
  const { i18n } = useTranslation();
  const languages = [
    { key: "us", image: US },
    { key: "ru", image: Russia },
    { key: "vi", image: Vietnam },
    { key: "es", image: Spain },
    { key: "pt", image: Portugal },
    { key: "id", image: Indonesia },
    { key: "fr", image: France },
    { key: "tr", image: Turkey },
    { key: "zh", image: China },
  ];
  const [isActive, setIsActive] = useState(false);
  const [selectedLang, setSelectedLang] = useState({ key: "us", image: US });

  const handleClick = () => {
    setIsActive(!isActive);
  };

  // setting default language based on user's browser language.
  useEffect(() => {
    const userLang = navigator.language?.split("-")[0];
    const userLangObj = languages.find((lang) => lang.key === userLang);

    if (userLangObj) {
      setSelectedLang(userLangObj);
    }
  }, []);

  return (
    <div className={s.language}>
      <div className={s.language__current} onClick={handleClick}>
        <img src={selectedLang.image} alt="" />
      </div>
      {isActive && (
        <div className={s.language__dropdown}>
          {languages
            .filter((lang) => lang.key !== selectedLang.key)
            .map((lang, index) => (
              <a key={index}>
                <img
                  src={lang.image}
                  alt="country-flag"
                  width="24"
                  onClick={() => {
                    setSelectedLang(lang);
                    setIsActive(false);
                    i18n.changeLanguage(lang.key);
                  }}
                />
              </a>
            ))}
        </div>
      )}
    </div>
  );
};

export default Language;
