import { atom } from "recoil";
import { POLLING_INTERVAL } from "src/constants";
import { getChange } from "src/utils/common";
import { EQUITY_PAIRS, DEFAULT_EQUITY_DATA, INTERVAL_RESOLUTION } from "../socket/constants";
import { getPythCandles } from "./OptionBlitzProvider";

export const equitiesState = atom({
  key: "equitiesState", // unique ID (with respect to other atoms/selectors)
  default: {
    data: { ...DEFAULT_EQUITY_DATA },
    isDataFetched: false,
  },
  effects: [
    ({ setSelf, getLoadable, node, resetSelf }) => {
      // fetching current prices from finage REST API.
      const fetchPriceData = async () => {
        // old state.
        let { data } = getLoadable(node).contents;
        const prices = await Promise.all(
          EQUITY_PAIRS.map((pair) => getPythCandles(pair.split("/").join("_"), INTERVAL_RESOLUTION, 3))
        );

        // creating updated data.
        let updatedData = {};
        for (let i = 0; i < EQUITY_PAIRS.length; i++) {
          let dailyChange = getChange(prices[i]?.newPrice?.rate, prices[i]?.oldPrice?.rate)
            ? getChange(prices[i]?.newPrice?.rate, prices[i]?.oldPrice?.rate).toFixed(2)
            : "N/A";

          let newPairData = {
            ...data[EQUITY_PAIRS[i]],
            /** @type {string} */
            s: EQUITY_PAIRS[i],

            /** @type {number} */
            p: prices[i].newPrice?.rate,

            /** @type {string} */
            dc: dailyChange,

            /** @type {{C:number}[]} */
            graphData: prices[i].obCandles.map((x) => ({ C: x?.rate })),
          };
          updatedData[EQUITY_PAIRS[i]] = newPairData;
        }

        setSelf({ isDataFetched: true, data: { ...updatedData } });
      };

      async function fetchData() {
        await fetchPriceData();
      }

      // fetching data first time.
      fetchData();

      setInterval(() => {
        fetchPriceData();
      }, POLLING_INTERVAL);

      // cleanup
      return () => {
        resetSelf();
      };
    },
  ],
});
