import React, { useState, useEffect } from "react";
import s from "./TradingProducts.module.scss";
import InfoBlock from "../InfoBlock/InfoBlock";
import Advantage from "../Advantage/Advantage";
import Sandglass from "./../../assets/images/sandglass.svg";
import Exercise from "./../../assets/images/exercise.svg";
import Forex from "./../../assets/images/forex.svg";
import Transparent from "./../../assets/images/transparent.svg";
import Moneybag from "./../../assets/images/moneybag.svg";
import CreditCard from "./../../assets/images/credit_card.svg";
import Refresh from "./../../assets/images/refresh.svg";
import Image from "../../assets/images/options__image.webp";
import Image_2x from "../../assets/images/options__image_2x.webp";
import BinaryImage from "../../assets/images/binary__image.webp";
import BinaryImage_2x from "../../assets/images/binary__image_2x.png";
import TouchImage from "../../assets/images/touch__image.webp";
import TouchImage_2x from "../../assets/images/touch__image_2x.png";
import Fade from "react-reveal/Fade";
import { useTranslation } from "react-i18next";

const TradingProductSections = () => {
  const { t } = useTranslation();

  let [isAdaptive, setIsAdaptive] = useState(window.screen.width <= 768);
  useEffect(() => {
    window.addEventListener(
      "resize",
      function (event) {
        setIsAdaptive(window.screen.width <= 768);
      },
      true
    );
  }, []);

  return (
    <>
      <section className={s.trading_products_section}>
        <div className={s.trading_products}>
          <div className="container">
            <h1>{t("Options.heading")}</h1>
            <Fade bottom>
              <div className={s.trading_products__block}>
                <InfoBlock className={s.trading_products__block_info}>
                  <h3>{t("Options.1.heading")}</h3>
                  <p>{t("Options.1.description")}</p>
                  <p> {t("Options.1.description1")}</p>
                  <p> {t("Options.1.description2")}</p>
                  {/* <p>{t("Options.1.description3")}</p> */}
                </InfoBlock>
                <div className={s.trading_products__block_column}>
                  <div className={s.trading_products__block_advantages}>
                    <Advantage icon={Sandglass} text={"10m to 24h expiration"} />
                    <Advantage icon={Exercise} text={"Exercise at any time"} />
                    <Advantage icon={Forex} text={"Forex, Crypto, Commodity, Energy markets"} />
                    <Advantage icon={Transparent} text={"Transparent pricing"} />
                  </div>
                  <div className={s.trading_products__block_image}>
                    <picture>
                      <source srcSet={`${Image}, ${Image_2x} 2x`} />
                      <img srcSet={`${Image}, ${Image_2x} 2x`} alt="My default" height="581" width="785" />
                    </picture>
                  </div>
                </div>
              </div>
            </Fade>
          </div>
        </div>
        <div className={s.binary}>
          <div className="container">
            <Fade bottom>
              <div className={s.binary__image}>
                <div className={s.binary__image_container}>
                  <picture>
                    <source srcSet={`${BinaryImage}, ${BinaryImage_2x} 2x`} />
                    <img srcSet={`${BinaryImage}, ${BinaryImage_2x} 2x`} alt="My default" height="auto" width="100%" />
                  </picture>
                </div>
                <div className={s.binary__image_advantages}>
                  <Advantage icon={Sandglass} text={"10s to 1 hour expiration"} />
                  <Advantage icon={Moneybag} text={"Up to 90% payout rate"} />
                  <Advantage icon={CreditCard} text={"Minimum trade amount $1"} />
                </div>
              </div>
            </Fade>
            <Fade bottom>
              <InfoBlock className={s.binary__info}>
                <h3>{t("Options.2.heading")}</h3>
                <p>{t("Options.2.description")}</p>
                <p>{t("Options.2.description1")}</p>
              </InfoBlock>
            </Fade>
          </div>
        </div>
        <div className={s.touch}>
          <div className="container">
            <h3>
              {t("Options.3.heading")} <br /> {t("Options.3.heading1")}
            </h3>
            {isAdaptive ? (
              <>
                <Fade bottom>
                  <div className={s.touch__info}>
                    <InfoBlock className={s.touch__info_block}>
                      <p>{t("Options.3.description")}</p>
                      <p>{t("Options.3.description1")}</p>
                    </InfoBlock>
                    <div className={s.touch__info_image}>
                      <picture>
                        <source srcSet={`${TouchImage}, ${TouchImage_2x} 2x`} />
                        <img srcSet={`${TouchImage}, ${TouchImage_2x} 2x`} alt="My default" height="auto" width="627" />
                      </picture>
                    </div>
                  </div>
                </Fade>
                <Fade bottom>
                  <div className={s.touch__advantages}>
                    <Advantage icon={Sandglass} text={"10m to 24h expiration"} />
                    <Advantage icon={Moneybag} text={"Up to 200% payout rates"} />
                    <Advantage icon={Refresh} text={"Custom barrier levels"} />
                    <Advantage icon={CreditCard} text={"Minimum trade amount $1"} />
                  </div>
                </Fade>
              </>
            ) : (
              <>
                <Fade bottom>
                  <div className={s.touch__advantages}>
                    <Advantage icon={Sandglass} text={"10m to 24h expiration"} />
                    <Advantage icon={Moneybag} text={"Up to 200% payout rates"} />
                    <Advantage icon={Refresh} text={"Custom barrier levels"} />
                    <Advantage icon={CreditCard} text={"Minimum trade amount $1"} />
                  </div>
                </Fade>
                <Fade bottom>
                  <div className={s.touch__info}>
                    <InfoBlock className={s.touch__info_block}>
                      <p>{t("Options.3.description")}</p>
                      <p>{t("Options.3.description1")}</p>
                    </InfoBlock>
                    <div className={s.touch__info_image}>
                      <picture>
                        <source srcSet={`${TouchImage}, ${TouchImage_2x} 2x`} />
                        <img srcSet={`${TouchImage}, ${TouchImage_2x} 2x`} alt="My default" height="auto" width="627" />
                      </picture>
                    </div>
                  </div>
                </Fade>
              </>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default TradingProductSections;
