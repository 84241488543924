import axios from "axios";

// gasless transaction forwarder(biconomy)
export const biconomyForwarderAddresses = {
  1: "0x84a0856b038eaAd1cC7E297cF34A7e72685A8693",
  5: "0xE041608922d06a4F26C0d4c27d8bCD01daf1f792", // this is from query and website
  9413: "0x8474c0A047ad6a336df1D8c4234e06Cd324D9246",
};

const apiKeys = {
  1: 'q4rLN095Y.32cbf0cd-aeae-4788-90c8-2921af3e7fc8',
  5: 'iy7jsCA6O.5ca4ef96-a5b9-4971-9d28-d5cdbbb7f14b',
  9413: 'iy7jsCA6O.5ca4ef96-a5b9-4971-9d28-d5cdbbb7f14b',
};

const binconomyRpc = {
  1: {
    //baseUrl: "http://localhost:3003/biconomy",
    //baseUrl: "https://gasless-meta.prod.biconomy.io",
    baseUrl: "https://api.biconomy.io", // this must be v2
    metaData: "/api/v1/systemInfo/?networkId=1",
    submitV2: "/api/v2/meta-tx/native",
    //submitV1: "/api/v1/native",
  },
  5: {
    //baseUrl: "http://localhost:3003/biconomy",
    //baseUrl: "https://gasless-meta.prod.biconomy.io",
    baseUrl: "https://api.biconomy.io", // this must be v2
    metaData: "/api/v1/systemInfo/?networkId=5",
    submitV2: "/api/v2/meta-tx/native",
    //submitV1: "/api/v1/native",
  },
  9413: {
    //    baseUrl: "https://gasless-meta.prod.biconomy.io",
    baseUrl: "http://localhost:3003/biconomy",
    metaData: "/api/v1/systemInfo/?networkId=1",
    submitV2: "/api/v2/meta-tx/native",
    submitV1: "/api/v1/native",
  },
};

const submit = "/api/v1/native";

const funcMap = {
  5: {
    'enterPresale': 'baac15a2-3957-4e2b-8f89-3df7c6e1de12',
  },
  9413: {
    'enterPresale': 'faacd7cc-b7fd-4e65-949d-3b1fb49f3ea1',
  },
  1: {
    'enterPresale': '2b17edff-ce43-4c15-93f9-df9ba1805f91',
  },
}

// https://api.biconomy.io/api/v2/meta-tx/native only support personal sign
// https://gasless-meta.prod.biconomy.io/api/v1/native only support personal sign

export const sendTransaction = async (
  chainId: number,
  func: string,
  from: string,
  req: any,
  domainSeparator: string,
  sig: string,
  gasLimit: string, // hex string
  headers?: Record<string, string>
) => {
  let { baseUrl, submitV1, submitV2 }: { baseUrl: string, submitV1: string, submitV2: string } = binconomyRpc[chainId];
  const apiUrl = `${baseUrl}${submitV2}`;
  const apiKey = apiKeys[chainId];
  console.log(req, domainSeparator, sig);
  const axiosRequest = {
    url: apiUrl,
    method: 'post',
    data: JSON.stringify({
      to: req.to,
      apiId: funcMap[chainId][func],
      from: from,
      params: [req, domainSeparator, sig],
      signatureType: 'EIP712_SIGN',
      gasLimit: gasLimit,
      chainId: !baseUrl.includes('biconomy.io') ? chainId : undefined
    }),
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'x-api-key': `${apiKey}${chainId === 9413 ? 9413 : ''}`,
      ...headers,
    },
  };
  return axios(axiosRequest)
  .then(response => {
    const { data } = response;
    const { code, txHash, message, retryDuration, log } = data;
    if (!txHash) {
      return Promise.reject(log || "something wrong in the backend");
    }
    return { txHash };
  });
};

