import React, { useState } from "react";
import "add-to-calendar-button/assets/css/atcb.css";
import s from "./TokenSaleTimeline.module.scss";
import { Timeline1, Timeline2, Timeline3, Timeline4 } from "./Timeline/Timeline";
import Market from "./Market/Market";
import Timer from "../Timer/Timer";
import calenderIcon from "../../assets/images/calender__icon.svg";
import telegramIcon from "../../assets/images/telegram__icon.svg";
import Fade from "react-reveal";
import Button from "../UI/Button/Button";
import Popup from "../Popup/Popup";
import { atcb_action } from "add-to-calendar-button";
import useUser from "../../hooks/useUser";
import { useRecoilState } from "recoil";
import contractState from "src/state/contractAtom";
import { MONTHS, PRESALE_AMOUNT, TIME_OPTIONS } from "src/constants";
import { ethers } from "ethers";

// 28th feb, 2023 10:00:00 UTC
const START_TIME = 1677578400000;

// 28th march, 2023 10:00:00 UTC
const END_TIME = 1679997600000;

// 1st Oct 2023 10:00:00 UTC
export const IBCO_START_TIME = 1725098400000;
// 29th oct 2023 10:00:00 UTC
const IBCO_END_TIME = 1727776800000;

// default value to use for total raised.
const DEFAULT_TOTAL_RAISED = 0;

const PresaleInfo = ({ title }) => {
  const [openPopup, setOpenPopup] = useState(false);
  const { user, setAccount } = useUser();
  return (
    <>
      {openPopup && <Popup closePopup={() => setOpenPopup(false)} user={user} setAccount={setAccount} />}
      <div className={s.token_sale__presale__info}>
        <h2>{title}</h2>
        <a className={s.reward}>
          <p>
            💰 Get a 10% referral reward in BLX by sharing your affiliate link to new investors, click here now to visit
            your affiliate dashboard. <span onClick={() => setOpenPopup(true)}>CLICK HERE</span>
          </p>
        </a>
        <Button onClick={() => setOpenPopup(true)}>Get Reward</Button>
      </div>
    </>
  );
};

function addToCalendar() {
  atcb_action({
    name: "Presale Start Reminder",
    startDate: "2023-02-28",
    endDate: "2023-03-28",
    startTime: "10:00",
    endTime: "10:00",
    options: ["Apple", "Google", "Microsoft365"],
    timeZone: "Europe/Berlin",
  });
}
//get date
function getDate(timestamp) {
  const date = new Date(timestamp);
  const time = date.toLocaleTimeString("en-US", TIME_OPTIONS);
  const month = MONTHS[date.getMonth()];
  const year = date.getFullYear();
  const datewithSuffix = date.getDate() + getSuffix(date.getDate());
  return datewithSuffix + " " + month + " " + year + ", " + time;
}
//getDate suffix
function getSuffix(date) {
  if (date > 3 && date < 21) return "th";
  switch (date % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
}

const TokenSaleTimeline1 = ({ time, start, end }) => {
  return (
    <>
      <div className={s.token_sale__presale}>
        <Fade left>
          <PresaleInfo title={"Presale Starting Soon"} />
        </Fade>
        <Fade right>
          <div className={s.token_sale__presale__timer}>
            <div className={s.token_sale__presale__timer__btns}>
              <button onClick={addToCalendar}>
                <img src={calenderIcon} alt="icon" /> Add to Calendar
              </button>
              <a href="https://t.me/planetblx">
                <button>
                  <img src={telegramIcon} alt="icon" />
                  Subscribe
                </button>
              </a>
            </div>
            <div className="startTimer">
              <h3>Starts in</h3>
              <Timer presaleTimer={true} saleTime={time} />
            </div>
            <div className="start_time">
              <h3>Starts</h3>
              <div>
                <p>{getDate(start)}</p>
              </div>
            </div>
            <div className="end_time">
              <h3>Ends</h3>
              <div>
                <p>{getDate(end)}</p>
              </div>
            </div>
          </div>
        </Fade>
      </div>
      <Fade bottom>
        <Timeline1 />
      </Fade>
    </>
  );
};
const TokenSaleTimeline2 = ({ time, totalRaised }) => {
  return (
    <>
      <div className={s.token_sale__presale}>
        <Fade left>
          <PresaleInfo title={"PreSale has Started"} />
        </Fade>
        <Fade right>
          <div className={[s.token_sale__presale__timer, s.token_sale__presale__started].join(" ")}>
            <div className="startTimer">
              <h3>Ends in</h3>
              <Timer presaleTimer={true} saleTime={time} />
            </div>
            <div className="raised">
              <h3>Total raised</h3>
              <div>
                <h3>{ethers.utils.commify(totalRaised ?? DEFAULT_TOTAL_RAISED)}</h3>
                <span>USDC</span>
              </div>
            </div>
          </div>
        </Fade>
      </div>
      <Fade bottom>
        <Timeline2 />
      </Fade>
    </>
  );
};

const TokenSaleTimeline3 = ({ time, totalRaised }) => {
  return (
    <>
      <div className={s.token_sale__presale}>
        <Fade left>
          <PresaleInfo title={"Public Sale has Started"} />
        </Fade>
        <Fade right>
          <div className={[s.token_sale__presale__timer, s.token_sale__presale__started].join(" ")}>
            <div className="startTimer">
              <h3>Ends in</h3>
              <Timer presaleTimer={true} saleTime={time} />
            </div>
            <div className="raised">
              <h3>Total raised</h3>
              <div>
                <h3>{ethers.utils.commify(totalRaised ?? DEFAULT_TOTAL_RAISED)}</h3>
                <span>USDC</span>
              </div>
            </div>
          </div>
        </Fade>
      </div>
      <Fade bottom>
        <Timeline3 />
      </Fade>
    </>
  );
};
const TokenSaleTimeline4 = ({ time, totalRaised }) => {
  return (
    <>
      <div className={s.token_sale__presale}>
        <Fade left>
          <PresaleInfo title={"Public Sale has ended"} />
        </Fade>
        <Fade right>
          <div className={[s.token_sale__presale__timer, s.token_sale__presale__started].join(" ")}>
            <div className="startTimer">
              <h3>Ended On</h3>
              <Timer presaleTimer={true} saleTime={time} />
            </div>
            <div className="raised">
              <h3>Total raised</h3>
              <div>
                <h3>{ethers.utils.commify(totalRaised ?? DEFAULT_TOTAL_RAISED)}</h3>
                <span>USDC</span>
              </div>
            </div>
          </div>
        </Fade>
      </div>
      <Timeline4 />
    </>
  );
};

const TokenSaleTimeline5 = ({ time, start, end, totalRaised }) => {
  return (
    <>
      <div className={s.token_sale__presale}>
        <Fade left>
          <PresaleInfo title={"PRESALE HAS ENDED, PUBLIC SALE STARTING SOON!"} />
        </Fade>
        <Fade right>
          <div className={[s.token_sale__presale__timer, s.token_sale__presale__ended].join(" ")}>
            <h3>PUBLIC SALE</h3>
            <div className={s.token_sale__presale__timer__btns}>
              <button>
                <img src={calenderIcon} alt="icon" /> Add to Calendar
              </button>
              <button>
                <img src={telegramIcon} alt="icon" />
                Subscribe
              </button>
            </div>
            <div className="startTimer">
              <h3>Starts in</h3>
              <Timer presaleTimer={true} saleTime={time} />
            </div>
            <div className="raised">
              <h3>Total raised</h3>
              <div>
                <h3>{ethers.utils.commify(totalRaised ?? DEFAULT_TOTAL_RAISED)}</h3>
                <span>USDC</span>
              </div>
            </div>
            <div className="start_time">
              <h3>Starts</h3>
              <div>
                <p>{getDate(start)}</p>
              </div>
            </div>
            <div className="end_time">
              <h3>Ends</h3>
              <div>
                <p>{getDate(end)}</p>
              </div>
            </div>
          </div>
        </Fade>
      </div>
      <Fade bottom>
        <Timeline1 />
      </Fade>
    </>
  );
};

export const TokenSaleTimeline = () => {
  const [timelineState] = useRecoilState(contractState);

  // REPLACE THIS TOTAL RAISED WITH THE ONE FROM CONTRACT.
  //const totalRaised = 100000;
  const totalRaised = timelineState.presaleSold + timelineState.ibcoSold;
  console.log(timelineState);
  console.log(new Date(timelineState.presaleStart));
  const { presaleEnd, presaleStart, presaleSold, ibcoStart, ibcoEnd, presaleBlxSold } = timelineState;
  function getTimeline() {
    if (
      (Date.now() < START_TIME || Date.now() >= END_TIME) &&
      (!presaleStart || presaleStart > Date.now() || !presaleEnd)
    ) {
      return (
        <TokenSaleTimeline1
          time={presaleStart || START_TIME}
          start={presaleStart || START_TIME}
          end={presaleEnd || END_TIME}
        />
      );
    }
    if (
      (timelineState.presaleStart || START_TIME) < Date.now() &&
      Date.now() < (timelineState.presaleEnd || END_TIME) &&
      presaleBlxSold < PRESALE_AMOUNT
    ) {
      //      console.log('timeline2', presaleBlxSold, PRESALE_AMOUNT);
      return <TokenSaleTimeline2 time={timelineState.presaleEnd || END_TIME} totalRaised={totalRaised} />;
    }
    if (
      (timelineState.presaleEnd < Date.now() || presaleBlxSold >= PRESALE_AMOUNT) &&
      (Date.now() < ibcoStart || !ibcoStart)
    ) {
      //      console.log('timeline5', presaleBlxSold, PRESALE_AMOUNT);
      return (
        <TokenSaleTimeline5
          time={timelineState.ibcoStart || IBCO_START_TIME}
          start={timelineState.ibcoStart || IBCO_START_TIME}
          end={timelineState.ibcoEnd || IBCO_END_TIME}
          totalRaised={totalRaised}
        />
      );
    }
    if (timelineState.ibcoStart < Date.now() < timelineState.ibcoEnd) {
      console.log("timeline3", presaleBlxSold, PRESALE_AMOUNT);
      return <TokenSaleTimeline3 time={timelineState.ibcoEnd} totalRaised={totalRaised} />;
    }
    if (timelineState.ibcoEnd < Date.now()) {
      console.log("timeline4", presaleBlxSold, PRESALE_AMOUNT);
      return <TokenSaleTimeline4 time={timelineState.ibcoEnd} totalRaised={totalRaised} />;
    }
    console.log("timeline1", presaleBlxSold, PRESALE_AMOUNT);
    return <TokenSaleTimeline1 time={timelineState.presaleStart} />;
  }
  return (
    <section className={s.token_sale}>
      <div className="container">
        {getTimeline()}
        {/*For dynamic UI based on smart contract state, uncomment the line above && remove the line below*/}
        {/* <TokenSaleTimeline1 time={1677578400000} start={START_TIME} end={END_TIME} /> */}
        <Market />
      </div>
    </section>
  );
};
