import React, { useState, useEffect, useCallback } from "react";
import MessagePopup from "src/components/SuccessPopup/MessagePopup";
import s from "./Popup.module.scss";
import Close from "./../../assets/images/popup__close.svg";
import Button from "../UI/Button/Button";
import infoIcon from "../../assets/images/info__icon.svg";
import linkIcon from "../../assets/images/link__icon.svg";
import { createPortal } from "react-dom";
import useUser from "src/hooks/useUser";
import {
  enterPresale,
  enterIBCO,
  startPresale,
  startIBCO,
  setPresaleMinAmount,
  transferToDaoAgent,
  claimPresale,
  claimPresaleReward,
} from "src/contracts/transaction";
import useContract from "src/hooks/useContract";
import { getReferrer } from "src/utils/common";
import { Signer } from "ethers";
import { provider } from "src/config";

export const ReactPortal = ({ children }) => {
  return createPortal(children, document.getElementById("modal"));
};

export const Tooltip = ({ show, className }) => {
  const { user, setAccount } = useUser();
  const handleBtnClick = () => {};
  // useEffect(() => {
  //   magic.connect.getWalletInfo()
  //   .then(walletInfo => {
  //     const { walletType } = walletInfo;
  //     console.log('tracking wallet change', walletInfo);
  //     if (walletType === "metamask" && window.ethereum) {
  //       window.ethereum.on('accountsChanged', function (accounts) {
  //         console.log('metamask account change' , accounts);
  //         if (user.account !== accounts[0] && accounts[0] && bypassMagic) {
  //           setAccount(accounts[0]);
  //         }
  //       })
  //     }
  //   })
  //   .catch(err => {
  //     console.log('failed to get magic wallet info', err);
  //     if (window.ethereum) {
  //       window.ethereum.on('accountsChanged', function (accounts) {
  //         console.log('metamask account change' , accounts);
  //         if (user.account !== accounts[0] && accounts[0] && bypassMagic) {
  //           setAccount(accounts[0]);
  //         }
  //       })
  //     }
  //   })
  // },[user, setAccount]);

  return (
    <div className={[s.tooltip, show ? s.tooltip__show : "", className].join(" ")}>
      <div className={s.tooltip__header}></div>
      <div className={s.tooltip__body}>
        <p>LogIn to access features</p>
        <button onClick={handleBtnClick}>Login</button>
      </div>
    </div>
  );
};
const StageInfo = ({ stageNo, startDate, startTime, endDate, endTime, RemainingTime }) => {
  let days = Math.floor(RemainingTime / 86400);
  let hours = Math.floor((RemainingTime % 86400) / 3600);
  let minutes = Math.floor(((RemainingTime % 86400) % 3600) / 60);
  let seconds = Math.floor(((RemainingTime % 86400) % 3600) % 60);
  return (
    <div className={s.token_sale__body__stage}>
      <div className={s.token_sale__body__stage__name}>
        <h3>Stage {stageNo}</h3>
        <p>
          Stage {stageNo} BLX rewards will be unlocked on {startDate} {startTime}
        </p>
      </div>
      <div className={s.token_sale__body__stage__info}>
        <div>
          <p>Start Date</p>
          <h3>
            {startDate} <span>{startTime}</span>
          </h3>
        </div>
        <div>
          <p>End date</p>
          <h3>
            {endDate} <span>{endTime}</span>
          </h3>
        </div>
        <div>
          <p>Time remaining</p>
          <h3>{RemainingTime ? `${days}D / ${hours}H /  ${minutes}M / ${seconds}S` : "DD / HH / MM / SS"}</h3>
        </div>
      </div>
    </div>
  );
};

const Popup = ({ closePopup }) => {
  const [showTooltip, setShowTooltip] = useState([false, false, false]);
  const [showInfo, setShowInfo] = useState(false);
  const { user } = useUser();
  const { contracts: contractState } = useContract();
  const { contracts, myReferredBlx, myRewardBlx } = contractState;
  const network = contractState.deployment?.network;
  const [showPopup, setShowPopup] = useState(false);
  //message to show in popup
  const [popupMessage, setPopupMessage] = useState({
    // status: "success",
    // message: "CONGRATULATIONS YOUR PURCHASE WAS SUCCESSFUL",
  });
  const time = (new Date("2023-02-28T10:00:00Z") - Date.now()) / 1000;
  let [timer, setTimer] = useState(time);
  useEffect(() => {
    if (timer > 0) {
      setTimeout(() => {
        setTimer(timer - 1);
      }, 1000);
    }
  }, [timer]);

  useEffect(() => {
    console.log(contractState);
  }, [contractState]);

  const getReferralLink = useCallback(() => {
    return `https://optionblitz.co/?refId=${user.account}`;
  }, [user]);

  const claimBLX = (contracts) => {
    // startPresale(provider, signer, contracts)
    //   .then(result => {
    //     console.log(result);
    //   })
    //   .catch(err => {
    //     console.log(err);
    //   })
    // // only after presale meet softcap
    // startIBCO(provider, signer, contracts)
    //   .then(result => {
    //     console.log(result);
    //   })
    //   .catch(err => {
    //     console.log(err);
    //   })
    // setPresaleMinAmount(100, provider, signer, contracts)
    //   .then(result => {
    //     console.log(result);
    //   })
    //   .catch(err => {
    //     console.log(err);
    //   })
    //enterPresale({ amount: 9900, referrer }, provider, signer, contracts)
    // enterPresale({ amount: 100, referrer }, provider, signer, contracts)
    //   .then(result => {
    //     console.log(result);
    //   })
    //   .catch(err => {
    //     console.log(err);
    //   })
    // enterIBCO({ amount: 14477.00, referrer }, provider, signer, contracts)
    // .then(result => {
    //   console.log(result);
    // })
    // .catch(err => {
    //   console.log(err);
    // })
  };

  const handleHover = (e) => {
    if (!user.account) {
      const id = e.currentTarget.tabIndex;

      setShowTooltip((prev) =>
        prev.map((item, index) => {
          if (index === id) return !item;
          else return item;
        })
      );
    }
  };

  return (
    <ReactPortal>
      <div className={s.popup_container}>
        <div className={s.popup_container__bg}></div>
        <div className={s.popup}>
          <div className={s.popup__header}>
            <strong>Affiliate scheme</strong>
            <button className={s.popup__button} onClick={closePopup}>
              <img src={Close} alt="" />
            </button>
          </div>
          <div className={s.popup__body}>
            <div className={s.popup__body__info_block}>
              <img src={infoIcon} alt="info-icon" />
              <p>
                Copy your link and share it with your network to earn up to <span>10%</span> of the BLX sold as your
                reward!
              </p>
            </div>
            <p>
              Every time someone uses your link to purchase BLX from the token sale page, a unique ref ID is recorded on
              the blockchain. At the end of the token sale you will be able to visit this page and press the 'Claim BLX'
              button to transfer the reward to your connected wallet. BLX rewards are unlocked at both stages, Stage 1
              is the Presale and Stage 2 is the Public Sale.
            </p>
            <div className={s.popup__body__link_block}>
              <h3>Your Unique Link</h3>
              <div className={s.popup__body__link_block__wrapper}>
                <div>
                  <img src={linkIcon} alt="link-icon" />
                  {user.account ? <span>{getReferralLink()}</span> : <p>Not Logged In</p>}
                </div>
                <button
                  onClick={() => navigator.clipboard.writeText(getReferralLink())}
                  disabled={user.account ? false : true}
                  className={user.account && s.popup__body__link_block__wrapper__copy_btn}
                >
                  Copy Link
                </button>
              </div>
            </div>
            <div className={s.popup__body__login_details}>
              <div>
                <p>Wallet Connected</p>
                <h3
                  tabIndex={0}
                  onMouseEnter={(e) => {
                    handleHover(e);
                  }}
                  onMouseLeave={(e) => {
                    handleHover(e);
                  }}
                  className={user.account ? "user" : ""}
                >
                  {user.account ? user.account : "Not Logged In"}
                </h3>
                <Tooltip show={showTooltip[0]} />
              </div>
              <div>
                <p>Network</p>
                <h3
                  className={user.account ? "user" : ""}
                  tabIndex={1}
                  onMouseEnter={(e) => {
                    handleHover(e);
                  }}
                  onMouseLeave={(e) => {
                    handleHover(e);
                  }}
                >
                  {user.account ? "Ethereum" : "Not Logged In"}
                </h3>
                <Tooltip show={showTooltip[1]} />
              </div>
              <div>
                <p>BLX rewards earned</p>
                <h3
                  className={user.account ? "user" : ""}
                  tabIndex={2}
                  onMouseEnter={(e) => {
                    handleHover(e);
                  }}
                  onMouseLeave={(e) => {
                    handleHover(e);
                  }}
                >
                  {user.account ? `${myRewardBlx || 0} BLX` : "Not Logged In"}
                </h3>
                <Tooltip show={showTooltip[2]} />
              </div>
            </div>
            <div className={s.token_sale}>
              <div className={s.token_sale__header}>
                <h2>TOKEN SALE SCHEDULE</h2>
                <h3
                  onClick={() => {
                    setShowInfo(!showInfo);
                  }}
                >
                  {showInfo ? "Less Info" : "More Info"}
                </h3>
              </div>
              {showInfo && (
                <div className={s.token_sale__body}>
                  <StageInfo
                    stageNo={1}
                    startDate={"Feb 28, 2023"}
                    endDate={"Mar 28, 2023"}
                    startTime={"10:00"}
                    endTime={"10:00"}
                    RemainingTime={timer}
                  />
                  <StageInfo stageNo={2} startDate={"TBD"} endDate={"TBD"} startTime={""} endTime={""} />
                  <div className={s.popup__body__info_block}>
                    <img src={infoIcon} alt="info-icon" />
                    <p>
                      Click <a href="https://docs.optionblitz.co/">‘here’</a> to go to the help section to learn how
                      rewards are calculated.
                    </p>
                  </div>
                </div>
              )}
            </div>
            <Button className={s.popup__body__claim_btn} onClick={(evt) => claimBLX(contracts)}>
              Claim BLX
            </Button>
          </div>
        </div>
      </div>
      {showPopup && <MessagePopup closePopup={() => setShowPopup(false)} message={popupMessage} />}
    </ReactPortal>
  );
};

export default Popup;
