import React from "react";
import { useTranslation } from "react-i18next";
import s from "./Integration.module.scss";
import Fade from "react-reveal/Fade";
import abitriumLogo from "../../assets/images/integration/arbitrum.png";
import biconomyLogo from "../../assets/images/integration/biconomy-transparent.png";
import certikLogo from "../../assets/images/integration/certik-white.png";
import pythLogo from "../../assets/images/integration/pyth-prpl.png";

const IntegrationPartners = () => {
  const { t } = useTranslation();

  const integrationPartner = [
    {
      name: "Pyth",
      src: pythLogo,
    },
    {
      name: "Arbitrum",
      src: abitriumLogo,
    },
    {
      name: "Certik",
      src: certikLogo,
    },
    {
      src: biconomyLogo,
      name: "Biconomy",
    },
  ];

  return (
    <section className={s.integration_partners}>
      <Fade right>
        <div className="container">
          <h1>{t("Integration_partners.heading")}</h1>
          <div className={s.integration}>
            <p>{t("Integration_partners.paragraph")}</p>
            <div className={s.partnerContainer}>
              {integrationPartner.map((item) => (
                <img
                  src={item.src}
                  alt=""
                  style={{
                    filter: item.name === "Biconomy" ? "grayscale(100%) brightness(1.75)" : "grayscale(100%)",
                  }}
                />
              ))}
            </div>
          </div>
        </div>
      </Fade>
    </section>
  );
};

export default IntegrationPartners;
