import { Signer } from '@ethersproject/abstract-signer';
import { Provider } from '@ethersproject/providers';
import {
  Multicall2, Multicall2__factory,
  BlxPresale, BlxPresale__factory,
  IBCO, IBCO__factory,
  FiatTokenV2_1, FiatTokenV2_1__factory,
  BiconomyForwarder, BiconomyForwarder__factory,
  TokenSale, TokenSale__factory,
  TokenVesting, TokenVesting__factory,
  BlxToken, BlxToken__factory,
} from './typechain-types';

/** @internal */
export interface _OptionBlitzContracts {
  BlxPresale: BlxPresale;
  IBCO: IBCO;
  USDC: FiatTokenV2_1;
  Multicall2: Multicall2;
  Forwarder: BiconomyForwarder;
  TokenSale: TokenSale;
  TokenVesting: TokenVesting;
  BlxToken: BlxToken;
}

/** @internal */
export const _OptionBlitzContractFactory = {
  BlxPresale: BlxPresale__factory,
  IBCO: IBCO__factory,
  Multicall2: Multicall2__factory,
  USDC: FiatTokenV2_1__factory,
  Forwarder: BiconomyForwarder__factory,
  TokenSale: TokenSale__factory,
  TokenVesting: TokenVesting__factory,
  BlxToken: BlxToken__factory,
};
const factorysName = Object.keys(_OptionBlitzContractFactory);
const optionsName = Object.keys(_OptionBlitzContractFactory);

type OptionBlitzContractsKey = keyof _OptionBlitzContracts;
type KeyOfType<T, V> = keyof {
  [P in keyof T as T[P] extends V? P: never]: any
}

/** @internal */
export type _OptionBlitzContractAddresses = Record<OptionBlitzContractsKey, string>;

const mapOptionBlitzContracts = <T, U>(
  contracts: Record<OptionBlitzContractsKey, T>,
  f: (t: T, key: OptionBlitzContractsKey) => U,
) =>
  Object.fromEntries(
    Object.entries(contracts)
      .filter(([key, t]) => factorysName.includes(key))
      .map(([key, t]) => [key, f(t, key as OptionBlitzContractsKey)]),
  ) as Record<OptionBlitzContractsKey, U>;

/** @internal */
export interface _OptionBlitzDeploymentJSON {
  readonly chainId: number;
  readonly addresses: _OptionBlitzContractAddresses;
  readonly deploymentDate: number;
  readonly startBlock: number;
  readonly network: string;
}

/** @internal */
export const _connectToContracts = (
  signerOrProvider: Signer | Provider,
  { addresses }: _OptionBlitzDeploymentJSON,
): _OptionBlitzContracts => mapOptionBlitzContracts(
  addresses,
  (address, key) =>
    _OptionBlitzContractFactory[key].connect(address, signerOrProvider),
) as _OptionBlitzContracts;
