import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { RecoilRoot } from "recoil";
import { RecoilDevTools } from "recoil-gear";
import "./utils/index";
import MetaTags from "react-meta-tags";
// import { OptionBlitzProvider } from "./state/OptionBlitzProvider";
ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <RecoilRoot>
        <RecoilDevTools />
        <Suspense fallback="loading">
          <MetaTags>
            <meta property="og:image" content="%PUBLIC_URL%logo.png" />
          </MetaTags>
          {/* <OptionBlitzProvider> */}
          <App />
          {/* </OptionBlitzProvider> */}
        </Suspense>
      </RecoilRoot>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
