export const POLLING_INTERVAL = 20000;
export const ONE_HOUR = 1000 * 60 * 60;
export const EMAIL_REG_EXP = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
export const PRESALE_AMOUNT = 10000000;
export const GRAPH_INTERVAL = 10000;
export const NON_US_TEMPLATE_ID = 12;
export const US_TEMPLATE_ID = 11;
export const MONTHS = [
  "January",
  "Febuary",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
export const TIME_OPTIONS = { hour12: false, timeZone: "UTC", timeZoneName: "short" };
