import { BigNumber, utils } from "ethers";
import { _connectToContracts, _OptionBlitzContracts } from "./contracts";
import { BiconomyForwarder, FiatTokenV2_1__factory, BiconomyForwarder__factory } from "./typechain-types";
import { RSV } from "./metaSign";
//import { Biconomy } from "@biconomy/mexa";
import axios from "axios";
import { sendTransaction, biconomyForwarderAddresses } from "src/api/biconomy";
import { provider } from "src/config";

// const biconomy = new Biconomy((window as any).ethereum,{apiKey: "", debug: true, contractAddresses: []});
// const permitClient = biconomy.perm
const binconomyRpc = {
  //    baseUrl: "https://gasless-meta.prod.biconomy.io",
  baseUrl: "https://api.biconomy.io",
  metaData: "/api/v1/systemInfo/?networkId=1",
  submitV2: "/api/v2/meta-tx/native",
  submitV1: "/api/v1/native",
};

export interface Forwarder {
  sendEIP2612PermitRequest(
    tokenAddress: string,
    request: {
      message: { owner: string; spender: string; value: number; nonce: number; deadline: number };
      sig: RSV;
    },
    forMultiCall?: boolean
  ): Promise<any>;
  sendBiconomyERC20ForwardRequest(
    request: {
      message: BiconomyForwarder.ERC20ForwardRequestStruct;
      sig: RSV;
      func?: string;
      domainSeparator?: string;
      chainId?: number;
    },
    forMultiCall?: boolean
  ): Promise<any>;
}

export class NetForwarder implements Forwarder {
  readonly contracts: _OptionBlitzContracts;

  constructor(_contracts: _OptionBlitzContracts) {
    this.contracts = _contracts;
  }

  async sendEIP2612PermitRequest(
    tokenAddress: string,
    request: { message: { owner: string; spender: string; value: number; nonce: number; deadline: number }; sig: RSV },
    forMultiCall?: boolean
  ): Promise<any> {
    const { message, sig } = request;
    const USDC = FiatTokenV2_1__factory.connect(tokenAddress, provider);
    const { owner, spender, value, deadline } = message;
    if (forMultiCall) {
      const multicallStruct = {
        target: USDC.address,
        callData: (await USDC.populateTransaction.permit(owner, spender, value, deadline, sig.v, sig.r, sig.s)).data,
      };
      return { multicallStruct };
    } else {
      const permitTx = await USDC.permit(owner, spender, value, deadline, sig.v, sig.r, sig.s);
      const permitResult = await permitTx.wait();
      console.log(permitTx, permitResult, permitResult.gasUsed.toString());
      return { txHash: permitTx.hash };
    }
  }

  async sendBiconomyERC20ForwardRequest(
    request: {
      chainId?: number;
      func?: string;
      domainSeparator?: string;
      message: BiconomyForwarder.ERC20ForwardRequestStruct;
      sig: RSV;
    },
    forMultiCall?: boolean
  ) {
    const { message, sig, domainSeparator, func, chainId } = request;
    const forwarder = this.contracts.Forwarder.connect(provider);
    if (forMultiCall) {
      const multicallStruct = {
        target: forwarder.address,
        callData: (
          await forwarder.populateTransaction.executeEIP712(message, domainSeparator, utils.joinSignature(sig), {
            value: 0,
          })
        ).data,
      };
      return { multicallStruct };
    } else {
      const x = await forwarder.verifyEIP712(message, domainSeparator, utils.joinSignature(sig));
      const tx = await forwarder.executeEIP712(message, domainSeparator, utils.joinSignature(sig), { value: 0 });
      const txResult = await tx.wait();
      console.log(tx, txResult, txResult.gasUsed.toString());
      return { txHash: tx.hash };
    }
  }
}

export class Biconomy implements Forwarder {
  readonly contracts: _OptionBlitzContracts;

  constructor(_contracts: _OptionBlitzContracts) {
    this.contracts = _contracts;
  }

  async sendEIP2612PermitRequest(
    tokenAddress: string,
    request: { message: { owner: string; spender: string; value: number; nonce: number; deadline: number }; sig: RSV },
    forMultiCall?: boolean
  ): Promise<any> {
    const { message, sig } = request;
    const USDC = FiatTokenV2_1__factory.connect(tokenAddress, provider);
    const { owner, spender, value, deadline } = message;
    if (forMultiCall) {
      const multicallStruct = {
        target: USDC.address,
        callData: (await USDC.populateTransaction.permit(owner, spender, value, deadline, sig.v, sig.r, sig.s)).data,
      };
      return { multicallStruct };
    } else {
      const permitTx = await USDC.permit(owner, spender, value, deadline, sig.v, sig.r, sig.s);
      const permitResult = await permitTx.wait();
      console.log(permitTx, permitResult, permitResult.gasUsed.toString());
      return { txHash: permitTx.hash };
    }
  }

  async sendBiconomyERC20ForwardRequest(
    request: {
      chainId?: number;
      func?: string;
      domainSeparator?: string;
      message: BiconomyForwarder.ERC20ForwardRequestStruct;
      sig: RSV;
    },
    forMultiCall?: boolean
  ) {
    const { message, sig, domainSeparator, func, chainId } = request;
    const req = {};
    const forwarder = this.contracts.Forwarder.connect(provider);
    const forcedChainId = chainId;

    await Promise.all(
      Object.keys(message).map(async (k) => {
        const content = message[k];
        if (content.then) {
          message[k].then((v: any) => {
            req[k] = v;
          });
        } else req[k] = content;
        return content;
      })
    );

    if (forMultiCall) {
      const multicallStruct = {
        target: forwarder.address,
        callData: (
          await forwarder.populateTransaction.executeEIP712(message, domainSeparator, utils.joinSignature(sig), {
            value: 0,
          })
        ).data,
      };
      return { multicallStruct };
    } else {
      const from = await message.from;
      const forwarderAddress = biconomyForwarderAddresses[forcedChainId];
      //const x = await forwarder.attach(forwarderAddress).connect(getNetProvider(forcedChainId)).verifyEIP712(message, domainSeparator, utils.joinSignature(sig), { from } );
      //   const gasLimit = await forwarder
      //     .attach(forwarderAddress)
      //     .connect(getNetProvider(forcedChainId))
      //     .estimateGas.executeEIP712(message, domainSeparator, utils.joinSignature(sig), { from });
      // const tx = await forwarder.attach(forwarderAddress).connect(getNetSigner(getNetProvider(forcedChainId))).executeEIP712(message, domainSeparator, utils.joinSignature(sig), { value: 0 });
      // const txResult = await tx.wait();
      // console.log(tx, txResult, txResult.gasUsed.toString());
      // return { tx, txResult };
      const tx = await sendTransaction(
        chainId,
        func,
        from,
        req,
        domainSeparator,
        utils.joinSignature(sig),
        BigNumber.from(400000).toHexString(),
        {}
      );
      console.log(tx);
      return tx;
    }
  }
}

export const getForwarder = (chainId: number, contracts: _OptionBlitzContracts) => {
  if (chainId === 9413) return new NetForwarder(contracts);
  else return new Biconomy(contracts);
};
