import React from "react";
import s from "./Footer.module.scss";
import Logo from "./../../assets/images/logo.svg";
import { ReactComponent as Discord } from "./../../assets/images/discord.svg";
import { ReactComponent as Medium } from "./../../assets/images/medium.svg";
import { ReactComponent as LinkedIn } from "./../../assets/images/linkedin.svg";
import { ReactComponent as Youtube } from "./../../assets/images/yt.svg";
import { ReactComponent as Instagram } from "./../../assets/images/inst.svg";
import { ReactComponent as Telegram } from "./../../assets/images/tg.svg";
import { ReactComponent as Twitter } from "./../../assets/images/tw.svg";
import { ReactComponent as Reddit } from "./../../assets/images/reddit.svg";
import { ReactComponent as Mail } from "./../../assets/images/mail.svg";

import Fade from "react-reveal/Fade";

const Footer = () => {
  return (
    <footer className={s.footer}>
      <Fade bottom>
        <div className="container">
          <ul className={s.footer__menu}>
            <li>
              <a href="https://optionblitz.medium.com/">Blog</a>
            </li>
            <li>
              <a href="#">Legal</a>
            </li>
            <li>
              <a href="#">Privacy policy</a>
            </li>
          </ul>
          <a href="/" className={s.footer__logo}>
            <img src={Logo} alt="" />
          </a>
          <ul className={s.footer__media}>
            <li>
              <a href="https://discord.gg/D5Rk6fhh4n" target={"_blank"}>
                <Discord />
              </a>
            </li>
            <li>
              <a href="https://optionblitz.medium.com/" target={"_blank"}>
                <Medium />
              </a>
            </li>
            <li>
              <a href="https://twitter.com/optionblitz_co" target={"_blank"}>
                <Twitter />
              </a>
            </li>
            <li>
              <a href="https://www.reddit.com/r/OptionBlitz/" target={"_blank"}>
                <Reddit />
              </a>
            </li>
            <li>
              <a href="https://instagram.com/optionblitz" target={"_blank"}>
                <Instagram />
              </a>
            </li>

            <li>
              <a href="https://www.youtube.com/@optionblitz" target={"_blank"}>
                <Youtube />
              </a>
            </li>
            <li>
              <a href="https://t.me/planetblx" target={"_blank"}>
                <Telegram />
              </a>
            </li>
            <li>
              <a href="mailto:info@optionblitz.co">
                <Mail />
              </a>
            </li>
          </ul>
        </div>
      </Fade>
      <p className={s.footer__copyright}>© Option Blitz 2021</p>
    </footer>
  );
};

export default Footer;
