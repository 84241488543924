import React from "react";
import s from "./Roadmap.module.scss";
import Arrow from "./../../assets/images/arrow_green.svg";
import Check from "./../../assets/images/check.svg";
import Fade from "react-reveal/Fade";
import { useTranslation } from "react-i18next";
import file from "../../assets/file/optionblitz_whitepaper.pdf";
let roadmapData = [
  {
    date: "Jan 2024",
    tasks: [
      {
        text: "Finish beta testing",
        isCompleted: true,
      },
    ],
  },
  {
    date: "March 2024",
    tasks: [
      {
        text: "Launch OptionBlitz v3",
        isCompleted: true,
      },
    ],
  },
  {
    date: "May 2024",
    tasks: [
      {
        text: "Launch Perps trading",
        isCompleted: false,
      },
    ],
  },
  {
    date: "September 2024",
    tasks: [
      {
        text: "BLX Public Sale",
        isCompleted: false,
      },
    ],
  },
  {
    date: "October 2024",
    tasks: [
      {
        text: "Launch XXXX (In stealth-mode)",
        isCompleted: false,
      },
    ],
  },
];

const RoadmapItem = ({ date, tasks, className }) => {
  return (
    <div className={[s.roadmap__items_item, className].join(" ")}>
      <span className={s.roadmap__items_item__date}>{date}</span>
      <div className={s.roadmap__items_item__tasks}>
        {tasks.map((t, index) => (
          <div key={index}>
            <strong>{t.text}</strong>
            {t.isCompleted && <img src={Check} alt="" />}
          </div>
        ))}
      </div>
    </div>
  );
};

const Roadmap = () => {
  const { t } = useTranslation();
  return (
    <section className={s.roadmap} id="roadmap">
      <div className="container">
        <h2>{t("Roadmap.heading")}</h2>
        <Fade bottom>
          <div className={s.roadmap__info}>
            <div className={s.roadmap__info_item}>
              <p>{t("Roadmap.1")}</p>
              <a download href={file}>
                <strong>{t("Roadmap.read")}</strong>
                <img src={Arrow} alt="" />
              </a>
            </div>
            <div className={s.roadmap__info_item}>
              <p>{t("Roadmap.2")}</p>
              <a href="https://skynet.certik.com/projects/optionblitz">
                <strong>{t("Roadmap.audit")}</strong>
                <img src={Arrow} alt="" />
              </a>
            </div>
          </div>
        </Fade>
        <Fade bottom>
          <div className={s.roadmap__items}>
            {roadmapData.map((r, index) => (
              <RoadmapItem date={r.date} tasks={r.tasks} key={index} />
            ))}
          </div>
        </Fade>
      </div>
    </section>
  );
};

export default Roadmap;
