// structure of a tx.
// {
//     "amount": 10000,
//     "price": 0.1,
//     "blxPurchase": 100000,
//     "totalSold": 100000,
//   }

import { GRAPH_INTERVAL, PRESALE_AMOUNT } from "src/constants";

export const MOCK_TX = {
  amount: 15000000,
  price: 0.1,
  blxPurchase: 100000,
  totalSold: 10500,
};

function calculateTokenPrice(tokenSold) {
  if (tokenSold < PRESALE_AMOUNT) return 0.1;

  const t = Math.pow(tokenSold - PRESALE_AMOUNT, 1 / 3);
  const tokenPrice = 0.1 + 0.001 * t;
  return tokenPrice;
}

function calculateUsdcAmount(price, blxAmount) {
  return blxAmount * price;
}

export const breakTransactions = (totalBlxPurchased, interval = GRAPH_INTERVAL) => {
  let subTxCount = Math.floor(totalBlxPurchased / interval);

  let result = [];

  for (let i = 0; i < subTxCount; i++) {
    let subTx = {};

    let prevTotalSold = result[i - 1] ? result[i - 1].totalSold : 0;
    let price = calculateTokenPrice(prevTotalSold);

    subTx.blxPurchase = interval;
    subTx.totalSold = prevTotalSold + interval;
    subTx.price = price;
    subTx.amount = calculateUsdcAmount(price, interval);

    result.push(subTx);
  }

  if (totalBlxPurchased % interval !== 0) {
    let finalSubTx = {};
    let remainderBlxAmount = totalBlxPurchased % interval;

    let prevTotalSold = result[result.length - 1] ? result[result.length - 1].totalSold : 0;
    let price = calculateTokenPrice(prevTotalSold);

    finalSubTx.blxPurchase = remainderBlxAmount;
    finalSubTx.totalSold = prevTotalSold + remainderBlxAmount;
    finalSubTx.price = price;
    finalSubTx.amount = calculateUsdcAmount(price, remainderBlxAmount);

    result.push(finalSubTx);
  }
  return result;
};
