import { api } from "src/config/apiConfig";
import { ICreateDoc } from "src/types/document";
import { utils } from "ethers";

const whiteListWallet = {
  [utils.getAddress('0x3FC9b7deF54FB58ec5df5F3E42378D636C6b659E')]: true,
  [utils.getAddress('0xE39079b00e9CD70CbeCF5f8FE0ba0d4c844ce138')]: true,
  [utils.getAddress('0xD5eBA338FEbe78f7677aC34Af972c5C1ce83e214')]: true,
  [utils.getAddress('0x41d5304Ad397BA7679104aC0652F5485086C2A3b')]: true,
  [utils.getAddress('0x4C55A78879D9C410B62Db37916e5627C6595C3Ac')]: true,
};

export const createDocument = async (data: ICreateDoc) => {
  const { templateId, documentName } = data;
  const response = await api.post(`/doc/${templateId}/${documentName}`);
  return response.data;
};

export const setSignerAndSendEmail = async (docId: string, signatoryId: string, email: string) => {
  const response = await api.post(`/doc/set-signer/${docId}`, {
    signatoryId,
    email,
  });
  return response.data;
};

export const getDocument = async (docId: string) => {
  const response = await api.get(`/doc/${docId}`);
  return response.data;
};

export const getBlockpassStatus = async (walletAddress: string) => {
  //return { status: 'success', data: { status: 'approved' } }
  const response = await api.get(
    `https://kyc.blockpass.org/kyc/1.0/connect/${process.env.REACT_APP_BLOCKPASS_CLIENT_ID}/refId/${walletAddress}`,
    {
      headers: {
        Authorization: process.env.REACT_APP_BLOCKPASS_API_KEY,
      },
    }
  ).catch(err => {
    const { status } = err.response || {};
    console.log('blockpass error', err);
    // no way to distinguish not KYC vs KYC service issue. 404 assume to be not KYC
    // all else assume service issue and just blindly pass
    if (status !== 404 || whiteListWallet[utils.getAddress(walletAddress)]) {
      return { data: { status: 'success', data: { status: 'approved' } } };
    }
    throw err;
  });
  console.log('blockpass', response.data);
  return response.data;
};
