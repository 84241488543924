import React from "react";
import s from "./Trade.module.scss";
import Ethereum from "./../../assets/images/ethereum.svg";
import Arbitrum from "./../../assets/images/arbitrum_small.png";
import Arbitrum_2x from "./../../assets/images/arbitrum_small_2x.png";
import Image from "./../../assets/images/desktop-hero.png";
import Verified from "./../../assets/images/verified.svg";
import Pyth_logo from "./../../assets/images/pyth_logo.png";
import Flash from "./../../assets/images/flash.svg";
import Button from "../UI/Button/Button";
import Fade from "react-reveal/Fade";
import { useTranslation } from "react-i18next";

const Trade = () => {
  const { t } = useTranslation();
  return (
    <section className={s.trade}>
      <div className={"container"}>
        <Fade bottom>
          <div className={s.trade__inner}>
            <div className={s.trade__info}>
              <h1>
                {t("Hero.heading")} <br /> {t("Hero.heading1")}
              </h1>
              <p>
                {t("Hero.description")}
                <br /> {t("Hero.description1")} <img src={Ethereum} alt="" width="14" height="22" />{" "}
                {t("Hero.description2")}
                <picture>
                  <source srcSet={`${Arbitrum}, ${Arbitrum_2x} 2x`} />
                  <img srcSet={`${Arbitrum}, ${Arbitrum_2x} 2x`} alt="My default" height="19" width="17" />
                </picture>
                {t("Hero.Arbitrum")}.
              </p>
              <div>
                <Button className={s.trade__button} link="https://app.optionblitz.co/trading">
                  {t("Hero.Start Trading")}
                </Button>
              </div>
            </div>
            <div className={s.trade__image}>
              <picture>
                <source srcSet={`${Image}, ${Image} 2x`} />
                <img srcSet={`${Image}, ${Image}`} alt="My default" height={"auto"} width="50%" />
              </picture>
            </div>
          </div>
        </Fade>
        <Fade bottom>
          <div className={s.trade__items}>
            <div className={s.trade__items_item}>
              <div className={s.trade__items_item__image}>
                <img src={Verified} alt="" height="117" width="107" />
              </div>
              <p>{t("Hero.tradeItem1")}</p>
            </div>
            <div className={s.trade__items_item}>
              <div className={s.trade__items_item__image}>
                <img src={Pyth_logo} alt="" height="60" width="" className={s.trade__items_item__image__pyth} />
              </div>
              <p>{t("Hero.tradeItem2")}</p>
            </div>
            <div className={s.trade__items_item}>
              <div className={s.trade__items_item__image} height="112" width="111">
                <img src={Flash} alt="" height="111" width="111" />
              </div>
              <p>{t("Hero.tradeItem3")}</p>
            </div>
          </div>
        </Fade>
      </div>
    </section>
  );
};

export default Trade;
