import React, { useState } from "react";
import s from "./FAQ.module.scss";
import Fade from "react-reveal/Fade";
import { useTranslation } from "react-i18next";
function urlify(text) {
  var urlRegex = /(https?:\/\/[^\s]+)/g;

  return text.replace(urlRegex, function (url) {
    return '<a href="' + url + '">' + url + "</a>";
  });
}
const FAQItem = ({ question, answer }) => {
  const { t } = useTranslation();
  let [isActive, setIsActive] = useState(false);

  let toggleIsActive = () => {
    setIsActive(!isActive);
  };

  return (
    <div
      className={[s.faq__items_item, isActive && s.faq__items_item_active].join(" ")}
      onClick={() => {
        toggleIsActive();
      }}
    >
      <strong>{t(`FAQ.${question}`)}</strong>
      <div>
        <p dangerouslySetInnerHTML={{ __html: urlify(t(`FAQ.${answer}`)) }} />
      </div>
    </div>
  );
};

const FAQ = ({ faqItems }) => {
  return (
    <section className={s.faq} id="faq">
      <div className="container">
        <h2>FAQ</h2>
        <Fade bottom>
          <div className={s.faq__items}>
            {faqItems.map((q, index) => (
              <FAQItem question={q.question} answer={q.answer} link={q.link} key={index} />
            ))}
          </div>
        </Fade>
      </div>
    </section>
  );
};

export default FAQ;
