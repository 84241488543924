/* eslint-disable operator-assignment */
/* eslint-disable object-shorthand */
/* eslint-disable @typescript-eslint/quotes */
import axios from "axios";
import { priceFeed } from "./OptionBlitzPricefeed";

export const symbols: Record<string, any> = {
  // Forex
  EUR_USD: {
    bid: 0.995,
    ask: 1.005,
    price: 1.0,
    multiplier: 100000,
    // binance: 'EURUSDT',
    market: "forex",
    // tw: 'FX:EURUSD',
    pythId: "0xa995d00bb36a63cef7fd2c287dc105fc8f3d93779f062f09551b0af3e81ec30b",
    pythTestNetId: "0xc1b12769f6633798d45adfd62bfc70114839232e2949b01fb3d3f927d2606154",
    pythSymbol: "FX.EUR/USD",
    decimals: 6,
  },
  GBP_USD: {
    bid: 1.135,
    ask: 1.145,
    price: 1.14,
    multiplier: 100000,
    // binance: 'GBPUSDT',
    // tw: 'FX:GBPUSD',
    market: "forex",
    pythId: "0x84c2dde9633d93d1bcad84e7dc41c9d56578b7ec52fabedc1f335d673df0a7c1",
    pythTestNetId: "0xbcbdc2755bd74a2065f9d3283c2b8acbd898e473bdb90a6764b3dbd467c56ecd",
    pythSymbol: "FX.GBP/USD",
    decimals: 5,
  },
  AUD_USD: {
    bid: 0.6805,
    ask: 0.6905,
    price: 0.685,
    multiplier: 100000,
    // binance: 'AUDUSDT',
    // tw: 'FX:AUDUSD',
    market: "forex",
    pythId: "0x67a6f93030420c1c9e3fe37c1ab6b77966af82f995944a9fefce357a22854a80",
    pythTestNetId: "0x2646ca1e1186fd2bb48b2ab3effa841d233b7e904b2caebb19c8030784a89c97",
    pythSymbol: "FX.AUD/USD",
    decimals: 6,
  },
  NZD_USD: {
    bid: 0.6805,
    ask: 0.6905,
    price: 0.685,
    multiplier: 100000,
    // binance: 'NZDUSDT',
    // tw: 'FX:NZDUSD',
    market: "forex",
    pythId: "0x92eea8ba1b00078cdc2ef6f64f091f262e8c7d0576ee4677572f314ebfafa4c7",
    pythTestNetId: "0xd71fc5838e683d30b49539d979af53063250a76cfab4a4ecfa2b3279f26b7b29",
    pythSymbol: "FX.NZD/USD",
    decimals: 6,
  },
  USD_CAD: {
    pythSymbol: "FX.USD/CAD",
  },
  USD_CHF: {
    pythSymbol: "FX.USD/CHF",
  },
  USD_CNH: {
    pythSymbol: "FX.USD/CNH",
  },
  USD_JPY: {
    pythSymbol: "FX.USD/JPY",
  },

  USD_BRL: {
    pythSymbol: "FX.USD/BRL",
  },
  USD_HKD: {
    pythSymbol: "FX.USD/HKD",
  },
  USD_MXN: {
    pythSymbol: "FX.USD/MXN",
  },
  USD_NOK: {
    pythSymbol: "FX.USD/NOK",
  },
  USD_SEK: {
    pythSymbol: "FX.USD/SEK",
  },
  USD_SGD: {
    pythSymbol: "FX.USD/SGD",
  },
  USD_ZAR: {
    pythSymbol: "FX.USD/ZAR",
  },

  // Metals
  XAU_USD: {
    bid: 0.995,
    ask: 1.005,
    price: 1.0,
    multiplier: 100000,
    market: "forex",
    pythId: "0x765d2ba906dbc32ca17cc11f5310a89e9ee1f6420508c63861f2f8ba4ee34bb2",
    pythTestNetId: "0x30a19158f5a54c0adf8fb7560627343f22a1bc852b89d56be1accdc5dbf96d0e",
    tw: "FX:XAUUSD",
    pythSymbol: "Metal.XAU/USD",
    decimals: 2,
  },
  XAG_USD: {
    bid: 0.995,
    ask: 1.005,
    price: 1.0,
    multiplier: 100000,
    market: "forex",
    pythId: "0xf2fb02c32b055c805e7238d628e5e9dadef274376114eb1f012337cabe93871e",
    pythTestNetId: "0x321ba4d608fa75ba76d6d73daa715abcbdeb9dba02257f05a1b59178b49f599b",
    tw: "FX:XAGUSD",
    pythSymbol: "Metal.XAG/USD",
    decimals: 4,
  },

  // Crypto
  BTC_USD: {
    bid: 20000.12,
    ask: 20000.34,
    price: 20000.23,
    multiplier: 1,
    market: "crypto",
    // binance: 'BTCUSDT',
    // tw: 'BITSTAMP:BTCUSD',
    pythId: "0xe62df6c8b4a85fe1a67db44dc12de5db330f7ac66b72dc658afedf0f4a415b43",
    pythTestNetId: "0xf9c0172ba10dfa4d19088d94f5bf61d3b54d5bd7483a322a982e1373ee8ea31b",
    pythSymbol: "Crypto.BTC/USD",
    decimals: 2,
  },
  ETH_USD: {
    bid: 1305.5,
    ask: 1315.5,
    price: 1310.5,
    multiplier: 1,
    market: "crypto",
    // binance: 'ETHUSDT',
    // tw: 'BITSTAMP:ETHUSD',
    pythId: "0xff61491a931112ddf1bd8147cd1b641375f79f5825126d665480874634fd0ace",
    pythTestNetId: "0xca80ba6dc32e08d06f1aa886011eed1d77c77be9eb761cc10d72b7d0a2fd57a6",
    pythSymbol: "Crypto.ETH/USD",
    decimals: 2,
  },
  XRP_USD: {
    pythSymbol: "Crypto.XRP/USD",
  },
  BNB_USD: {
    pythSymbol: "Crypto.BNB/USD",
  },
  DOGE_USD: {
    pythSymbol: "Crypto.DOGE/USD",
  },
  LTC_USD: {
    pythSymbol: "Crypto.LTC/USD",
  },
  SOL_USD: {
    pythSymbol: "Crypto.SOL/USD",
  },
  AVAX_USD: {
    pythSymbol: "Crypto.AVAX/USD",
  },
  LINK_USD: {
    pythSymbol: "Crypto.LINK/USD",
  },
  ADA_USD: {
    pythSymbol: "Crypto.ADA/USD",
  },
  MATIC_USD: {
    pythSymbol: "Crypto.MATIC/USD",
  },
  DOT_USD: {
    pythSymbol: "Crypto.DOT/USD",
  },
  SHIB_USD: {
    pythSymbol: "Crypto.SHIB/USD",
  },
  PEPE_USD: {
    pythSymbol: "Crypto.PEPE/USD",
  },

  // Equity
  AAPL_USD: {
    pythSymbol: "Equity.US.AAPL/USD",
  },
  AMZN_USD: {
    pythSymbol: "Equity.US.AMZN/USD",
  },
  GOOG_USD: {
    pythSymbol: "Equity.US.GOOG/USD",
  },
  IBM_USD: {
    pythSymbol: "Equity.US.IBM/USD",
  },
  INTC_USD: {
    pythSymbol: "Equity.US.INTC/USD",
  },
  META_USD: {
    pythSymbol: "Equity.US.META/USD",
  },
  NVDA_USD: {
    pythSymbol: "Equity.US.NVDA/USD",
  },
  MSFT_USD: {
    pythSymbol: "Equity.US.MSFT/USD",
  },
  TSLA_USD: {
    pythSymbol: "Equity.US.TSLA/USD",
  },
};

export const getPythCandles = async (symbol: string, period: string = "1H", dayRange: number = 1) => {
  const toTimeStamp = Math.floor(Date.now() / 1000);
  const fromTimeStamp = toTimeStamp - 60 * 60 * 24 * dayRange;
  const url =
    "https://benchmarks.pyth.network/v1/shims/tradingview/history?symbol=" +
    encodeURIComponent(symbols[symbol].pythSymbol) +
    "&resolution=" +
    period +
    "&from=" +
    fromTimeStamp +
    "&to=" +
    toTimeStamp;

  const cacheExpires = ({ "1M": 3600, "1D": 3600, "1H": 0 }[period] || 0) * 1000;
  // eslint-disable-next-line
  const cacheKey = symbols[symbol].pythSymbol + "_" + period;

  let obCandles;
  const cacheJSON = window.localStorage.getItem(cacheKey);
  if (cacheJSON) {
    const cache = JSON.parse(cacheJSON);
    if (cache.expires > Date.now()) {
      obCandles = cache.candles;
    }
  }

  if (!obCandles) {
    const candles = (await axios({
      url,
      method: "GET",
      data: {},
      headers: {
        "content-type": "application/x-www-form-urlencoded",
        Accept: "application/json",
      },
    })) as any;

    if (!candles.data) {
      return [];
    }

    obCandles = [];
    for (let index = 0; index < candles.data.o.length; index++) {
      const c = candles.data;
      obCandles.push({
        candle: {
          O: c.o[index],
          C: c.c[index],
          H: c.h[index],
          L: c.l[index],
          T: c.t[index],
          V: 0,
        },
        date: new Date(c.t[index]),
        rate: parseFloat(c.c[index]),
      });
    }

    // fix the timestamps to bring the datapoints closer to the current timestamp
    if (period === "1H" && obCandles.length) {
      const last = obCandles[obCandles.length - 1].date.getTime();
      const lastOffset = Date.now() - last;

      if (lastOffset < 60 * 1000) {
        obCandles = obCandles.map((c: any) => {
          const d = new Date(c.date.getTime() + lastOffset);
          c.date = d;
          c.candle.T = Math.floor(d.getTime() / 1000);

          return c;
        });
      }
    }

    if (cacheExpires > 0) {
      window.localStorage.setItem(cacheKey, JSON.stringify({ expires: Date.now() + cacheExpires, candles: obCandles }));
    }
  }

  if (obCandles.length) {
    priceFeed.updateBackupPrice(symbol, obCandles[obCandles.length - 1].candle.C);

    if (period === "1M") {
      const idx = Math.max(0, obCandles.length - 24);
      priceFeed.update24HPrice(symbol, obCandles[idx].candle.C);
    }
  }
  return { obCandles, oldPrice: obCandles[0], newPrice: obCandles[obCandles.length - 1] };
};
