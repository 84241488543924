import data from "../assets/file/data.json";
import file from "src/assets/file/optionblitz_whitepaper.pdf";

export const items = [
  { title: "Ticker", value: "Blx" },
  { title: "Ico stages", value: "2" },
  { title: "Initial Total Tokens", value: "100,000,000 BLX" },
  { title: "Cumulative number of tokens available", value: "40,000,000 BLX" },
  { title: "Accepted currencies:", value: "USDC" },
  { title: "Cumulative cap:", value: "10,600,000 USDC" },
  { title: "Jurisdiction:", value: "Saint Vincent & Grenadines" },
  { title: "Founder vesting:", value: "yes" },
  { title: "Eligibility", value: "Subject to KYC and AML" },
  { title: "Token type:", value: "Deflationary (buybacks)" },
  { title: "Token purchase contract:", value: "SAFT" },
  { title: "US INVESTORS ALLOWED", value: "Yes, under SEC Reg. S and Reg. D" },
];

export const stage1Items = [
  { title: "Fixed token price", value: "0.1 USDC" },
  { title: "Remaining tokens post-presale:", value: "Burned" },
  { title: "Softcap", value: "200,000 USDC" },
  { title: "Pre-sale token allocation:", value: "10%" },
  { title: "Hardcap:", value: "1,000,000 USDC" },
  { title: "Pre-sale tokens", value: "10,000,000 BLX" },
  { title: "Stage start:", value: "28th Feb 2023" },
  { title: "Stage end:", value: "28th Mar 2023" },
];
export const stage2Items = [
  { title: "Initial token price", value: "0.1 USDC" },
  { title: "Final token price", value: "0.39 USDC" },
  { title: "Softcap", value: "1,000,000 USDC" },
  { title: "Hardcap", value: "9,600,000 USDC" },
  { title: "IBCO Tokens", value: "39,000,000 BLX" },
  { title: "Remaining tokens post IBCO", value: "Burned" },
  { title: "Stage start:", value: "TBD" },
  { title: "Stage end:", value: "TBD" },
];

export const chartDataInit = data;

export const LandingFaqItems = [
  {
    question: "What is OptionBlitz?",
    answer:
      "OptionBlitz is a decentralised trading platform built on the blockchain. You can buy different types of options including binary options, barrier options, American options, European Options and Turbos. OptionBlitz also supports liquidity staking where clients can invest funds in exchange for a revenue share which is generated from fees collected from traders.",
  },
  {
    question: "What is the blockchain and how does it work with OptionBlitz?",
    answer:
      "The blockchain is a network of computers which allows data to transfer strictly under certain rules including consensus. OptionBlitz trading protocol is code on the blockchain and the network enforces the rules to make sure transactions are immutable and secure. This protects clients from fraud or manipulation since it is not possible to modify the transaction, or the conditions for its execution without the network failing to reach consensus and therefore rejecting the request.",
  },
  {
    question: "How do I login or connect if there is no registration?",
    answer:
      "When you click login you have the choice to connect with your dapp wallet such as Metamask or 1-click login with social authentication like Google. When you do this a wallet is created inside your web browser which allows you to load crypto such as USDC and then",
  },
  {
    question: "Is there any KYC?",
    answer: "No",
  },
  {
    question: "Are US traders allowed?",
    answer: "Yes",
  },
  {
    question: "How do I make a deposit?",
    answer:
      "There are no deposits! All you need to do is either transfer some USDC into your wallet or buy USDC from the built-in payment gateway. Bank Transfer, debit/credit cards are accepted from over 140 countries. Your funds are always in your possession and we never have access to any private keys",
  },
  {
    question: "How do I learn how to trade on OptionBlitz?",
    answer: "Visit the trading academy to get started.",
  },
];
export const TokenSaleFaqItems = [
  {
    question: "How do I find out more information about the token sale?",
    answer: `Download the whitepaper by clicking this <a download href=${file}>link</a> and navigate to the token sale financials section. 
      Check out the help section for a deep dive into OptionBlitz token financials and incentive models.`,
  },
  {
    question: "Why is there a Presale and an IBCO?",

    answer:
      "OptionBlitz needs two funding stages. Stage 1 (Presale) is required to fund security audits of the code and prepare marketing & operations for launch. In stage 2 (IBCO/Public sale), the trading protocol needs funds to bootstrap liquidity which will enable trading and fund the aggressive marketing strategies required to make OptionBlitz thrive.",
  },
  {
    question: "What is an IBCO?",
    answer:
      "IBCO stands for Initial Bonding Curve Offering. This is the public sale (stage 2) part of the token sale. An IBCO is a fundraising model where instead of a fixed price per token, the price scales upwards according to a formula as investment increases. This new fundraising model solves many of the concerns and issues that traditional ICOs faced such as pump and dump scams and lack of secondary market trading. The OptionBlitz IBCO is designed to incentivise early investors by giving them a discount compared to later ones. The code also has a function which automatically deploys a secondary market on Uniswap so investors know they will be able to exit their position after the sale ends.",
  },
  {
    question: "What is Uniswap?",
    answer:
      "https://app.uniswap.org/ is DEX where anyone can trade crypto pairs freely. Anyone can list their own tokens there and provide the collateral for the pair. This is what OptionBlitz will do at the end of the token sale by initiating the ‘market transition’ function inside the token sale code. BLX will list at around 0.39 USDC/BLX subject to the maximum funding target being reached.",
  },
  {
    question: "How will I get my BLX tokens?",
    answer:
      "You have to wait for the token sale to end. Then the button will change to CLAIM BLX. Once you press that, the BLX tokens will transfer to your wallet. This is to make sure you can get a refund if the minimum fundraise target is not met. In the unlikely event that happens, the button will show CLAIM USDC at the end of the token sale instead, and allow you to transfer the USDC you provided back to your wallet.",
  },
  {
    question: "Is there a vesting schedule?",

    answer:
      "Yes! Team tokens (25% of total supply) are vested for 4 years, with 25% unlocked annually until 100% are unlocked in year 4.",
  },
  {
    question: "I am a US investor, can I invest?",
    answer: "Yes, under SEC Regulation S and Regulation D.",
  },
  {
    question: "What KYC documents are required?",
    answer: "Any of the following is accepted: Passport, Drivers licence, National Identity card.",
  },
  {
    question: "How long does KYC take to confirm?",
    answer: "Usually within minutes",
  },
  {
    question: "What’s the best way to contact you if I am having technical difficulties?",
    answer:
      "Book a call by clicking the schedule a call with the founders link above or email us at info@optionblitz.co",
  },
  {
    question: "What blockchain is OptionBlitz on?",
    answer: "The token sale is on Ethereum, the DAO is on Ethereum and the trading protocol is on Arbitrum.",
  },
  {
    question: "When will the trading protocol launch?",
    answer: "As soon as the IBCO (stage 2) ends, the trading protocol will deploy and launch instantly",
  },
  {
    question: "What exchanges will BLX trade on?",
    answer:
      "BLX will list on Uniswap instantly at the end of the IBCO (stage 2) and later when the community grows and demand for BLX rises, OptionBlitz will lobby Tier 1 and Tier 2 exchanges to list BLX. The BLX tokens for these listings will come from the reserves in the DAO treasury and only be released after consensus from governance proposals.",
  },
  {
    question: "How do the affiliate links work?",
    answer: `Your affiliate <a href='docs.optionblitz.co'>link</a> is unique to you and any visitor that accesses the token sale page from your link will be tagged as your referral. You will earn up to 10% of the BLX they purchase as a reward which you will be able to claim when the token sale ends, for more details visit this link.`,
  },
];

export const activityTableData = [
  { date: "08/01/2019", address: "0x921294ff08E1...D078322551a", amount: "934.65", view: "#" },
  { date: "19/03/2022", address: "0x211294ff08E1...D078322551a", amount: "217.11", view: "#" },
  { date: "21/04/2021", address: "0x621294ff08E1...D078322551a", amount: "389.42", view: "#" },
  { date: "12/05/2021", address: "0x821294ff08E1...D078322551a", amount: "647.37", view: "#" },
  { date: "11/07/2022", address: "0x421294ff08E1...D078322551a", amount: "114.454", view: "#" },
];
