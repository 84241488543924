// @ts-nocheck
import React, { useEffect, useState } from "react";
import s from "./Header.module.scss";
import Language from "./Language/Language";
import Logo from "./../../assets/images/logo.svg";
import { useLocation } from "react-router-dom";
import file from "../../assets/file/optionblitz_whitepaper.pdf";
import { useTranslation } from "react-i18next";
import useUser from "../../hooks/useUser";

const Header = () => {
  const { t } = useTranslation();
  const { user, setAccount, disconnect } = useUser();
  let menuItems = [
    { href: "#", title: t("NavItems.About") },
    { href: "/#markets", title: t("NavItems.Markets") },
    { href: "/#staking", title: t("NavItems.Staking") },
    { href: "https://refer.optionblitz.co", title: t("NavItems.Referrals") },
    { href: "/presale", title: t("NavItems.BLX token sale") },
    { href: "/trading-products", title: t("NavItems.Trading Products") },
    { href: "/#roadmap", title: t("NavItems.Roadmap") },
    { href: "/#faq", title: t("NavItems.FAQ") },
    { href: "https://optionblitz.medium.com/", title: t("NavItems.Blog") },
    { href: file, title: t("NavItems.Whitepaper") },
    { href: "https://docs.optionblitz.co", title: t("NavItems.Help") },
  ];

  let [isActive, setIsActive] = useState(false);

  let [isScrolled, setIsScrolled] = useState(false);

  let toggleMenu = () => {
    setIsActive(!isActive);
  };

  let handleClickMenu = () => {
    if (isActive) {
      toggleMenu();
    }
  };

  const handleBtnClick = () => {};

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    });
  }, []);

  const { pathname } = useLocation();

  return (
    <header className={[s.header, isScrolled && s.header_scrolled, isActive && s.header_active].join(" ")}>
      <button
        className={[s.header__burger, isActive && s.header__burger_active].join(" ")}
        onClick={() => {
          toggleMenu();
        }}
      >
        <span></span>
        <span></span>
        <span></span>
      </button>
      <img src={Logo} alt="" className={s.header__logo} height="43" width="137" />
      <ul className={[s.header__menu, isActive && s.header__menu_active].join(" ")}>
        {menuItems.map((m, index) => (
          <li key={index}>
            <a
              download={m.title === "Whitepaper" ? true : false}
              href={m.href}
              onClick={() => {
                handleClickMenu();
              }}
            >
              {m.title === "Whitepaper" && (
                <svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 24 24">
                  <path d="M17.71,11.29a1,1,0,0,0-1.42,0L13,14.59V7a1,1,0,0,0-2,0v7.59l-3.29-3.3a1,1,0,0,0-1.42,1.42l5,5a1,1,0,0,0,.33.21.94.94,0,0,0,.76,0,1,1,0,0,0,.33-.21l5-5A1,1,0,0,0,17.71,11.29Z" />
                </svg>
              )}
              {m.title}
            </a>
          </li>
        ))}
      </ul>
      <div className={[s.header__group, isActive && s.header__group_hidden].join(" ")}>
        <Language />
        {user.displayAccount ? (
          <div className={s.header__logged_in_button}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M3.75 6V18C3.75 18.3978 3.90804 18.7794 4.18934 19.0607C4.47064 19.342 4.85218 19.5 5.25 19.5H20.25C20.4489 19.5 20.6397 19.421 20.7803 19.2803C20.921 19.1397 21 18.9489 21 18.75V8.25C21 8.05109 20.921 7.86032 20.7803 7.71967C20.6397 7.57902 20.4489 7.5 20.25 7.5H5.25C4.85218 7.5 4.47064 7.34196 4.18934 7.06066C3.90804 6.77936 3.75 6.39782 3.75 6ZM3.75 6C3.75 5.60218 3.90804 5.22064 4.18934 4.93934C4.47064 4.65804 4.85218 4.5 5.25 4.5H18"
                stroke="white"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M16.875 14.625C17.4963 14.625 18 14.1213 18 13.5C18 12.8787 17.4963 12.375 16.875 12.375C16.2537 12.375 15.75 12.8787 15.75 13.5C15.75 14.1213 16.2537 14.625 16.875 14.625Z"
                fill="white"
              />
            </svg>

            <h3>{user.displayAccount}</h3>
          </div>
        ) : (
          pathname !== "/" && (
            <button className={s.header__button} onClick={handleBtnClick}>
              Login
            </button>
          )
        )}
      </div>
    </header>
  );
};

export default Header;
