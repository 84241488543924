import React, { useEffect, useState } from "react";
import s from "./Tabs.module.scss";
import Arrow from "./../../../../assets/images/tabs__arrow.svg";
import { MARKET_TABLE_TABS } from "../../Markets";

const Tab = ({ title, isActive, onClick, withArrow }) => {
  return (
    <button className={[s.tabs__item, isActive && s.tabs__item_active].join(" ")} onClick={onClick}>
      {title}
      {withArrow && <img src={Arrow} alt="" />}
    </button>
  );
};

const Tabs = ({ activeTab, setActiveTab }) => {
  let [isAdaptive, setIsAdaptive] = useState(window.screen.width <= 768);
  let [isMobileDropDownActive, setIsMobileDropDownActive] = useState(false);

  // utility fn
  function getKeyByValue(object, value) {
    return Object.keys(object).find((key) => object[key] === value);
  }

  let toggleMobileDropDown = () => {
    setIsMobileDropDownActive(!isMobileDropDownActive);
  };

  useEffect(() => {
    window.addEventListener(
      "resize",
      function (event) {
        setIsAdaptive(window.screen.width <= 768);
      },
      true
    );
  }, []);

  // mobile view.
  if (isAdaptive) {
    return (
      <div className={[s.tabs_adaptive, isMobileDropDownActive && s.tabs_active].join(" ")}>
        <Tab
          title={getKeyByValue(MARKET_TABLE_TABS, activeTab)}
          isActive={true}
          withArrow={true}
          onClick={() => {
            toggleMobileDropDown();
          }}
        />
        {isMobileDropDownActive && (
          <div className={s.tabs_adaptive__items}>
            {/* Rendering all but selected tab. */}
            {Object.keys(MARKET_TABLE_TABS)
              .filter((key) => {
                return key !== getKeyByValue(MARKET_TABLE_TABS, activeTab);
              })
              .map((key, i) => (
                <Tab
                  title={key}
                  isActive={false}
                  onClick={() => {
                    setActiveTab(MARKET_TABLE_TABS[key]);
                    toggleMobileDropDown();
                  }}
                  key={i}
                />
              ))}
          </div>
        )}
      </div>
    );
  }

  return (
    <div className={s.tabs}>
      <Tab
        title={"Forex"}
        isActive={activeTab === MARKET_TABLE_TABS.FOREX}
        onClick={() => {
          setActiveTab(MARKET_TABLE_TABS.FOREX);
        }}
      />
      <Tab
        title={"Crypto"}
        isActive={activeTab === MARKET_TABLE_TABS.CRYPTO}
        onClick={() => {
          setActiveTab(MARKET_TABLE_TABS.CRYPTO);
        }}
      />
      <Tab
        title={"Metals"}
        isActive={activeTab === MARKET_TABLE_TABS.METALS}
        onClick={() => {
          setActiveTab(MARKET_TABLE_TABS.METALS);
        }}
      />
      <Tab
        title={"Equities"}
        isActive={activeTab === MARKET_TABLE_TABS.EQUITIES}
        onClick={() => {
          setActiveTab(MARKET_TABLE_TABS.EQUITIES);
        }}
      />
    </div>
  );
};

export default Tabs;
