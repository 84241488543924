import React from "react";
import s from "./Transactions.module.scss";
import "swiper/css";
import "swiper/css/pagination";
import Mining from "./../../assets/images/mining.svg";
import Spread from "./../../assets/images/spread.svg";
import Lock from "./../../assets/images/lock.svg";
import Stacking from "./../../assets/images/stacking.svg";
import MiningBg from "./../../assets/images/mining_bg.svg";
import SpreadBg from "./../../assets/images/spread_bg.svg";
import LockBg from "./../../assets/images/lock_bg.svg";
import StackingBg from "./../../assets/images/stacking_bg.svg";
import Comoros from "../../assets/images/comoros.png";
import Denmark from "../../assets/images/denmark.png";
import Ecuador from "../../assets/images/ecuador.png";
import Guinea from "../../assets/images/guinea.png";
import FaroeIslands from "../../assets/images/faroe-islands.png";
import Germany from "../../assets/images/germany.png";
import Greece from "../../assets/images/greece.png";
import Fade from "react-reveal/Fade";
import { useTranslation } from "react-i18next";
// SwiperCore.use([Pagination]);

const Transactions = () => {
  const { t } = useTranslation();
  let features = [
    {
      icon: Mining,
      title: t("Features.feature1.heading"),
      text: t("Features.feature1.description"),
      colors: ["#24C088", "#209C6F", "#23926F"],
      bg: MiningBg,
    },
    {
      icon: Spread,
      title: t("Features.feature2.heading"),
      text: t("Features.feature2.description"),
      colors: ["#ffffff4d", "#A82D59", "#7A20BF"],
      bg: SpreadBg,
    },
    {
      icon: Lock,
      title: t("Features.feature3.heading"),
      text: t("Features.feature3.description"),
      colors: ["#DD941D", "#E0B005", "#ED8903"],
      bg: LockBg,
      class: "secure",
    },
    {
      icon: Stacking,
      title: t("Features.feature4.heading"),
      text: t("Features.feature4.description"),
      colors: ["#0C5F9B66", "#16A2C0", "#055D9D"],
      bg: StackingBg,
    },
  ];

  let whyItems = [
    {
      title: t("Transactions Why Items.item1.heading"),
      text: t("Transactions Why Items.item1.description"),
    },
    {
      title: t("Transactions Why Items.item2.heading"),
      text: t("Transactions Why Items.item2.description"),
    },
    {
      title: t("Transactions Why Items.item3.heading"),
      text: t("Transactions Why Items.item3.description"),
      flags: [Comoros, Denmark, Ecuador, Guinea, FaroeIslands, Germany, Greece],
    },
    {
      title: t("Transactions Why Items.item4.heading"),
      text: t("Transactions Why Items.item4.description"),
    },
    { title: t("Transactions Why Items.item5.heading"), text: t("Transactions Why Items.item5.description") },
    {
      title: t("Transactions Why Items.item6.heading"),
      text: t("Transactions Why Items.item6.description"),
    },
    { title: t("Transactions Why Items.item7.heading"), text: t("Transactions Why Items.item7.description") },
    { title: t("Transactions Why Items.item8.heading"), text: t("Transactions Why Items.item8.description") },
  ];

  return (
    <section className={s.transactions}>
      <div className="container">
        <h2>{t("Features.heading")}</h2>

        <Fade bottom>
          <div className="features-wrapper">
            {features.map((s, index) => (
              //   <SwiperSlide key={index}>
              <div className="feature" key={index}>
                <div className="header">
                  <div className="icon" style={{ backgroundColor: `${s.colors[0]}` }}>
                    <img src={s.icon} alt="" height="40" width="40" />
                  </div>
                  <strong>{s.title.toUpperCase()}</strong>
                </div>
                <p className={`${s.class}`}>{s.text}</p>
              </div>
            ))}
          </div>
        </Fade>

        <Fade bottom>
          <div className={s.transactions__why}>
            <div className={s.transactions__why_items}>
              {whyItems.map((w, index) => (
                <div key={index}>
                  <strong>{w.title}</strong>
                  <p>
                    {w.text} <br />
                    {w.flags &&
                      w.flags.map((f, flagIndex) => <img src={f} alt="" key={flagIndex} height="16" width="16" />)}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </Fade>
      </div>
    </section>
  );
};

export default Transactions;
