import "./App.scss";
import { useEffect, useState } from "react";
import Header from "./components/Header/Header";
import Trade from "./components/Trade/Trade";
import Transactions from "./components/Transactions/Transactions";
// import Options from "./components/Options/Options";
import Markets from "./components/Markets/Markets";
import Staking from "./components/Staking/Staking";
import Roadmap from "./components/Roadmap/Roadmap";
import FAQ from "./components/FAQ/FAQ";
import Footer from "./components/Footer/Footer";
import Download from "./components/Download/Download";
import PageLoader from "./components/PageLoader/PageLoader";
import { Route, Routes } from "react-router-dom";
import { TokenSaleTimeline } from "./components/TokenSaleTimeline/TokenSaleTimeline";
import ScheduleACall from "./components/ScheduleACall/ScheduleACall";
import SuccessPopup from "./components/SuccessPopup/MessagePopup";
import { LandingFaqItems, TokenSaleFaqItems } from "./utils/data";

import { useLocation } from "react-router-dom";
import { useRecoilState } from "recoil";
import contractState from "./state/contractAtom";
import TokenSale from "./components/TokenSale/TokenSale";
import Featured from "./components/Featured/Featured";
import IntegrationPartners from "./components/IntegrationPartners/IntegrationPartners";
import TradingProductSections from "./components/TradingProducts/TradingProducts";

const Landing = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    window.onload = () => {
      //so that pageloader should show for a minimum of 250ms.
      setIsLoading(false);
      // setTimeout(() => {
      // }, 100);
    };
  }, []);
  return (
    <>
      <PageLoader loading={isLoading} />
      <Header />
      {/* <Download /> */}
      <Trade />
      <Transactions />
      <Featured />
      <IntegrationPartners />
      <Markets />
      <Staking />
      <TokenSale />
      <Roadmap />
      <FAQ faqItems={LandingFaqItems} />
      <Footer />
    </>
  );
};

const Presale = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [timelineState] = useRecoilState(contractState);

  useEffect(() => {
    //when timeline state is updated initially
    if (timelineState.presaleStart || true) {
      setTimeout(() => setIsLoading(false), 1000);
    }
  }, [timelineState.presaleStart]);

  return (
    <>
      <PageLoader loading={isLoading} />
      <Header />
      <TokenSaleTimeline />
      <ScheduleACall />
      <FAQ faqItems={TokenSaleFaqItems} />
      <Footer />
    </>
  );
};

const TradingProducts = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    window.onload = () => {
      //so that pageloader should show for a minimum of 250ms.
      setTimeout(() => {
        setIsLoading(false);
      }, 500);
    };
  }, []);
  return (
    <>
      <PageLoader loading={isLoading} />
      <Header />
      <TradingProductSections />
      <Footer />
    </>
  );
};

function App() {
  const location = useLocation();

  useEffect(() => {
    if (location.search.includes("refId")) {
      window.localStorage.setItem("refId", location.search.split("=")[1]);
    }
  }, [location.search]);
  return (
    <div className="App">
      <Routes>
        <Route element={<Landing />} path="/" />
        <Route element={<Presale />} path="/presale" />
        <Route element={<TradingProducts />} path="/trading-products" />
      </Routes>
    </div>
  );
}

export default App;
