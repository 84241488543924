import React from "react";
import { useTranslation } from "react-i18next";
import s from "./Featured.module.scss";
import BloomBerg from "../../assets/images/featured/Bloomberg.svg";
import yahoo from "../../assets/images/featured/yahoo.svg";
import MarketWatch from "../../assets/images/featured/MarketWatch.svg";
import Bitcoin from "../../assets/images/featured/Bitcoin.svg";
import CoinSpeaker from "../../assets/images/featured/CoinSpeaker.svg";
import NewsBtc from "../../assets/images/featured/NewsBtc.png";
import CoinMarketCap from "../../assets/images/featured/CoinMarketCap.svg";
import CyptoNews from "../../assets/images/featured/Cryptonews.svg";
import Fade from "react-reveal/Fade";

const Featured = () => {
  const { t } = useTranslation();
  const featureLogo = [
    {
      src: BloomBerg,
      url: "https://www.bloomberg.com/press-releases/2023-02-07/optionblitz-leverages-ethereum-layer-2-protocol-arbitrum-to-pioneer-zero-day-options-social-trading-platform",
    },
    {
      src: yahoo,
      url: "https://finance.yahoo.com/finance/news/optionblitz-leverages-ethereum-layer-2-180000313.html?guccounter=1&guce_referrer=aHR0cHM6Ly93d3cuZ29vZ2xlLmNvbS8&guce_referrer_sig=AQAAAEg-FmQa2IZ8VAkHOXtx7OmTQHmm6Me9Vx_VdgXDSCl12MyPZzMJ3hUEd1PjzBkge9CP8h8JYJYbpCiF5_jupIljX7jp5zQ5eqfSEpLj4cSBpYcyOcI4K0XlSXvUvGst9SVz_gKO5I5h4KThSJ_AYK8p3bKQ64iD5RTAPDRumtDO",
    },
    {
      src: MarketWatch,
      url: "https://www.marketwatch.com/press-release/optionblitz-leverages-ethereum-layer-2-protocol-arbitrum-to-pioneer-zero-day-options-social-trading-platform-2023-02-07?mod=search_headline",
    },
    {
      src: Bitcoin,
      url: "https://bitcoinist.com/optionblitz-leverages-ethereum-layer-2-protocol-arbitrum-to-pioneer-zero-day-options-social-trading-platform/",
    },
    {
      src: CoinSpeaker,
      url: "https://www.coinspeaker.com/optionblitz-eth-layer-2-protocol-arbitrum/",
    },
    {
      src: NewsBtc,
      url: "https://www.newsbtc.com/press-releases/optionblitz-leverages-ethereum-layer-2-protocol-arbitrum-to-pioneer-zero-day-options-social-trading-platform/",
    },
    {
      src: CoinMarketCap,
      url: "https://coinmarketcap.com/headlines/news/optionblitz-leverages-ethereum-layer-2-protocol-arbitrum-to-pioneer-zero-day-options-social-trading-platform/",
    },
    {
      src: CyptoNews,
      url: "https://cryptonews.com/news/optionblitz-leverages-ethereum-layer-2-protocol-arbitrum-to-pioneer-zero-day-options-social-trading-platform.htm",
    },
  ];

  return (
    <section className={s.feature_in}>
      <Fade left>
        <div className="container">
          <h1>{t("Featured_in.heading")}</h1>
          <div className={s.featured_partner}>
            {featureLogo.map((logo) => (
              <img src={logo.src} alt="" onClick={() => window.open(logo.url, "_blank")} />
            ))}
          </div>
        </div>
      </Fade>
    </section>
  );
};

export default Featured;
