import { OptionBlitzCentrifugo } from "src/state/OptionBlitzCentrifugo";
import { optionBlitzBackendDomain } from "src/config";

const centrifugoEndpoint = `wss://${optionBlitzBackendDomain}/centrifugo`;

const getAccessToken = async (ctx) => {
  return "";
};

export const centrifugo = new OptionBlitzCentrifugo(centrifugoEndpoint, "", getAccessToken);
