import { createDocument, getBlockpassStatus, getDocument, setSignerAndSendEmail } from "src/api/presale";
import { ICreateDoc } from "src/types/document";
import { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import useUser from "src/hooks/useUser";
import { US_TEMPLATE_ID } from "src/constants";

export const usePresale = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [template, setTemplate] = useState(US_TEMPLATE_ID);
  const [error, setError] = useState("");
  const [status, setStatus] = useState("");
  const { user } = useUser();
  const docData: ICreateDoc = {
    templateId: template,
    documentName: uuidv4(),
  };

  function saveDocInStorage(docId: string) {
    window.localStorage.setItem(user.account, docId);
  }

  function retrieveDocFromStorage() {
    return window.localStorage.getItem(user.account);
  }

  async function sendEmail(docId: string, signatoryId: string, email: string) {
    const { status } = await setSignerAndSendEmail(docId, signatoryId, email);
    return status;
  }

  async function getDocumentStatus() {
    const docId = retrieveDocFromStorage();
    if (docId) {
      try {
        const { data } = await getDocument(docId);
        if (data.Document.stage === "COMPLETED") {
          setStatus("Completed");
        } else {
          setStatus("Pending");
        }
        return data.Document.stage;
      } catch (error) {
        setError(error);
      }
    } else {
      return;
    }
  }

  async function createDoc(email: string) {
    setIsLoading(true);
    const document = retrieveDocFromStorage();
    if (!document) {
      let docId: string;
      try {
        const { data } = await createDocument(docData);
        docId = data.Document.id;
        const signatoryId = data.Document.signatories_v2[0].id;
        await sendEmail(docId, signatoryId, email);
        setStatus("Pending");
        saveDocInStorage(docId);
        setIsLoading(false);
      } catch (error) {
        setError(error);
        setIsLoading(false);
      }
      return;
    } else {
      await getDocumentStatus();
      setIsLoading(false);
      return;
    }
  }

  async function getVerificationStatus(walletAddress: string) {
    try {
      const { data } = await getBlockpassStatus(walletAddress);
      setStatus(data.status);
      return data.status;
    } catch (e) {
      console.log('blockpass status not found', e);
      setStatus("Not Found");
    }
  }
  return {
    createDoc,
    isLoading,
    setIsLoading,
    setTemplate,
    status,
    error,
    getDocumentStatus,
    getBlockpassStatus,
    getVerificationStatus,
  };
};
