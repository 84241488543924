import { CRYPTO_PAIRS } from "src/socket/constants";
import { optionblitzApi } from ".";

const cryptoPairs = CRYPTO_PAIRS.map((x) => x.replace("/", "_"));

export const get24hrGraphDataForAllPairs = async (pairs) => {
  let promises = [];

  const ONE_DAY_IN_S = 60 * 60 * 24;

  let now = Math.floor(Date.now() / 1000);
  let yesterday = now - ONE_DAY_IN_S;
  let multiplier = 1;
  let period = "Hour";

  // only need to check first pair as we don't mix pairs when fetching.
  // checking if pair is not a crypto as they are open 24/7.
  // If pair is forex or energy or metals, and it is weekend, then fetching fridays graph.
  if (!cryptoPairs.includes(pairs[0])) {
    const currentDay = new Date().getUTCDay(); // 0 = Sunday, 6 = Saturday
    const currentHour = new Date().getUTCHours();

    // Later than Friday 4 pm UTC and earlier than Sunday 5pm UTC.
    if ((currentDay === 5 && currentHour >= 16) || currentDay === 6 || (currentDay === 0 && currentHour <= 17)) {
      const friday4pm = new Date(now);
      friday4pm.setUTCHours(16, 0, 0, 0);

      let daysPast = 0;
      // if saturday
      if (currentDay === 6) {
        daysPast = 1;
      }
      // if sunday
      else if (currentDay === 0) {
        daysPast = 2;
      }

      // setting the "to" time to friday 4pm.
      now = Math.floor(friday4pm.getTime() / 1000) - daysPast * ONE_DAY_IN_S;
      yesterday = now - 60 * 60 * 24; // Thursday 4pm UTC.
    }
  }

  for (let pair of pairs) {
    promises.push(
      optionblitzApi.get(`/oracle/aggregate/${pair}`, {
        params: {
          multiplier,
          period,
          from: yesterday,
          to: now,
        },
      })
    );
  }
  let res = await Promise.all(promises);

  return res.map((x) => {
    return {
      s: x.data.symbol,
      prices: x.data.sticks.map((stick) => stick.candle),
    };
  });
};

export const getCurrentBidsForAllPairs = async (pairs) => {
  let promises = [];
  for (let pair of pairs) {
    promises.push(optionblitzApi.get(`oracle/last_quote/${pair}`));
  }
  let res = await Promise.all(promises);

  return res.map((x) => x.data);
};
