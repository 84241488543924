/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import completedIcon from "../../../assets/images/check__icon.svg";
import pdfIcon from "../../../assets/images/pdf__icon.svg";
import blockpassIcon from "../../../assets/images/blockpass__icon.svg";
import Button from "src/components/UI/Button/Button";
import s from "./Stepper.module.scss";
import Loader from "../Loader/Loader";
import BuyInput from "../BuyInput/BuyInput";
import { EMAIL_REG_EXP, NON_US_TEMPLATE_ID, US_TEMPLATE_ID } from "src/constants";
import { usePresale } from "src/hooks/usePresale";
import MessagePopup from "src/components/SuccessPopup/MessagePopup";
import useUser from "src/hooks/useUser";
import { enterPresale, claimPresale, claimIBCO } from "src/contracts/transaction";
import useContract from "src/hooks/useContract";
import { getReferrer } from "src/utils/common";
import { provider } from "src/config";

const Stepper = ({ changeHandler, amountUSDC, amountBLX, tokenPrice, purchaseBlx }) => {
  const [stage, setStage] = useState(0);
  const [completed, setCompleted] = useState([false, false, false]);
  const [email, setEmail] = useState("");
  const [isValid, setIsValid] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  //message to show in popup
  const [popupMessage, setPopupMessage] = useState({
    // status: "success",
    // message: "CONGRATULATIONS YOUR PURCHASE WAS SUCCESSFUL",
  });
  const { user } = useUser();
  const { contracts: contractState } = useContract();
  const { contracts } = contractState;
  const network = contractState.deployment?.network;
  const { createDoc, setTemplate, isLoading, status, error, getDocumentStatus, setIsLoading, getVerificationStatus } =
    usePresale();
  const {
    ibcoBlxSold,
    presaleBlxSold,
    blxPrice,
    presaleClosed,
    presaleSoftCapReached,
    ibcoEnd,
    ibcoSoftCapReached,
    myBlxBalance,
    myRewardBlx,
    myRewardsClaimed,
    myPresaleClaimed,
  } = contractState;
  // purchase price for the given usdc/blx combination, if either <= 0 use current tokenPrice
  const purchasePrice = amountBLX > 0 && amountUSDC > 0 ? amountUSDC / amountBLX : tokenPrice;
  const claimableBLX = (myBlxBalance || 0) + (myRewardBlx || 0) - (myRewardsClaimed || 0) - (myPresaleClaimed || 0);
  //console.log(contractState);
  //console.log(`presale status ${presaleClosed} ${presaleSoftCapReached} ${presaleBlxSold} ${ibcoEnd} ${ibcoSoftCapReached} ${myBlxBalance} ${claimableBLX}`);
  //email required for revv document
  function emailChangeHandler(e) {
    const { value } = e.target;
    setEmail(value);
    if (EMAIL_REG_EXP.test(value)) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }
  //to move to the next stage within the same step
  async function handleNext(stage, step) {
    setStage(stage);
    if (stage === 2 && step === 1) {
      await createDoc(email);
    }
  }

  //to complete a step
  function handleComplete(step) {
    setCompleted((prev) =>
      prev.map((s, index) => {
        if (index === step) {
          return true;
        } else {
          return s;
        }
      })
    );
    //stage is reset to 0 for new step
    setStage(0);

    //when step 0 is completed
    if (step === 0) {
      //triggered when document verification is completed
      //check blockpass status
      getBlockpassVerificationStatus();
    }
  }

  //blockpass initialization
  const loadBlockpassWidget = () => {
    const blockpass = new window.BlockpassKYCConnect(process.env.REACT_APP_BLOCKPASS_CLIENT_ID, {
      refId: user.account,
    });
    blockpass.startKYCConnect();
    blockpass.on("KYCConnectSuccess", () => {});
  };

  //blockpass verification status
  async function getBlockpassVerificationStatus() {
    setIsLoading(true);
    const respone = await getVerificationStatus(user.account);
    setIsLoading(false);

    //if already approve move to the next step
    if (respone === "approved") {
      handleComplete(1);
      return;
    }
  }

  //get revv document status
  async function getDocStatus() {
    setIsLoading(true);
    const response = await getDocumentStatus();
    setIsLoading(false);

    //if already document is signed then move to next step

    if (response === "COMPLETED") {
      handleComplete(0);
    }
    if (response && response !== "COMPLETED") {
      setStage(2);
    } else {
      setStage(0);
    }
  }
  async function claimBLX() {
    setIsLoading(true);
    console.log(`claim BLX ${claimableBLX}`);
    const referrer = getReferrer(); // should be from url or somewhere
    provider
      .then((signer) => {
        if (claimableBLX <= 0) return Promise.reject("Nothing to claim");
        claimPresale(provider, signer, contracts)
          .then((result) => {
            console.log("result");
            console.log(result);
            const { hash: txHash } = result;
            // await purchaseBlx();
            setIsLoading(false);
            /**keep the status as success just change the message**/
            const link = `https://${network === "mainnet" ? "" : network + "."}etherscan.io/tx/${txHash}`;
            const anchor = `<a href=${link} target="_blank" >https://${
              network === "mainnet" ? "" : network + "."
            }etherscan.io/tx/${txHash.substring(0, 10)}</a>`;
            setPopupMessage({ status: "success", message: `YOUR CLAIM WAS SUCCESSFUL <br/>${anchor}<br/>` });
            setShowPopup(true);
          })
          .catch((err) => {
            console.log(err);
            setIsLoading(false);
            setPopupMessage({ status: "error", message: err.message || `${err}` });
            /**keep the status as error just change the message**/

            // setPopupMessage({status:"error",message:"Some error has occured!"});
            setShowPopup(true);
          });
      })
      .catch((error) => {
        console.log("e", error);
        setIsLoading(false);
        setPopupMessage({ status: "error", message: `${error.message || error}` });
        setShowPopup(true);
      });
  }
  //after successfully buying blx in step 3.
  async function buyBLX() {
    setIsLoading(true);
    console.log(`buying ${amountUSDC}`);
    const referrer = getReferrer(); // should be from url or somewhere
    provider
      .then((signer) => {
        enterPresale({ amount: amountUSDC, referrer }, provider, signer, contracts)
          .then((result) => {
            console.log("result");
            console.log(result);
            const { txHash } = result;
            // await purchaseBlx();
            setIsLoading(false);
            /**keep the status as success just change the message**/
            const link = `https://${network === "mainnet" ? "" : network + "."}etherscan.io/tx/${txHash}`;
            const anchor = `<a href=${link} target="_blank" >https://${
              network === "mainnet" ? "" : network + "."
            }etherscan.io/tx/${txHash.substring(0, 10)}</a>`;
            setPopupMessage({
              status: "success",
              message: `CONGRATULATIONS YOUR PURCHASE WAS SUCCESSFUL <br/>${anchor}<br/>`,
            });
            setShowPopup(true);
          })
          .catch((err) => {
            console.log(err);
            setIsLoading(false);
            setPopupMessage({ status: "error", message: err.message || `${err}` });
            /**keep the status as error just change the message**/

            // setPopupMessage({status:"error",message:"Some error has occured!"});
            setShowPopup(true);
          });
      })
      .catch((e) => {
        console.log("e", error);
        setIsLoading(false);
        setPopupMessage({ status: "error", message: `${error}` });
        setShowPopup(true);
      });
  }

  useEffect(() => {
    //when user log in get the status of document if there is any..
    if (user.account) {
      getDocStatus();
    } else {
      setCompleted([false, false, false]);
    }
  }, [user.account]);

  useEffect(() => {
    //blockpass initialization only when status of blockpass is not found
    if (status === "Not Found") {
      loadBlockpassWidget();
    }

    //polling to get status of document or blockpass
    const timer = setInterval(async () => {
      //if step 1 is not completed and status of document is pending then keep calling to get document status
      if (!completed[0] && status === "Pending") {
        await getDocumentStatus();
        return;
      }

      //if step 1 is  completed and verification status of blockpass is not approved then keep calling to get blockpass status
      if (completed[0] && status !== "approved") {
        await getVerificationStatus(user.account);

        return;
      }
    }, 10000);

    //if step 1 is  completed and verification status of blockpass is approved then move to the next step
    if (completed[0] && status === "approved") {
      handleNext(1, 1);
    }
    return () => {
      clearInterval(timer);
    };
  }, [completed, status]);

  return (
    <React.Fragment>
      <div className={s.stepper}>
        <div className={[s.stepper__step, user.account ? (completed[0] ? "completed" : "active") : ""].join(" ")}>
          <div className={s.stepper__step__step_header}>
            <div>
              {completed[0] ? (
                <span>
                  <img src={completedIcon} alt="check-icon" />
                </span>
              ) : (
                <span>1</span>
              )}
            </div>
            <h3>Accept Terms</h3>
            {/* {completed[0] && <p>Agreement.pdf</p>} */}
          </div>
          <div className={[s.stepper__step__step_body].join(" ")}>
            {stage === 0 && (
              <>
                {isLoading ? (
                  <div className="stage_three">
                    <Loader />
                    <p style={{ marginTop: "0px" }}>Please wait...</p>
                  </div>
                ) : error && error.message.length > 0 ? (
                  <div className="stage_three">
                    <p>Server under maintenance, please try again later</p>
                  </div>
                ) : (
                  <>
                    <p>
                      Welcome to the Optionblitz token sale, to begin you must complete an investor suitability
                      questionnaire, then read and accept the Token Sale Agreement, Risk Disclosure, Private Placement
                      Memorandum and Terms and Conditions of Use.
                    </p>
                    <p>
                      You will use your connected Metamask wallet to sign the documents and the associated Ethereum
                      wallet address will be whitelisted to allow you to purchase BLX with it.
                    </p>
                    <div>
                      <p>Are you a US Person?</p>
                      <div className="action-btns">
                        <button
                          onClick={() => {
                            setTemplate(US_TEMPLATE_ID);
                            handleNext(1, 1);
                          }}
                        >
                          Yes
                        </button>
                        <button
                          onClick={() => {
                            setTemplate(NON_US_TEMPLATE_ID);
                            handleNext(1, 1);
                          }}
                        >
                          No
                        </button>
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
            {stage === 1 && (
              <>
                <p>Please enter your email address below. You will receive an Esign request from Revv.</p>
                <p>1. Click Sign Document. You will get redirected to the terms.</p>
                <p>2. Scroll down to the end of the document and click the 'Click to sign' field.</p>
                <p>3. Now, click OK to confirm your signature.</p>
                <p>4. Wait for this page to update and continue to KYC verification.</p>
                <div className={s.stepper__step__step_body__two}>
                  <input
                    type={"text"}
                    placeholder="Enter your email address"
                    value={email}
                    onChange={emailChangeHandler}
                  />
                  <Button className={!isValid ? "disable" : ""} onClick={() => handleNext(2, 1)} disabled={!isValid}>
                    Click here to start
                  </Button>
                </div>
              </>
            )}
            {stage === 2 && (
              <div className="stage_three">
                {isLoading ? (
                  <>
                    <Loader />
                    <p style={{ marginTop: "0px" }}>
                      Please wait while your
                      <br /> submission is processed...
                    </p>
                  </>
                ) : (
                  <>
                    {error && error.message.length > 0 && <p>Server under maintenance, please try again later</p>}
                    {status === "Pending" && (
                      <p>Verification pending. Please sign the terms pdf sent to your email to continue.</p>
                    )}
                    {status === "Completed" && (
                      <>
                        <p className="confirmation_text">
                          Confirmed successfully,
                          <br /> please click next to move to <br />
                          the next step
                        </p>
                        {/* <div className="pdf_file">
                    <img src={pdfIcon} alt="pdf-icon" />
                    <a href="#">Agreement.pdf</a>
                  </div> */}
                        <Button onClick={() => handleComplete(0)}>Next</Button>
                      </>
                    )}
                  </>
                )}
              </div>
            )}
          </div>
        </div>
        <div
          className={[
            s.stepper__step,
            user.account && completed[0] ? (completed[1] ? "completed" : "active") : "",
          ].join(" ")}
        >
          <div className={[s.stepper__step__step_header]}>
            <div>
              {completed[1] ? (
                <span>
                  <img src={completedIcon} alt="check-icon" />
                </span>
              ) : (
                <span>2</span>
              )}
            </div>
            <h3>KYC VERIFICATION</h3>
          </div>
          <div className={[s.stepper__step__step_body, s.stepper__step__kyc].join(" ")}>
            {stage === 0 && (
              <div className="stage_three">
                {isLoading ? (
                  <>
                    <Loader />
                    <p style={{ marginTop: "0px" }}>Please wait...</p>
                  </>
                ) : (
                  <>
                    {status === "waiting" && (
                      <>
                        <p>KYC Verification is pending.</p>
                      </>
                    )}
                    {status === "inreview" && (
                      <>
                        <p>KYC Verification is currently in review.</p>
                      </>
                    )}
                  </>
                )}
                {status === "Not Found" && (
                  <>
                    <p>
                      Please complete KYC using our verification partner Blockpass. You will need a Photo ID such as a
                      passport or driver’s licence. After completing KYC, your connected wallet will be whitelisted and
                      BLX purchases will be enabled.
                    </p>
                    <Button id="blockpass-kyc-connect">
                      <img src={blockpassIcon} alt="blockpass-icon" />
                      Verify with blockpass
                    </Button>
                  </>
                )}
              </div>
            )}
            {stage === 1 && (
              <div className="stage_three">
                <>
                  <p className="success_msg">
                    KYC application
                    <br />
                    successful, you can
                    <br />
                    now purchase BLX.
                  </p>
                  <Button onClick={() => handleComplete(1)}>Next</Button>
                </>
              </div>
            )}
          </div>
        </div>
        <div
          className={[
            s.stepper__step,
            user.account && completed[1] ? (completed[2] ? "completed" : "active") : "",
          ].join(" ")}
        >
          <div className={[s.stepper__step__step_header]}>
            <div>
              {completed[2] ? (
                <span>
                  <img src={completedIcon} alt="check-icon" />
                </span>
              ) : (
                <span>3</span>
              )}
            </div>
            <h3>BUY BLX</h3>
          </div>
          <div className={[s.stepper__step__step_body, "step_three"].join(" ")}>
            {(!presaleClosed || ibcoEnd > 0) && (
              <>
                <div className={s.buy__price}>
                  <h3>Price</h3>
                  <span>{purchasePrice.toFixed(4)}</span>
                </div>
                <BuyInput
                  currency={"USDC"}
                  title={"You send"}
                  isHaveMaxButton={true}
                  amount={amountUSDC % 1 !== 0 ? amountUSDC.toFixed(4) : amountUSDC}
                  changeHandler={changeHandler}
                />
                <BuyInput
                  currency={"BLX"}
                  title={"You receive"}
                  isHaveMaxButton={false}
                  amount={amountBLX % 1 !== 0 ? amountBLX.toFixed(4) : amountBLX}
                  changeHandler={changeHandler}
                />
                <Button
                  onClick={buyBLX}
                  className={!(amountUSDC > 0) | isLoading && "disable"}
                  disabled={(amountUSDC > 0) | isLoading ? false : true}
                >
                  {isLoading ? <span className="loader" /> : "Buy BLX"}
                </Button>
              </>
            )}
            {presaleClosed && !ibcoEnd && claimableBLX >= 0 && (
              <>
                <Button onClick={claimBLX} className={isLoading && "disable"} disabled={isLoading}>
                  {isLoading ? <span className="loader" /> : "Claim BLX"}
                </Button>
                <div className={s.buy__price}>
                  <h3>Claimable</h3>
                  <span>{claimableBLX.toFixed(4)}</span>
                </div>
              </>
            )}
          </div>
          <div>
            {(!presaleClosed || ibcoEnd) && (
              <p>
                Note: If you have no ETH in your wallet to pay for network costs, 5 USDC from your balance will be taken
                to pay using our integration with Biconomy. A dialog box will popup requesting a second signing request.
                If you are having technical difficulties contact us on Telegram at{" "}
                <a href="https://t.me/Tobias_OB" target="_blank">
                  https://t.me/Tobias_OB
                </a>
              </p>
            )}
          </div>
        </div>
      </div>
      {showPopup && <MessagePopup closePopup={() => setShowPopup(false)} message={popupMessage} />}
    </React.Fragment>
  );
};

export default Stepper;
