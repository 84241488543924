import { useCallback } from "react";
import { useRecoilState } from "recoil";
import userState from "src/state/userAtom";
import contractState from "src/state/contractAtom";
import { utils } from "ethers";
const useUser = () => {
  const [user, setUser] = useRecoilState(userState);
  const [contract, setContract] = useRecoilState(contractState);

  const setAccount = useCallback(
    (account: string) => {
      const walletAddress = account ? utils.getAddress(account) : account;
      setUser({ ...user, account: walletAddress });
      setContract({ ...contract, account: walletAddress });
    },
    [user, setUser]
  );

  const disconnect = () => {
    // magic.connect.disconnect();
    setUser({});
  };

  return { user, setAccount, disconnect };
};

export default useUser;
