import React from 'react';
import s from "./Advantage.module.scss";

const Advantage = ({icon, text}) => {
    return (
        <div className={s.advantage}>
            <img src={icon} alt=""/>
            <strong>{text}</strong>
        </div>
    );
};

export default Advantage;