import { atom } from "recoil";
import { getCurrentPriceForAllPairs, getLastDayPriceForAllPairs } from "src/api/finage";
import { get24hrGraphDataForAllPairs, getCurrentBidsForAllPairs } from "src/api/optionblitz";
import { ONE_HOUR, POLLING_INTERVAL } from "src/constants";
import { getChange, getSignalName } from "src/utils/common";
import { DEFAULT_FEATURED_DATA, FEATURED_SIGNALS_PAIRS, CRYPTO_PAIRS, INTERVAL_RESOLUTION } from "../socket/constants";
import { centrifugo } from "src/config/centrifugo";
import { getPythCandles } from "./OptionBlitzProvider";
export const featuredSignalsState = atom({
  key: "featuredSignalsState", // unique ID (with respect to other atoms/selectors)
  default: {
    data: { ...DEFAULT_FEATURED_DATA },
    isDataFetched: false,
    isSubscribed: false,
  },
  effects: [
    ({ setSelf, getLoadable, node, onSet, resetSelf }) => {
      // fetching current prices from finage REST API.
      const fetchPriceData = async () => {
        //breaking featured Signals data pairs into crypto and non crypto.
        let cryptoPairs = FEATURED_SIGNALS_PAIRS.filter((pair) => CRYPTO_PAIRS.includes(pair));

        //removing /
        let cryptoPairsForOptionBlitz = cryptoPairs.map((pair) => pair.split("/").join("_"));

        let cryptoPairsForLastDayPrice = cryptoPairs.map((pair) => pair.split("/").join("_"));

        // e.g ASD/ASD
        let nonCryptoPairs = FEATURED_SIGNALS_PAIRS.filter((pair) => !CRYPTO_PAIRS.includes(pair));

        let nonCryptoPairsForOptionBlitz = nonCryptoPairs.map((pair) => pair.split("/").join("_"));

        // fetching current prices for all crypto pairs.
        // const currCryptoPrices = await getCurrentBidsForAllPairs(cryptoPairsForOptionBlitz);
        const currCryptoPrices = await Promise.all(
          cryptoPairsForOptionBlitz.map((pair) => getPythCandles(pair, INTERVAL_RESOLUTION))
        );
        // fetching current prices for all non crypto pairs.
        // const currPrices = await getCurrentPriceForAllPairs(nonCryptoPairsForOptionBlitz);
        const currPrices = await Promise.all(
          nonCryptoPairsForOptionBlitz.map((pair) => getPythCandles(pair, INTERVAL_RESOLUTION))
        );

        // fetching past prices for non crypto pairs.
        const pastPrices = await Promise.all(
          nonCryptoPairsForOptionBlitz.map((pair) => getPythCandles(pair, INTERVAL_RESOLUTION))
        );
        // fetching past prices for  crypto pairs.
        const pastCryptoPrices = await Promise.all(
          cryptoPairsForLastDayPrice.map((pair) => getPythCandles(pair, INTERVAL_RESOLUTION))
        );

        // old state.
        let { data, isSubscribed } = getLoadable(node).contents;
        let updatedData = {};

        for (let i = 0; i < nonCryptoPairs.length; i++) {
          let newPairData = {
            ...data[nonCryptoPairs[i]],
            s: nonCryptoPairs[i],
            p: currPrices[i].newPrice.rate,
            dc: getChange(currPrices[i].newPrice.rate, pastPrices[i].oldPrice.rate).toFixed(2),
            /** @type {{C:number}[]} */
            graphData: currPrices[i].obCandles.map((x) => ({ C: x.rate })),
          };
          updatedData[nonCryptoPairs[i]] = newPairData;
        }
        for (let i = 0; i < cryptoPairs.length; i++) {
          let newPairData = {
            ...data[cryptoPairs[i]],
            s: cryptoPairs[i],
            p: currCryptoPrices[i].newPrice.rate,
            dc: getChange(currCryptoPrices[i].newPrice.rate, pastCryptoPrices[i].oldPrice.rate).toFixed(2),
            /** @type {{C:number}[]} */
            graphData: currCryptoPrices[i].obCandles.map((x) => ({ C: x.rate })),
          };
          updatedData[cryptoPairs[i]] = newPairData;
        }

        setSelf({ isSubscribed, isDataFetched: true, data: { ...updatedData } });
      };

      const fetchSocketData = () => {
        centrifugo.start();
        centrifugo?.on(
          "tasignal.all",
          (socketData) => {
            const { symbol, signal, _signal, interval } = socketData.data;

            const sym = symbol.split("_").join("/");
            if (!FEATURED_SIGNALS_PAIRS.find((item) => item === sym)) return;
            // console.log("socket data", socketData.data);
            const signals = signal || _signal;

            //old state
            let { data, isSubscribed } = getLoadable(node).contents;
            let updatedData = data[sym];
            // console.log("data", updatedData);
            if (interval === "5min") {
              // console.log(interval, signals.AVERAGE, signals.SMAL);

              updatedData = {
                ...updatedData,
                fiveMin: [
                  getSignalName(signals.AVE_SMA),
                  getSignalName(signals.AVE_OTHER),
                  getSignalName(signals.AVE_ТОТ),
                ],
              };
            }
            if (interval === "15min") {
              // console.log(interval, signals.AVERAGE, signals.AVE_SMA);

              updatedData = {
                ...updatedData,
                fifteenMin: [
                  getSignalName(signals.AVE_SMA),
                  getSignalName(signals.AVE_OTHER),
                  getSignalName(signals.AVE_ТОТ),
                ],
              };
            }
            if (interval === "1hour") {
              // console.log(interval, signals.AVERAGE, signals.AVE_SMA);

              updatedData = {
                ...updatedData,
                hourly: [
                  getSignalName(signals.AVE_SMA),
                  getSignalName(signals.AVE_OTHER),
                  getSignalName(signals.AVE_ТОТ),
                ],
              };
            }
            if (interval === "24hour") {
              // console.log(interval, signals.AVERAGE, signals.AVE_SMA);

              updatedData = {
                ...updatedData,
                daily: [
                  getSignalName(signals.AVE_SMA),
                  getSignalName(signals.AVE_OTHER),
                  getSignalName(signals.AVE_ТОТ),
                ],
              };
            }

            const newData = {
              ...data,
              [sym]: { ...updatedData },
            };
            // console.log("newData from socket", newData);
            setSelf({ isSubscribed, isDataFetched: true, data: { ...newData } });
          },
          "publish"
        );
      };

      async function fetchData() {
        fetchSocketData();
        await fetchPriceData();
      }
      // console.log("featured atom running");

      // fetching data first time.
      fetchData();

      setInterval(() => {
        // fetching data after interval only if subscribed.
        let { isSubscribed } = getLoadable(node).contents;
        if (isSubscribed) {
          fetchPriceData();
        }
      }, POLLING_INTERVAL);

      // cleanup
      return () => {
        resetSelf();
      };
    },
  ],
});
